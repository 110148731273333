import styled from 'styled-components'
import { Button } from 'semantic-ui-react'

export const StyledForm = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px 0 10px 0;
`

export const RedeemButton = styled(Button)`
  &&& {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.silver} !important;
    margin-left: 1em;
    box-shadow: none;
    padding: 12px 1em !important;
    font-size: 1.2em;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
`
