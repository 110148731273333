import styled, { css } from 'styled-components'
import { Image } from 'semantic-ui-react'
import { Link } from 'gatsby'

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  backdrop-filter: blur(5px) contrast(0.8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
`

export const Main = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 90vw;
  max-width: 400px;
  background: black;
  padding: 2em 0;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  margin-top: 4em;
`

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  padding: 1em;
  text-align: center;
`

export const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.white};
  ${props =>
    props.bold &&
    css`
      font-weight: bolder;
      font-size: 1.4em;
      color: ${({ theme }) => theme.colors.carnelian};
      margin-bottom: 10px;
    `}

  ${props =>
    props.emphasized &&
    css`
      font-style: italic;
    `}
`

export const QLogo = styled(Image)`
  &&& {
    width: 40%;
    height: auto;
    max-width: 200px;
    margin: 2em 0;
  }
`

export const StyledLink = styled(Link)`
  font-weight: bold;
`
