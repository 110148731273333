import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import toast from 'react-hot-toast'
import { StyledInput } from '../../components'
// Context
import { useCartContext } from '../../context/CartContext'
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { ErrorMsg } from '../../styles/LoginPage.styles'
import { StyledForm, RedeemButton } from './AddCoupon.styles'
// Utils
import { buildAddCouponCode } from '../../utils/validations'

const AddCoupon = () => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    prismicData: {
      prismicCartPage: { coupon_placeholder, redeem, coupon_added },
      prismicGeneral,
    },
  } = usePrismic()
  const { cartId, manageCart, buildCart } = useCartContext()

  const onSubmit = async data => {
    setIsLoading(true)
    await manageCart
      .addCouponToCart(data.code, cartId, buildCart, prismicGeneral)
      .then(() => {
        toast.success(coupon_added[0].text)
        setIsLoading(false)
        reset()
      })
      .catch(err => {
        toast.error(err.response.errors[0].message)
        setIsLoading(false)
        reset()
      })
  }

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(buildAddCouponCode(prismicGeneral)),
  })

  return (
    <StyledForm row onSubmit={handleSubmit(onSubmit)}>
      <StyledInput
        style={{ marginBottom: 0 }}
        {...register('code')}
        placeholder={coupon_placeholder[0].text}
        data-qa="inputCoupon"
      />
      <ErrorMsg>{errors.code?.message}</ErrorMsg>
      <RedeemButton data-qa="redeemButton" loading={isLoading} type="submit">
        {redeem[0].text}
      </RedeemButton>
    </StyledForm>
  )
}

export default AddCoupon
