import styled from 'styled-components'
import { Image, Button } from 'semantic-ui-react'
import { Container, StyledSpan } from '../../styles/common'
import { Primary as PrimaryButton } from '../../components'

export const ProductImage = styled(Image)`
  width: 35% !important;
`

export const CartCount = styled(StyledSpan)`
  padding: 0.5em 0 0.5em 1em;
  text-align: left;
`

export const CartItem = styled(Container)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.ashGray};
  padding-top: 1em;

  &:first-child {
    padding-top: 0.5em;
  }
`

export const OutlinedButton = styled(Button)`
  &&& {
    background: inherit;
    border: none;
    align-self: flex-end;
    padding: 5px 0 15px 0;
    font-weight: bolder;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.metallicSeaweed};
    z-index: 101;
    text-decoration: underline;

    :hover {
      background: inherit;
      color: ${({ theme }) => theme.colors.carnelian};
    }
  }
`

export const FilledButton = styled(PrimaryButton)`
  margin: 0 0 0.5em !important;

  &:last-child {
    margin-bottom: 1.5em !important;
  }
`

export const StyledText = styled(StyledSpan)`
  font-size: 1em;
  text-align: left;
  padding-bottom: 0;
`
