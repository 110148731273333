import React from 'react'

// Context
import { usePrismic } from '../../context/PrismicContext'

import {
  StyledModal,
  StyledHeader,
  StyledModalContent,
  Container,
  StyledText,
  StyledArqLogo,
  AppStoreLogoContainer,
  AppStoreLogo,
  StyledMyQFitLogo,
} from './ArqModal.styles'

const ArqModal = ({ open, onClose }) => {
  const {
    prismicData: {
      prismicArqModal: {
        apple_app_store,
        arq_apple_link,
        arq_google_link,
        arq_logo,
        arq_name,
        download_and_install_arq,
        everything_you_need_to_run_in_your_hand,
        google_app_store,
        myqfit_apple_link,
        myqfit_is_included,
        myqfit_logo,
        watch_your_email_for_instruction,
      },
    },
  } = usePrismic()

  return (
    <StyledModal
      closeIcon
      open={open}
      centered={true}
      closeOnDimmerClick={true}
      closeOnEscape
      onClose={onClose}
    >
      <StyledHeader />
      <StyledModalContent>
        <Container direction="column">
          <StyledText>{download_and_install_arq[0].text}</StyledText>
          <StyledText>
            ({arq_name[0]?.text ?? 'Ambassador Resources Q'})
          </StyledText>

          <StyledArqLogo src={arq_logo.url} />

          <StyledText>
            {everything_you_need_to_run_in_your_hand[0].text}
          </StyledText>
        </Container>

        <AppStoreLogoContainer>
          <a href={arq_apple_link.url} target="_blank">
            <AppStoreLogo src={apple_app_store.url} />
          </a>
          <a href={arq_google_link.url} target="_blank">
            <AppStoreLogo src={google_app_store.url} />
          </a>
        </AppStoreLogoContainer>

        <Container direction="column">
          <StyledText>{myqfit_is_included[0].text}</StyledText>
          <a href={myqfit_apple_link.url} target="_blank">
            <StyledMyQFitLogo src={myqfit_logo.url} />
          </a>
          <StyledText>{watch_your_email_for_instruction[0].text}</StyledText>
        </Container>
      </StyledModalContent>
    </StyledModal>
  )
}

export default ArqModal
