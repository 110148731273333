import React from 'react'
import { Modal } from 'semantic-ui-react'
import {
  Container,
  StyledButton,
  StyledModalContent,
  StyledText,
} from './PacksModal.styles'

const UkModal = ({ open, onClose, amount, onSubmit }) => {
  return (
    <Modal
      closeIcon
      style={{ width: '80%', maxWidth: 300 }}
      open={open}
      centered={true}
      closeOnDimmerClick={true}
      closeOnEscape
      onClose={onClose}
    >
      <StyledModalContent>
        <StyledText style={{ fontSize: 18, fontWeight: 800 }}>
          {`Your cart total is £${amount}`}
        </StyledText>
        <br></br>
        <StyledText>
          <br></br>
          In accordance with UK legislation, enrollment orders over £200 are
          held for 7 days.
          <br></br>
          <br></br>
          If you would like your order to ship immediately, then please keep the
          total under £200.
        </StyledText>
        <Container>
          <StyledButton onClick={onClose}>EDIT ORDER</StyledButton>
          <StyledButton
            onClick={() => {
              onSubmit()
              onClose()
            }}
          >
            PLACE ORDER
          </StyledButton>
        </Container>
      </StyledModalContent>
    </Modal>
  )
}

export default UkModal
