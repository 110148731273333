import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 1em 0;
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  font-weight: bold;
  margin: 3px 0;

  ${props =>
    props.total &&
    css`
      position: relative;
      padding-top: 10px;
      border-top: 1px solid #ccc;
      span {
        font-size: 1.3em;
        font-weight: bold;
      }
    `}
`

export const SavedWrapper = styled.div`
  background-color: #8afc8a;
  width: 100%;
  padding: 5px 10px;
  color: ${({ theme }) => theme.colors.green};
  font-weight: normal;
  font-size: 14px;
  span {
    font-size: 14px;
    font-weight: 900;
  }
`

export const DiscountText = styled.span`
  color: ${({ theme }) => theme.colors.carnelian};
`
