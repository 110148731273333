import React, { useEffect, useState } from 'react'
import { Container, Wrapper } from '../../styles/common'
import { MenuItem, Tab, TabPane } from 'semantic-ui-react'
import { Manage, PcSmartship, Setup } from './index'
import { PaymentDetails, ShippingDetails } from '../Cart'
import infoIcon from '../../svg/InfoIcon.svg'
import { Primary } from '../Buttons'
import { navigate } from 'gatsby'
import { formatParagraph } from '../../utils/prismicUtils'
import { useAuthContext } from '../../context/AuthContext'
import { useCartContext } from '../../context/CartContext'
import { usePrismic } from '../../context/PrismicContext'
import { buildAutoShipItems } from '../../utils/autoShipHelpers'
import styled from 'styled-components'
import DeleteAutoshipModal from './DeleteAutoshipModal'

const InfoIcon = styled.img`
  width: 20px;
  height: 20px;
`
const AutoShipTab = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [cardData, setCardData] = useState(null)
  const [virtualCardData, setVirtualCardData] = useState(null)
  const [isUpdatingAutoShip, setIsUpdatingAutoShip] = useState(false)
  const [purchasedSku, setPurchasedSku] = useState('')
  const [deleteVirtual, setDeleteVirtual] = useState(null)

  const {
    cardsOnFile,
    qUser,
    userType,
    isAuthenticated,
    isAutoshipFreeShipping,
    isEventSite,
    isVipSite,
  } = useAuthContext()
  const {
    autoShipAddress,
    autoShipDate,
    autoShipId,
    autoShipItems,
    autoShipPaymentInformationId,
    virtualAutoShipDate,
    virtualAutoShipItems,
    virtualAutoShipPaymentInformationId,
    isPcSmartshipInCart,
    manageAutoShip,
    updateAutoShipState,
    upgradeUserAddPcSmartship,
  } = useCartContext()

  const {
    storeCountryCode,
    prismicData: {
      prismicCartPage,
      prismicGeneral,
      prismicAutoshipPage: {
        cancel_my_autoship,
        continue_shopping,
        return_here_to_set_up,
        virtual_next_charge,
        product_sub_tab_name,
        virtual_sub_tab_name,
        no_virtual_found,
      },
    },
  } = usePrismic()

  useEffect(() => {
    if (virtualAutoShipItems.length > 0) {
      setPurchasedSku(virtualAutoShipItems[0].sku)
    }
  }, [virtualAutoShipItems])

  useEffect(() => {
    if (!autoShipPaymentInformationId) {
      setCardData(null)
      return
    }
    const card = cardsOnFile.find(({ associatePaymentInformationId }) => {
      return associatePaymentInformationId === autoShipPaymentInformationId
    })
    if (
      card?.creditCardType?.toLowerCase() === 'americanexpress' &&
      storeCountryCode === 'ca'
    ) {
      return setCardData(null)
    }
    setCardData(card)
  }, [autoShipPaymentInformationId])

  useEffect(() => {
    if (!virtualAutoShipPaymentInformationId) {
      setVirtualCardData(null)
      return
    }
    const card = cardsOnFile.find(({ associatePaymentInformationId }) => {
      return (
        associatePaymentInformationId === virtualAutoShipPaymentInformationId
      )
    })
    if (
      card?.creditCardType?.toLowerCase() === 'americanexpress' &&
      storeCountryCode === 'ca'
    ) {
      return setVirtualCardData(null)
    }
    setVirtualCardData(card)
  }, [virtualAutoShipPaymentInformationId])

  const shouldHidePcSmartship =
    isAutoshipFreeShipping ||
    isPcSmartshipInCart ||
    userType === 'RETAIL' ||
    isVipSite ||
    isEventSite

  const createNewAutoShip = async () => {
    setIsUpdatingAutoShip(true)

    const data = {
      address: autoShipAddress,
      associateId: qUser.associateId,
      associatePaymentInformationId: autoShipPaymentInformationId,
      autoShipItems: buildAutoShipItems(autoShipItems),
      dateNextProcess: autoShipDate,
      dateStart: autoShipDate,
      isActive: true,
      isVirtual: false,
    }
    await manageAutoShip
      .create(data, updateAutoShipState, prismicGeneral)
      .then(() => setIsUpdatingAutoShip(false))
  }

  useEffect(() => {
    if (deleteVirtual !== null) {
      setOpenDeleteModal(true)
    }
  }, [deleteVirtual])

  const handleButton = () => {
    if (isAuthenticated) {
      if (userType !== 'RETAIL') {
        return {
          content: autoShipId
            ? prismicGeneral.msg_continue_shopping
            : prismicGeneral.msg_create_subscription,
          onClick: autoShipId ? () => navigate('/products') : createNewAutoShip,
          loading: isUpdatingAutoShip,
          disabled:
            !autoShipAddress ||
            !autoShipPaymentInformationId ||
            autoShipItems?.length === 0,
        }
      }
    } else {
      return {
        content: prismicGeneral.msg_sign_in_to_begin,
        onClick: () => navigate('/login'),
      }
    }
  }

  const options = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  }

  const productSubPane = () => {
    return (
      <TabPane
        style={{
          margin: 5,
          border: 'none',
        }}
      >
        <Wrapper>
          {autoShipDate ? (
            <Manage openDeleteModal={setOpenDeleteModal}>
              <Container>
                {shouldHidePcSmartship ? null : (
                  <PcSmartship
                    addPcSmartshipToCart={upgradeUserAddPcSmartship}
                  />
                )}
                {isAuthenticated ? (
                  <>
                    <ShippingDetails
                      address={autoShipAddress}
                      showAddress={!!autoShipAddress}
                      returnUrl="/subscriptions"
                    />
                    <PaymentDetails
                      ccType={cardData?.creditCardType}
                      lastFour={cardData?.lastFourDigits}
                      returnUrl="/subscriptions"
                    />
                  </>
                ) : null}
                <Container align="center">
                  {isAuthenticated && userType === 'RETAIL' ? (
                    <Container align="center" maxWidth="90%">
                      <Container row>
                        <InfoIcon src={infoIcon} />
                        <span style={{ fontStyle: 'italic' }}>
                          {return_here_to_set_up}
                        </span>
                      </Container>
                      <Primary
                        onClick={() => navigate('/cart')}
                        content={prismicCartPage.checkout_now}
                        style={{ marginBottom: 0 }}
                      />
                      <Primary
                        onClick={() => navigate('/')}
                        content={prismicCartPage.keep_shopping[0].text}
                      />
                    </Container>
                  ) : (
                    <Primary {...handleButton()} />
                  )}
                  <Container style={{ marginBottom: '2em', width: '70%' }}>
                    <label
                      dangerouslySetInnerHTML={{
                        __html: formatParagraph(
                          prismicCartPage.terms_of_use_subscriptions[0]
                        ),
                      }}
                    />
                  </Container>
                </Container>
              </Container>
            </Manage>
          ) : (
            <Setup>
              <Primary
                content={continue_shopping[0].text}
                onClick={() => navigate('/')}
              />
            </Setup>
          )}
        </Wrapper>
      </TabPane>
    )
  }

  const buildNextChargeText = () => {
    const date = new Date(virtualAutoShipDate).toLocaleDateString(
      'en-US',
      options
    )
    return `${virtual_next_charge[0].text.replace(
      '{sku}',
      purchasedSku
    )} <b>${date}</b>`
  }

  const geniusSubPane = () => {
    return (
      <TabPane
        style={{
          margin: 5,
          border: 'none',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {virtualAutoShipDate ? (
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: buildNextChargeText(),
                }}
              ></span>
              <PaymentDetails
                ccType={virtualCardData?.creditCardType}
                lastFour={virtualCardData?.lastFourDigits}
                returnUrl="/subscriptions"
                inVirtual={true}
              />
              <Primary onClick={() => setDeleteVirtual(true)}>
                {cancel_my_autoship[0].text}
              </Primary>
            </>
          ) : (
            no_virtual_found[0].text
          )}
        </div>
      </TabPane>
    )
  }

  const panes = [
    {
      menuItem: (
        <MenuItem key={'prodSub'}>{product_sub_tab_name[0].text}</MenuItem>
      ),
      render: () => productSubPane(),
    },
    {
      menuItem: (
        <MenuItem key={'aiSub'}>{virtual_sub_tab_name[0].text}</MenuItem>
      ),
      render: () => geniusSubPane(),
    },
  ]

  return (
    <>
      <Wrapper>
        <Tab
          menu={{
            pointing: true,
            secondary: true,
            style: {
              justifyContent: 'center',
            },
          }}
          panes={panes}
        ></Tab>
      </Wrapper>
      <DeleteAutoshipModal
        open={openDeleteModal}
        setShowModal={setOpenDeleteModal}
        isVirtual={deleteVirtual}
      />
    </>
  )
}

export default AutoShipTab
