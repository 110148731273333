import React, { useState } from 'react'
// Components
import { ProductImages } from '..'
import { SignInModal, AddToAutoship } from '../Autoship'
import ConfigProduct from '../ProductSummary/ConfigurableProduct'
// Context
import { useCartContext } from '../../context/CartContext'
// Hooks
import useAddToCart from '../../hooks/useAddToCart'
import useShowProductPrice from '../../hooks/useShowProductPrice'
import useProductPriceSum from '../../hooks/useProductPriceSum'
// Styles
import {
  AddButton,
  Container,
  MinusButton,
  PlusButton,
  StyledHeader,
  StyledModal,
  StyledModalContent,
  StyledSpan,
} from './AddToModal.styles'

// Helpers
import { buildConfigOptions } from '../../utils/productHelpers'
import { geniusSkus } from '../../utils/constants'

const AddToModal = ({ open, setShowModal, product, autoshipItem }) => {
  const { addToAutoShip, autoShipDate, doesExceedMaxOrder } = useCartContext()

  const {
    image,
    media_gallery,
    name,
    sku,
    stock_status,
    configurable_options = null,
    configurable_product_options_selection = null,
    variants,
  } = product

  const {
    count,
    increment,
    decrement,
    selectedOption,
    selectedOptionProduct,
    isLoading,
    addToCart,
    handleSetOption,
    handleSetOptionProduct,
  } = useAddToCart(product)

  const { ProductPricing } = useShowProductPrice(product)
  const { productPriceSum } = useProductPriceSum(product, count)

  const [cartOption, setCartOption] = useState('')
  const [showSignIn, setShowSignIn] = useState(false)

  const sortedImages = media_gallery?.sort((a, b) => a.position - b.position)
  const isOutOfStock = stock_status === 'OUT_OF_STOCK'

  const nextAutoshipDate = autoShipDate

  const isCartMaxedOut = !geniusSkus.includes(sku)
    ? doesExceedMaxOrder(productPriceSum, 'cart')
    : false
  const isAutoShipMaxedOut = doesExceedMaxOrder(productPriceSum, 'autoship')

  const handleDisableButton = () => {
    if (configurable_options) {
      return !selectedOption
    }
    return false
  }

  const shouldDisableAddToCart =
    isLoading || isOutOfStock || !cartOption || handleDisableButton()

  const handleAddProduct = async () => {
    const prod = selectedOptionProduct ? selectedOptionProduct : product
    if (cartOption === 'both') {
      await addToCart(count, isCartMaxedOut)
      addToAutoShip(prod, count, isAutoShipMaxedOut)
    } else if (cartOption === 'autoship') {
      addToAutoShip(prod, count, isAutoShipMaxedOut)
    } else if (cartOption === 'cart') {
      await addToCart(count, isCartMaxedOut)
    }
    handleClose()
  }

  const handleClose = () => {
    handleSetOptionProduct(null)
    setShowModal(false)
  }

  return (
    <StyledModal
      centered={true}
      closeIcon
      closeOnDimmerClick={true}
      closeOnEscape
      onClose={handleClose}
      open={open}
    >
      {showSignIn && (
        <SignInModal open={showSignIn} setShowModal={setShowSignIn} />
      )}
      <StyledHeader />
      <StyledModalContent>
        {!configurable_options ? (
          <>
            <Container justify="space-between" align="center" maxWidth="800px">
              <Container width="50%" justify="flex-end" align="center">
                <ProductImages mediaGallery={sortedImages} image={image} />
              </Container>
              <Container column align="flex-start" width="48%">
                <div style={{ marginBottom: '10px' }}>
                  <StyledSpan data-qa={`title-${sku}`} isTitle>
                    {name}
                  </StyledSpan>
                </div>
                <ProductPricing />
              </Container>
            </Container>
          </>
        ) : (
          <ConfigProduct
            name={name}
            image={image}
            configurableOptions={buildConfigOptions(
              configurable_options,
              configurable_product_options_selection,
              variants,
              image
            )}
            setSelected={handleSetOption}
            setSelectedOption={handleSetOptionProduct}
            allowClose={false}
          >
            <ProductPricing />
          </ConfigProduct>
        )}

        <Container column align="center" justify="center">
          <StyledSpan data-qa={`count-${count}`} count={count}>
            {count}
          </StyledSpan>
          <Container>
            <MinusButton
              data-qa={`minusButton-${sku}`}
              onClick={() => decrement()}
            >
              <span style={{ fontSize: '2em' }}>-</span>
            </MinusButton>
            <PlusButton
              data-qa={`plusButton-${sku}`}
              onClick={() => increment()}
            >
              <span style={{ fontSize: '2em' }}>+</span>
            </PlusButton>
          </Container>
        </Container>

        <Container margin="1em">
          <AddToAutoship
            cartOption={cartOption}
            setCartOption={setCartOption}
            nextAutoshipDate={nextAutoshipDate}
            autoshipItem={autoshipItem}
          />
        </Container>

        <Container justify="center">
          <AddButton
            content={isOutOfStock ? 'Out of Stock' : 'Add'}
            data-qa={`add-${sku}`}
            disabled={shouldDisableAddToCart}
            loading={isLoading}
            onClick={handleAddProduct}
          />
        </Container>
      </StyledModalContent>
    </StyledModal>
  )
}

export default AddToModal
