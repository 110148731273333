import { get, child, ref } from 'firebase/database'
// Services
import { database } from '../services/firebase'
import environment from './environment'

enum EventStatus {
  NONE = 0,
  VIP = 'vip',
  EVENT = 'event',
}

export const getUserEventStatus = async (id: number) =>
  await get(child(ref(database), `guests/${id}`))
    .then(snapshot => {
      if (snapshot.exists()) {
        return snapshot.val() ? EventStatus.VIP : EventStatus.EVENT
      } else {
        return EventStatus.NONE
      }
    })
    .catch(error => {
      console.error(error)
      return EventStatus.NONE
    })
