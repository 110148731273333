import React from 'react'
import { Link } from 'gatsby'
// Context
import { usePrismic } from '../../context/PrismicContext'
// Styles
import {
  Container,
  MissingText,
  StyledChevron,
  StyledSpan,
} from '../../styles/common'

const PaymentDetails = ({
  lastFour,
  ccType,
  returnUrl = '/cart',
  inVirtual = false,
}) => {
  const {
    prismicData: {
      prismicCartPage: {
        payment_information,
        credit_debit_card,
        ending_in,
        add_card,
      },
    },
  } = usePrismic()

  return (
    <>
      <Container gray padded align="center">
        <h3>{payment_information[0].text}</h3>
      </Container>
      <Link
        data-qa="addCard"
        style={{ width: '100%' }}
        to="/payment-info"
        state={{
          returnUrl,
          inVirtual,
        }}
      >
        <Container padded row justify="space-between" align="center">
          <Container>
            {lastFour ? (
              <>
                <StyledSpan data-qa="cardName" bold>
                  {credit_debit_card[0].text}
                </StyledSpan>
                <span data-qa="cardLastFour">
                  {ccType} {ending_in[0].text} {lastFour}
                </span>
              </>
            ) : (
              <MissingText>{add_card[0].text}</MissingText>
            )}
          </Container>
          <StyledChevron name="chevron right" size="big" />
        </Container>
      </Link>
    </>
  )
}

export default PaymentDetails
