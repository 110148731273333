import styled, { css } from 'styled-components'
import { Image } from 'semantic-ui-react'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: ${props => props.justify || 'space-between'};
  align-items: ${props => props.align || 'center'};
  width: 100%;
  background: black;
  padding: 10px 5px;

  ${props =>
    props.isVip &&
    css`
      background: ${({ theme }) => theme.colors.vipDarkBlue};
    `}
`

export const IconContainer = styled.div`
  float: right;
  display: flex;
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: ${props => props.alignSelf};
  width: ${props => props.width || '25px'};
  height: 100%;
  margin: 5px;
  :hover {
    cursor: pointer;
  }
`

export const Icon = styled(Image)`
  &&& {
    height: ${props => props.height || 'auto'};
    object-fit: contain;
  }
`

export const QIconWrapper = styled.div`
  float: none;
  position: absolute;
  top: 20px;
  left: 55px;
  width: 150px;
`
