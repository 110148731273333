export const parseTextString = string => {
  const newArray = string.split(',,')
  const [name, message, length, regex] = newArray
  return { name, message, length: Number(length), regex }
}

export const formatTaxDataFromPrismic = array =>
  array.map(item => parseTextString(item.text))

export const getTaxInputPlaceholder = length => {
  let array = []
  for (let step = 0; step < length; step++) {
    array.push('*')
  }
  return array.join('')
}

export const getTaxInputWidth = length => {
  const baseWidth = 20
  return `${baseWidth * length}px`
}

export const findArrayItemsInObject = (array, object) =>
  array.map(item => object[item.name])
