import React, { useState, useEffect } from 'react'
// Styles
import {
  StyledModal,
  StyledHeader,
  MarketLabel,
  Container,
  StyledButton,
} from './MarketPicker.styles'
import { StyledInput, StyledSelect } from '../common'
// Context
import { useStoreContext } from '../../context/StoreContext'
import { usePrismic } from '../../context/PrismicContext'
import { getLanguageCountryMap } from './languageData'
import { findStoreByLocale } from '../../utils/storeHelpers'

const MarketPicker = ({ open, onClose }) => {
  const {
    storeLocale,
    langAndCountry,
    saveLangAndCountry,
    prismicData: {
      prismicMarketAndLanguage,
      prismicExistingQModal: { cancel },
    },
  } = usePrismic()
  const languageCountryMap = getLanguageCountryMap(prismicMarketAndLanguage)
  const { handleChangeStore } = useStoreContext()

  const localeArray = langAndCountry.split('-')
  const parsedLanguage = localeArray[0]
  const parsedCountry = localeArray[1]

  const storeLocaleArray = storeLocale.split('-')
  const parsedStoreCode = storeLocaleArray[1].toUpperCase()

  const [countryCode, setCountryCode] = useState(parsedCountry)
  const [languageCode, setLanguageCode] = useState(parsedLanguage)
  const [storeType, setStoreType] = useState('regular')

  // update language to match new selected country options
  useEffect(() => {
    const sameLanguage = languageCountryMap[countryCode]?.find(
      item => item.languageCode === languageCode
    )
    setLanguageCode(
      sameLanguage
        ? sameLanguage?.languageCode
        : languageCountryMap[countryCode]?.[0]?.languageCode ?? ''
    )
  }, [countryCode])

  const currentStoreCode = `${parsedLanguage}_${parsedCountry}`
  const magentoStore = findStoreByLocale(
    countryCode,
    prismicMarketAndLanguage.markets
  )
  // if it is EU but not English then use the country code instead of magento store code (ie: fr_FR vs en_EU)
  const storeOrCountryCode =
    magentoStore.store_code === 'EU' && languageCode !== 'en'
      ? magentoStore?.country_code
      : magentoStore?.store_code

  const storeCode = `${languageCode}_${storeOrCountryCode}`
  const magentoStoreCode = magentoStore?.store_code
  const langAndCountryCode = `${languageCode}-${countryCode}`
  const formattedStoreCode =
    magentoStoreCode === 'EU' && storeType === 'nfr' ? `en_NF` : storeCode

  useEffect(() => {
    if (parsedStoreCode === 'EU') {
      setStoreType('regular')
    }
    if (parsedStoreCode === 'NF') {
      setStoreType('nfr')
    }
  }, [parsedStoreCode])

  useEffect(() => {
    if (countryCode === 'ES' || countryCode === 'IT') {
      setStoreType('regular')
    }
  }, [countryCode])

  const saveMarket = () => {
    handleChangeStore(currentStoreCode, formattedStoreCode)
    localStorage.removeItem('guestCartId')
    saveLangAndCountry(langAndCountryCode)
    onClose()
  }

  const { title, select_market, select_language, save, markets } =
    prismicMarketAndLanguage

  const hideLanguageDropdown =
    languageCountryMap[countryCode]?.length === 1 || storeType === 'nfr'

  return (
    <StyledModal
      closeIcon
      open={open}
      centered={false}
      closeOnDimmerClick={false}
      closeOnEscape
      onClose={onClose}
    >
      <StyledHeader>{title[0].text}</StyledHeader>
      <StyledModal.Content>
        <MarketLabel>{select_market[0].text}</MarketLabel>
        <StyledSelect
          value={countryCode}
          onChange={e => setCountryCode(e.target.value)}
        >
          {markets.map(item => (
            <option key={item.country_code} value={item.country_code}>
              {item.label}
            </option>
          ))}
        </StyledSelect>
        <MarketLabel>{select_language[0].text}</MarketLabel>
        {!hideLanguageDropdown ? (
          <StyledSelect
            value={languageCode}
            onChange={e => setLanguageCode(e.target.value)}
          >
            {languageCountryMap[countryCode].map(item => (
              <option key={item.languageCode} value={item.languageCode}>
                {item.label}
              </option>
            ))}
          </StyledSelect>
        ) : (
          <StyledInput
            disabled
            value={languageCountryMap[countryCode]?.[0]?.label ?? 'English'}
          />
        )}
        {magentoStoreCode === 'EU' ? (
          <>
            <MarketLabel>
              {prismicMarketAndLanguage.store_type || 'Store Type'}
            </MarketLabel>
            {countryCode !== 'IT' && countryCode !== 'ES' ? (
              <StyledSelect
                value={storeType}
                onChange={e => setStoreType(e.target.value)}
              >
                <option value="regular">
                  {prismicMarketAndLanguage.ship_from_eu || 'Ship from EU'}
                </option>
                <option value="nfr">
                  {prismicMarketAndLanguage.ship_from_usa || 'Ship from USA'}
                </option>
              </StyledSelect>
            ) : (
              <StyledInput disabled value="Ship from EU" />
            )}
          </>
        ) : null}
        <Container>
          <StyledButton onClick={onClose}>
            {cancel[0].text.toUpperCase()}
          </StyledButton>
          <StyledButton onClick={saveMarket}>
            {save[0].text.toUpperCase()}
          </StyledButton>
        </Container>
      </StyledModal.Content>
    </StyledModal>
  )
}

export default MarketPicker
