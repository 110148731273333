import React from 'react'
import { StyledSelect, StyledInput } from '../common'
// Contexts
import { usePrismic } from '../../context/PrismicContext'
// Utils
import { formatCountryDropdownOptions } from '../../utils/i18n/countries'

export const CountryDropdown = ({ value, onChange }) => {
  const {
    prismicData: {
      prismicCountriesPerStore: { country_list },
    },
  } = usePrismic()

  const options = formatCountryDropdownOptions(country_list)

  return (
    <>
      {options.length > 1 ? (
        <StyledSelect value={value} onChange={onChange}>
          {options.map(item => (
            <option key={item.value} value={item.value}>
              {item.displayName}
            </option>
          ))}
        </StyledSelect>
      ) : (
        <StyledInput disabled value={options[0].displayName} />
      )}
    </>
  )
}
