import { useState, useEffect } from 'react'
import { useAuthContext } from '../context/AuthContext'
import { useCartContext } from '../context/CartContext'

const useProductPriceSum = (product, count = 1, useWholesale = false) => {
  const { userType } = useAuthContext()
  const { isPcOfferInCart, isAmbOfferInCart } = useCartContext()

  const [productPriceSum, setProductPriceSum] = useState(0)

  useEffect(() => {
    const wholesalePrice =
      product?.wholesale_price && Number(product?.wholesale_price)
    const finalPrice = useWholesale
      ? wholesalePrice
      : product?.price_range?.maximum_price?.final_price?.value
    const regularPrice = useWholesale
      ? wholesalePrice
      : product?.price_range?.maximum_price?.regular_price?.value
    const hasDiscount = finalPrice < regularPrice
    const showDiscount =
      ((isPcOfferInCart || isAmbOfferInCart) &&
        regularPrice > wholesalePrice) ||
      (userType !== 'RETAIL' && hasDiscount)
    const pricing = {
      regularPrice: regularPrice * count,
      finalPrice:
        isPcOfferInCart || isAmbOfferInCart
          ? wholesalePrice * count
          : finalPrice * count,
    }
    setProductPriceSum(showDiscount ? pricing.finalPrice : pricing.regularPrice)
  }, [product, count])

  return { productPriceSum }
}

export default useProductPriceSum
