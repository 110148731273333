import React, { useEffect, useState } from 'react'
import { CircleGradient, StyledCounter } from './Countdown.styles'

export const Countdown = ({ time, timeOutFunc }) => {
  const [timeLeft, setTimeLeft] = useState(time)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft == 0) {
        clearTimeout(timer)
        if (timeOutFunc !== undefined) {
          timeOutFunc()
        }
      } else {
        setTimeLeft((timeLeft: number) => timeLeft - 1)
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [timeLeft])

  return (
    <CircleGradient>
      <StyledCounter>{timeLeft}</StyledCounter>
    </CircleGradient>
  )
}
