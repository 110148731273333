import styled from 'styled-components'
import { Modal } from 'semantic-ui-react'

export const StyledLink = styled.span`
  color: ${props => props.theme.colors.sapphireBlue};
  margin: 10px;
  cursor: pointer;
`

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const SuccessMsg = styled.span`
  margin: 10px;
  font-weight: bold;
  font-size: 1.5rem;
  color: ${props => props.theme.colors.green};
`

export const ErrorMsg = styled.span`
  color: ${props => props.theme.colors.carnelian};
  margin-bottom: 5px;
`

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding: 10px;
  max-width: 300px;
  align-items: center;
`

export const ModalContent = styled(Modal.Content)`
  &&& {
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
`
