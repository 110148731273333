import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Link } from 'gatsby'
import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { Image } from 'semantic-ui-react'

import 'swiper/css'
import 'swiper/css/pagination'
import './styles.css'

// install Swiper modules
SwiperCore.use([Pagination, Autoplay])

const HeroSwiper = ({ slide_images }) => {
  const slides = slide_images.map(slide => ({
    url: slide.image.url,
    alt: slide.image.alt,
  }))

  const renderHeros = slides =>
    slides.map((slide, i) => (
      <SwiperSlide key={`${slide.title}-${i}`}>
        {slide.alt ? (
          <Link to={slide.alt}>
            <Image src={slide.url} fluid />
          </Link>
        ) : (
          <Image src={slide.url} fluid />
        )}
      </SwiperSlide>
    ))

  if (slides?.length === 1) {
    return (
      <>
        {slides[0].alt ? (
          <Link to={slides[0].alt}>
            <Image style={{ maxWidth: 700 }} src={slides[0].url} fluid />
          </Link>
        ) : (
          <Image style={{ maxWidth: 700 }} src={slides[0].url} fluid />
        )}
      </>
    )
  }

  return (
    <>
      <Swiper
        autoplay={{
          delay: 4000,
        }}
        loop={true}
        loopFillGroupWithBlank={true}
        pagination={true}
        slidesPerView={1}
        className="hero-swiper"
      >
        {renderHeros(slides)}
      </Swiper>
    </>
  )
}

export default HeroSwiper
