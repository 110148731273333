import styled from 'styled-components'

interface StyledSelectProps {
  width: string
}

export const StyledSelect = styled('select')<Partial<StyledSelectProps>>`
  padding: 9px;
  width: ${props => props.width || '100%'};
  border: 1px solid #222;
  border-radius: 10px;
  margin-bottom: 1rem;
  font-size: 16px;
  :focus {
    border: 1px solid ${props => props.theme.colors.metallicSeaweed};
    border-radius: 10px;
  }
`
