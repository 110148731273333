import toast from 'react-hot-toast'
// Services
import { Magento } from '../services/magento'
// Types
import {
  CartShippingAddress,
  UserShippingAddress,
  Region,
  CartTypes,
} from '../types/CartTypes'
// -------------------------------- EVENT CART HELPERS --------------------------------

const buildEventAddress = (firstname, lastname) => ({
  firstname,
  lastname,
  street: ['9495 W Coyotes Blvd', ''],
  city: 'Glendale',
  region: 'AZ',
  postcode: '85305',
  telephone: '3854682222',
  country_code: 'US',
  save_in_address_book: false,
})

export const setEventBillingAndShippingOnCart = async (
  cartId: string,
  firstname: string,
  lastname: string,
  setCartData: (cart: CartTypes) => void
) => {
  const address = buildEventAddress(firstname, lastname)

  const magentoShippingObj = {
    cart_id: cartId,
    shipping_addresses: [
      {
        address,
        pickup_location_code: 'Event',
      },
    ],
  }

  const magentoBillingObj = {
    cart_id: cartId,
    billing_address: {
      address,
    },
  }

  // const magentoShippingObj = {
  //   cart_id: cartId,
  //   billing_address: {
  //     address,
  //     same_as_shipping: true,
  //   },
  // }

  // await Magento.Cart.setBillingAddressOnCart(magentoShippingObj)
  // .then(setCartData)
  //   .then(() => console.log('EVENT Shipping/Billing address set!'))
  //   .catch(err => console.log(err))

  await Magento.Cart.setShippingAddressOnCart(magentoShippingObj)
    .then(
      async () =>
        await Magento.Cart.setBillingAddressOnCart(magentoBillingObj)
          .then(setCartData)
          .catch(err => console.log(err))
    )
    .catch(err => console.log(err))
}

// -------------------------------- EVENT CART HELPERS (end) --------------------------------

function buildCartAddressObject(data: UserShippingAddress) {
  if (typeof data !== 'object') return null
  let address = {
    ...data,
    region: getRegionCode(data.region),
    region_id: data.region.region_id,
    save_in_address_book: false,
  }

  // add & remove required properties
  delete address.id
  delete address.default_shipping
  return address
}

export const setBillingAndShippingAddressOnCart = async (
  cartId: string,
  addressData: UserShippingAddress,
  setCartData: (cart: CartTypes) => void,
  isDefault: boolean,
  prismicGeneral
) => {
  // we are setting shipping AND billing address
  const magentoShippingObj = {
    cart_id: cartId,
    billing_address: {
      address: buildCartAddressObject(addressData),
      use_for_shipping: true,
    },
  }
  await Magento.Cart.setBillingAddressOnCart(magentoShippingObj)
    .then(setCartData)
    .then(() => {
      if (isDefault)
        toast.success(prismicGeneral.msg_default_shipping_address_set)
      else toast.success(prismicGeneral.msg_shipping_address_set)
    })
    .catch(err => console.log(err))
}

function buildUserAddressObject(data, default_shipping) {
  if (typeof data !== 'object') return null
  const {
    firstname,
    lastname,
    address1,
    address2,
    city,
    state,
    postcode,
    telephone,
    country_code,
  } = data
  const street = [address1, address2]
  const [region_id, region_code, region] = state.split(',')
  const regionObj = { region_id, region_code, region }

  return {
    firstname,
    lastname,
    street,
    city,
    region: regionObj,
    postcode,
    telephone,
    country_code,
    default_shipping,
    default_billing: default_shipping,
  }
}

export const createUserAddress = (
  data,
  isDefault,
  updateUser,
  navigate,
  returnUrl,
  prismicGeneral
) => {
  const newShippingAddress = {
    input: buildUserAddressObject(data, isDefault),
  }

  Magento.User.createCustomerAddress(newShippingAddress)
    .then(() => toast.success(prismicGeneral.msg_new_shipping_address_added))
    .then(updateUser)
    .then(() =>
      navigate('/shipping-info', {
        state: {
          returnUrl,
        },
      })
    )
}

export const createNewUserAddress = (
  data,
  isDefault,
  updateUser
) => {
  const newShippingAddress = {
    input: buildUserAddressObject(data, isDefault),
  }

  Magento.User.createCustomerAddress(newShippingAddress)
    .then(updateUser)
}

export const updateUserAddress = (
  addressId,
  data,
  isDefault,
  updateUser,
  navigate,
  returnUrl,
  prismicGeneral
) => {
  const updatedShippingAddress = {
    id: addressId,
    input: buildUserAddressObject(data, isDefault),
  }
  Magento.User.updateCustomerAddress(updatedShippingAddress)
    .then(() => toast.success(prismicGeneral.msg_shipping_address_updated))
    .then(updateUser)
    .then(() =>
      navigate('/shipping-info', {
        state: {
          returnUrl,
        },
      })
    )
}

export const getRegionCode = (region: Region | String): string => {
  if ((region as Region)?.region_code) return (region as Region).region_code
  return region as string
}

/**
 * Magento does not provide an id for the address in the cart so we have to select the closest match.
 * Compares street, postal code, city, and state and returns an exact match. If there's more than one
 * match, it returns the first
 */
export const getSelectedAddress = (
  userAddresses: Partial<UserShippingAddress>[],
  cartAddress: Partial<CartShippingAddress>
) => {
  return userAddresses?.find(({ street, postcode, city }) => {
    return (
      street[0] === cartAddress?.street[0] &&
      postcode === cartAddress?.postcode &&
      city === cartAddress?.city
    )
  })
}
