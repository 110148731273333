import { gql } from 'graphql-request'

export const GET_ACTIVE_COUNTRIES = gql`
  query ActiveCountries($language: String) {
    activeCountries(language: $language) {
      countryId
      countryCode
      countryName
      pictureUrl
      regions {
        regionId
        regionName
        regionCode
      }
    }
  }
`
