import React from 'react'
import { navigate } from 'gatsby'
// Components
import { Primary as CloseButton } from '../'
// Context
import { usePrismic } from '../../context/PrismicContext'
// Styles
import {
  Container,
  StyledImage,
  StyledModal,
  StyledSpan,
} from '../ProductCard/AddToModal.styles'
// Utils
import environment from '../../utils/environment'

const EventLoginModal = ({ open, handleCloseModal }) => {
  const {
    prismicData: {
      prismicHomepage: {
        event_image: { url },
        event_name,
      },
      prismicGeneral: {
        btn_visit_shop_q,
        msg_were_sorry,
        msg_event_site_is_limited,
        msg_event_site_is_limited_vip,
      },
    },
  } = usePrismic()

  const handleClose = () => {
    handleCloseModal(false)
    navigate('https://qsciences.com')
  }

  return (
    <StyledModal
      centered={true}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      onClose={handleClose}
      open={open}
    >
      <StyledImage style src={url} fluid />
      <Container column align="center" padding="1em">
        <StyledSpan istitle>{msg_were_sorry}</StyledSpan>
        <span style={{ textAlign: 'center' }}>
          {environment.IS_VIP_SITE
            ? msg_event_site_is_limited_vip
            : msg_event_site_is_limited}
        </span>
        <CloseButton onClick={handleClose} content={btn_visit_shop_q} />
      </Container>
    </StyledModal>
  )
}

export default EventLoginModal
