import { GraphQLClient } from 'graphql-request'
// API
import { User } from './api/customer'
import { Store } from './api/store'
import { Cart } from './api/cart'
// Utils
import environment from '../../utils/environment'

const endpoint = environment.MAGENTO_API_URL

const client = new GraphQLClient(endpoint)
const autoshipClient = new GraphQLClient(endpoint)
const historyClient = new GraphQLClient(endpoint)

export const Magento = {
  Cart: Cart(client),
  Store: Store(client),
  User: User(client),
}

export const MagentoAutoship = {
  Cart: Cart(autoshipClient),
  Store: Store(autoshipClient),
  User: User(autoshipClient),
}

export const MagentoHistory = {
  User: User(historyClient),
}
