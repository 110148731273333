import { Image } from 'semantic-ui-react'
import styled from 'styled-components'
import { StyledButton } from '../Buttons/Buttons.styles'
import check from '../../svg/CheckCircle.svg'

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background: ${({ theme }) => theme.colors.powderBlue};
  padding: 1.5em;
  width: 100%;
`

export const Title = styled.div`
  font-size: 1.4em;
  font-weight: bolder;
  margin-bottom: 8px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
`

export const StyledText = styled.span``

export const ListContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5em;
`

export const StartSavingButton = styled(StyledButton)`
  &&& {
    align-self: center;
    background-color: ${({ theme }) => theme.colors.powderBlue};
    color: #fffef9;
    margin-bottom: 0.3em;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.black};
    text-transform: uppercase;
    padding: 0.7em;

    :active,
    :focus,
    :hover {
      background-color: ${({ theme }) => theme.colors.powderBlue};
      box-shadow: none;
      border: 1px solid ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`

export const StyledCheck = styled(Image).attrs({
  src: check,
})`
  &&& {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
`
