import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'
// Components
import { ProductCard } from '..'
// Styles
import 'swiper/css'
import 'swiper/css/pagination'
import './styles.css'

SwiperCore.use([Pagination])

const FeaturedProducts = ({ products }) => {
  const filteredProducts = products.filter(
    product => product.show_in_category === 1
  )
  const renderProducts = products =>
    products.map((product, i) => (
      <SwiperSlide key={`${product.title}-${i}`}>
        {/* NOTE: Set trending={true} to show reviews */}
        <ProductCard trending={false} {...product} />
      </SwiperSlide>
    ))

  return (
    <>
      <Swiper
        breakpoints={{
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
        loop={true}
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        slidesPerView={2}
        spaceBetween={20}
        className="featured-products"
      >
        {renderProducts(filteredProducts)}
      </Swiper>
    </>
  )
}

export default FeaturedProducts
