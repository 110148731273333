import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { ShoppingWith } from './ShoppingWith.styles'

const ShoppingWithBanner = () => {
  const {
    prismicData: {
      prismicHomepage: { click_here, shopping_with, welcome },
    },
  } = usePrismic()

  const {
    isAuthenticated,
    isVipSite,
    qUser,
    qUserUpline,
    userType,
    manageReferral: { isReferral, referralData },
  } = useAuthContext()

  // if user cancels enrollment, reset upline user so it doesn't show "undefined"
  const [uplineUser, setUplineUser] = useState('')
  useEffect(() => {
    const upline =
      qUserUpline?.displayName === 'Par Partners LLC' || !qUserUpline
        ? 'Q Sciences'
        : qUserUpline?.displayName ?? ''
    setUplineUser(upline)
  }, [qUserUpline, isAuthenticated])

  const ambassadorName = qUser?.displayName ?? ''

  const showShoppingWithBanner = (type: string) => {
    if (!isAuthenticated) {
      if (isReferral && referralData?.ambassadorName)
        return (
          <ShoppingWith
            data-qa={`enrollerName-${referralData?.ambassadorName}`}
          >{`${shopping_with[0].text} ${referralData?.ambassadorName}`}</ShoppingWith>
        )

      return (
        <ShoppingWith data-qa="signInButton">
          <Link style={{ color: 'white' }} to="/login">
            {click_here[0].text}
          </Link>
        </ShoppingWith>
      )
    }

    return {
      RETAIL: (
        <ShoppingWith data-qa={`uplineName-qsciences`}>{`${
          shopping_with[0].text
        } ${
          qUserUpline?.displayName === 'Par Partners LLC' || qUserUpline?.displayName === 'Orphan'
            ? 'Q Sciences'
            : qUserUpline?.displayName
        }`}</ShoppingWith>
      ),
      PREFERRED: (
        <ShoppingWith
          data-qa={`uplineName-${uplineUser}`}
        >{`${shopping_with[0].text} ${uplineUser}`}</ShoppingWith>
      ),
      AMBASSADOR: (
        <ShoppingWith
          data-qa={`ambassadorName-${ambassadorName}`}
        >{`${welcome[0].text} ${ambassadorName}`}</ShoppingWith>
      ),
    }[type]
  }

  return <>{showShoppingWithBanner(userType)}</>
}

export default ShoppingWithBanner
