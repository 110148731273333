import React from 'react'
import {
  Container,
  StyledModal,
  StyledModalContent,
  StyledParagraph,
  CenteredQLogo,
} from './Maintenance.styles'
import { Button, Header } from 'semantic-ui-react'
import { usePrismic } from '../../context/PrismicContext'

const styles = {
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  image: {
    height: '128px',
    width: '128px',
    margin: '1rem',
  },
  button: {
    width: '100%',
  },
}

const onClick = () => {
  console.log('in on click')
}
const DownForMaintenance = ({ open, onClose, onYes }) => {
  const {
    prismicData: {
      prismicLoginPage: { logo },
    },
  } = usePrismic()
  return (
    <StyledModal open={open} centered={true} onClose={onClose}>
      <StyledModalContent>
        <Container direction="column">
          <StyledParagraph>
            qsciences.com is currently down for maintenance.
            <br />
            Sorry for the inconvenience.
            <br />
            <br />
            <br />
            <CenteredQLogo src={logo.url} width={200}></CenteredQLogo>
          </StyledParagraph>
          <Button
            onClick={onClick}
            style={{
              opacity: 0,
              cursor: 'default',
            }}
          ></Button>
        </Container>
      </StyledModalContent>
    </StyledModal>
  )
}

export default DownForMaintenance
