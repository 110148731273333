import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import { useLocation } from '@reach/router'
// Components
import { SearchMenu } from '../'
import ShoppingCartIcon from './ShoppingCartIcon'
import AddToCartPopup from './AddToCartPopup'
// Context
import { useStoreContext } from '../../context/StoreContext'
import { useAuthContext } from '../../context/AuthContext'
import { useCartContext } from '../../context/CartContext'
import { usePrismic } from '../../context/PrismicContext'
// Styles
import {
  Wrapper,
  IconContainer,
  IconWrapper,
  Icon,
  QIconWrapper,
} from './Header.styles'
import { colors } from '../../styles/GlobalStyle'
// Images/Icons
import bars from '../../images/main/Bars.png'
import search from '../../images/main/Search.png'
import cart from '../../images/main/Cart.png'
import logo from '../../svg/logoQ.svg'
import autoShip from '../../svg/icSubscription2.svg'

const Header = ({ showSidebar }) => {
  const {
    storeCountryCode,
    prismicData: { prismicGeneral, prismicAutoshipPage },
  } = usePrismic()

  const [showSearch, setShowSearch] = useState(false)
  const {
    userType,
    isAuthenticated,
    isEventSite,
    isVipSite,
    manageReferral: { isEnrollmentComplete },
  } = useAuthContext()
  const { isStoreLoading } = useStoreContext()
  const {
    cartData,
    autoShipItems,
    isPcOfferInCart,
    isAmbOfferInCart,
    autoShipId,
  } = useCartContext()

  const cartCount = cartData?.items?.reduce(
    (agg, { quantity }) => agg + (quantity || 0),
    0
  )

  const autoShipCount = autoShipItems
    .filter(x => x.product !== undefined)
    .reduce((agg, { quantity }) => agg + (quantity || 0), 0)

  const location = useLocation()

  const shouldShowEnrollmentMessage =
    isAuthenticated &&
    isAmbOfferInCart &&
    !isEnrollmentComplete &&
    location.pathname !== '/enrollment-form'

  const showAutoShip =
    !(isVipSite || isEventSite) &&
    (isPcOfferInCart || isAmbOfferInCart || userType !== 'RETAIL') &&
    storeCountryCode !== 'nf'

  const shouldShowAutoShipMessage =
    autoShipItems.length > 0 &&
    autoShipId === 0 &&
    isAuthenticated &&
    location.pathname !== '/subscriptions' &&
    location.pathname !== '/shipping-info' &&
    location.pathname !== '/payment-info'

  const handleShowSearch = () => {
    if (isStoreLoading) return
    setShowSearch(!showSearch)
  }

  const handleIconClick = uri => {
    const { pathname } = location
    pathname === uri ? navigate(-1) : navigate(`${uri}`)
  }

  return (
    <>
      <Wrapper isVip={isVipSite}>
        <IconWrapper data-qa="menuButton" onClick={showSidebar} width="35px">
          <Icon height="30px" src={bars} />
        </IconWrapper>
        <QIconWrapper data-qa="qIcon" as={Link} to="/">
          <Icon src={logo} />
        </QIconWrapper>
        <IconContainer>
          <IconWrapper data-qa="search" onClick={handleShowSearch} width="30px">
            <Icon src={search} disabled={isStoreLoading} />
          </IconWrapper>
          <IconWrapper
            data-qa="cart"
            onClick={() => handleIconClick('/cart')}
            width="32px"
          >
            <ShoppingCartIcon cartCount={cartCount}>
              <Icon src={cart} />
            </ShoppingCartIcon>
          </IconWrapper>
          {showAutoShip ? (
            <IconWrapper
              data-qa="autoShip"
              onClick={() => handleIconClick('/subscriptions')}
              width="35px"
            >
              <ShoppingCartIcon cartCount={autoShipCount} isAutoShip={true}>
                <Icon src={autoShip} />
              </ShoppingCartIcon>
            </IconWrapper>
          ) : null}
        </IconContainer>
      </Wrapper>

      {showSearch ? (
        <Wrapper justify="center">
          <SearchMenu type="link" callback={handleShowSearch} />
        </Wrapper>
      ) : null}

      {shouldShowEnrollmentMessage ? (
        <Wrapper
          justify="center"
          style={{ backgroundColor: colors.carnelian, marginBottom: '2px' }}
        >
          <Link to="/enrollment-form">
            <span style={{ color: 'white' }}>
              {prismicGeneral.msg_click_here_to_complete_enrollment}
            </span>
          </Link>
        </Wrapper>
      ) : null}

      {shouldShowAutoShipMessage ? (
        <Wrapper justify="center" style={{ backgroundColor: colors.carnelian }}>
          <Link to="/subscriptions">
            <span style={{ color: 'white' }}>
              {prismicAutoshipPage.finish_setting_up_your_subscription_order}
            </span>
          </Link>
        </Wrapper>
      ) : null}

      <AddToCartPopup />
    </>
  )
}

Header.propTypes = {
  showSidebar: PropTypes.func,
}

export default Header
