import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { Button, Radio } from 'semantic-ui-react'
// Components
import { ProductImages } from '..'
// Styles
import { Container, StyledSpan } from './ProductSummary.styles'

const ConfigurableProduct = ({
  configurableOptions,
  image,
  name,
  setSelected,
  setSelectedOption,
  children,
  allowClose=true
}) => {
  const [checked, setChecked] = useState(null)

  const configObjArr = Object.values(configurableOptions)
  const optionImage = configurableOptions?.[checked]?.image

  const handleChange = (option) => {
    setChecked(option.uid)
    setSelected(option.uid)
    setSelectedOption(option)
  }

  return (
    <>
      {allowClose ? (<Container padding="1em">
        <Button
          basic
          circular
          icon="x"
          onClick={() => navigate(-1)}
          size="small"
        />
      </Container>): null}
      <Container justify="center" align="center" maxWidth="800px">
        <Container justify="flex-end" align="center">
          <ProductImages
            mediaGallery={[]}
            image={!!checked && optionImage ? optionImage : image}
          />
        </Container>
        <Container column align="flex-start">
          <div style={{ marginBottom: '10px' }}>
            <StyledSpan isTitle>{name}</StyledSpan>
          </div>
          {children}
          {configObjArr.length > 0 && (
            <div
              style={{
                marginTop: '10px',
                display: 'flex',
                flexFlow: 'column nowrap',
              }}
            >
              {configObjArr.map(option => (
                <Radio
                  checked={checked === option.uid}
                  key={option.uid}
                  label={option.label}
                  name="optionsGroup"
                  onChange={()=> handleChange(option)}
                  style={{ marginTop: '5px' }}
                  value={option.uid}
                />
              ))}
            </div>
          )}
        </Container>
      </Container>
    </>
  )
}

export default ConfigurableProduct
