import React, { useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { Primary } from '.'
import { useCartContext } from '../../context/CartContext'

const StyledButton = styled(Primary)`
  &&& {
    box-shadow: none;
    margin: 1em auto;
    width: 200px;
  }
`

interface ReorderProps {
  content: string
  orderNumber: string
}

const ReorderButton = ({ content, orderNumber }: ReorderProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const { manageCart, buildCart } = useCartContext()
  function handleClick() {
    setIsLoading(true)
    manageCart
      .handleReorderItems(orderNumber, buildCart)
      .then(() => navigate('/'))
  }

  return (
    <StyledButton onClick={handleClick} content={content} loading={isLoading} />
  )
}

export default ReorderButton
