import React from 'react'
import { Icon } from 'semantic-ui-react'

import { Container } from './SocialLinks.styles'

const SocialLinks = () => (
  <Container>
    <a
      href="https://www.facebook.com/QSciences"
      target="_blank"
      rel="noreferrer"
      data-qa="menuFacebook"
    >
      <Icon name="facebook f" size="big" />
    </a>
    <a
      href="https://www.instagram.com/qsciences"
      target="_blank"
      rel="noreferrer"
      data-qa="menuInstagram"
    >
      <Icon name="instagram" size="big" />
    </a>
    <a
      href="https://twitter.com/Qsciences"
      target="_blank"
      rel="noreferrer"
      data-qa="menuTwitter"
    >
      <Icon name="twitter" size="big" />
    </a>
    <a
      href="https://www.linkedin.com/company/q-sciences"
      target="_blank"
      rel="noreferrer"
      data-qa="menuLinkedin"
    >
      <Icon name="linkedin" size="big" />
    </a>
  </Container>
)

export default SocialLinks
