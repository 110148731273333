import React, { useState } from 'react'
import { Drawer, Container } from '..'
// Context
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { FilterWrapper, FilterMenuItem } from './Menu.styles'

const FilterSortMenu = ({ types, benefits, handleFilter }) => {
  const [selected, setSelected] = useState(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState(null)
  const {
    prismicData: {
      prismicProductsPage: { shop_by_type_or_benefit, all_products },
    },
  } = usePrismic()

  const typeOptions = Object.entries(types)
    .map(type => {
      const urlKey = type[0]
      const name = type[1].name
      return { key: urlKey, text: name, value: urlKey }
    })
    .filter(item => item.text)

  const benefitOptions = Object.entries(benefits)
    .map(benefit => {
      const urlKey = benefit[0]
      const name = benefit[1].name
      return { key: urlKey, text: name, value: urlKey }
    })
    .filter(item => item.text)
  const handleChange = (e, value, text) => {
    setSelected(value)
    handleFilter(value, text)
  }

  const resetFilter = () => {
    setSelected(null)
    handleFilter('', '')
    setIsDrawerOpen(false)
  }

  const toggleDrawer = () => {
    if (isDrawerOpen === null) return setIsDrawerOpen(true)
    return setIsDrawerOpen(state => !state)
  }

  return (
    <FilterWrapper>
      <Container padding="0 10px">
        <Container style={{ maxWidth: 400 }} padding="0">
          <Drawer
            title={shop_by_type_or_benefit}
            open={isDrawerOpen}
            onClick={toggleDrawer}
          >
            <Container padding="1rem" align="flex-start">
              <Container direction="column" align="flex-start" padding="0">
                {typeOptions.map(type => (
                  <FilterMenuItem
                    selected={selected === type.key}
                    onClick={e => {
                      handleChange(e, type.value, 'By Type')
                      setIsDrawerOpen(false)
                    }}
                    key={type.key}
                  >{`+ ${type.text}`}</FilterMenuItem>
                ))}
              </Container>
              <Container direction="column" align="flex-start" padding="0">
                {benefitOptions.map(benefit => (
                  <FilterMenuItem
                    selected={selected === benefit.key}
                    onClick={e => {
                      handleChange(e, benefit.value, 'By Benefit')
                      setIsDrawerOpen(false)
                    }}
                    key={benefit.key}
                  >{`+ ${benefit.text}`}</FilterMenuItem>
                ))}
                <FilterMenuItem
                  selected={selected === null}
                  onClick={resetFilter}
                  style={{ marginTop: 20 }}
                >{`+ ${all_products[0].text}`}</FilterMenuItem>
              </Container>
            </Container>
          </Drawer>
        </Container>
      </Container>
    </FilterWrapper>
  )
}

export default FilterSortMenu
