/* eslint-disable jsx-a11y/no-autofocus */

import React, { useState } from 'react'
import { Modal, Header } from 'semantic-ui-react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// Components
import {
  Primary as SubmitButton,
  Warning as CancelButton,
  StyledInput,
} from '../'
// Context
import { usePrismic } from '../../context/PrismicContext'
// Hooks
import useShowError from '../../hooks/useShowError'
// Styles
import {
  Container,
  ErrorMsg,
  Logo,
  StyledButton,
  StyledForm,
  StyledModal,
} from './Verification.styles'
// Utils
import { buildEnterCode } from '../../utils/validations'

const EnterCode = ({ isOpen, enterCodeCallback, cancel, resendCode }) => {
  const {
    prismicData: {
      prismicGeneral,
      prismicEnterCodeModal: {
        cancel: cancelText,
        continue: continueText,
        didnt_get_verification_code,
        header,
        placeholder,
        resend_code,
      },
    },
  } = usePrismic()

  const [open, setOpen] = useState(isOpen)

  const [error, setError, showError] = useShowError()

  const resetAndShowError = message => {
    reset()
    setError(message)
  }

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(buildEnterCode(prismicGeneral)),
  })

  const onSubmit = data => {
    enterCodeCallback(data.code, resetAndShowError)
  }

  const handleClose = () => {
    cancel()
    setOpen(false)
  }

  const handleResendCode = () => {
    resendCode()
  }

  return (
    <StyledModal
      centered={false}
      closeOnDimmerClick={false}
      dimmer="blurring"
      onClose={handleClose}
      open={open}
    >
      <Container direction="column">
        <Logo />
        <Modal.Description>
          <Header textAlign="center">{header[0].text}</Header>
        </Modal.Description>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          {error && showError()}
          <Container direction="column" maxWidth="300px">
            <StyledInput
              {...register('code')}
              placeholder={placeholder[0].text}
              onFocus={() => setError('')}
              autoFocus
            />
            <ErrorMsg>{errors.code?.message}</ErrorMsg>
          </Container>
          <SubmitButton type="submit">{continueText[0].text}</SubmitButton>
          <CancelButton onClick={cancel}>{cancelText[0].text}</CancelButton>
        </StyledForm>
        <Container justify="space-evenly" maxWidth="400px">
          {didnt_get_verification_code[0].text}
          <StyledButton onClick={handleResendCode}>
            {resend_code[0].text}
          </StyledButton>
        </Container>
      </Container>
    </StyledModal>
  )
}

export default EnterCode
