import React, { useState, useEffect, useContext } from 'react'
import toast from 'react-hot-toast'
import { navigate } from 'gatsby'
// Contexts
import { useAuthContext } from './AuthContext'
import { useStoreContext } from './StoreContext'
import { usePrismic } from '../context/PrismicContext'
// Services
import { Magento } from '../services/magento'
import { QService } from '../services/q-services'
// Types
import {
  AutoShipItemsProduct,
  CartContextTypes,
  InitAutoShipState,
  InitCartState,
  InitVirtualAutoShipState,
} from '../types/contexts/CartContextTypes'
import { AutoShipDataResponse } from '../types/AutoShipTypes'
// Utils
import { Logger } from '../utils/logger'
import {
  displayOutOfStockError,
  handleOutOfStock,
} from '../utils/productHelpers'
import manageCart from '../utils/cartHelpers'
import manageAutoShip, { buildAutoShipItems } from '../utils/autoShipHelpers'
import { enrollSkus } from '../utils/constants'
import { getUserEventStatus } from '../utils/eventSiteHelpers'
import { findStoreByLocale } from '../utils/storeHelpers'
// Components
import { EventLoginModal } from '../components'
import MaxOrderModal from '../components/Cart/MaxOrderModal'
import WrongAutoshipModal from '../components/Autoship/WrongAutoshipModal'
import {
  initialCartState,
  initialAutoShipState,
  initialVirtualAutoShipState,
} from './initialStates'
import { formatDate } from '../utils/dateHelpers'

enum UserType {
  AMBASSADOR = 'AMBASSADOR',
  PREFERRED = 'PREFERRED',
  RETAIL = 'RETAIL',
}

const EXCEPTIONSKUS = [
  'PK0AUNZ015AU',
  'PK0AUNZ014AU',
  'RQ001AU',
  'BI501AU',
  'AU710029AU',
]
const Cart = React.createContext<Partial<CartContextTypes>>({})

export const useCartContext = () => useContext(Cart)

const CartProvider = ({ children }) => {
  const [
    {
      cartData,
      cartId,
      guestCartId,
      isAmbOfferInCart,
      isAmbPremiumOfferInCart,
      isRenewalOfferInCart,
      isPcOfferInCart,
      isPcSmartshipInCart,
      isPlacingOrder,
      isShippingSet,
      isShowCartPopup,
    },
    setCartState,
  ] = useState<InitCartState>(initialCartState)

  const [
    {
      autoShipAddress,
      autoShipData,
      autoShipDate,
      autoShipId,
      autoShipItems,
      autoShipPaymentInformationId,
    },
    setAutoShipState,
  ] = useState<InitAutoShipState>(
    localStorage.getItem('autoShipState')
      ? JSON.parse(localStorage.getItem('autoShipState') as string)
      : initialAutoShipState
  )
  const [
    {
      virtualAutoShipData,
      virtualAutoShipDate,
      virtualAutoShipId,
      virtualAutoShipItems,
      virtualAutoShipPaymentInformationId,
    },
    setVirtualAutoShip,
  ] = useState(initialVirtualAutoShipState)

  const {
    isAuthenticated,
    isEventSite,
    isVipSite,
    handleUserLogout,
    isVerified,
    isAuthLoading,
    authToggle,
    magentoUser,
    qUser,
    shouldAddPcFee,
    shouldAddPcSmartship,
    userType,
    handleSetUserState,
    manageReferral: { isEnrollmentComplete },
    handleSetAuthState,
    isInMaintenance,
    maintenanceVerified,
  } = useAuthContext()
  const {
    storeCountryCode,
    langAndCountry,
    prismicData: { prismicCartPage, prismicGeneral, prismicMarketAndLanguage },
  } = usePrismic()

  const { isStoreLoading, autoshipStoreData, handleLoadLandingPage } =
    useStoreContext()
  const ASProducts =
    Object?.keys(autoshipStoreData)?.map(item => ({
      ...autoshipStoreData[item],
    })) ?? []

  useEffect(() => {
    if (isAuthLoading) return
    const existingCartId = localStorage.getItem('guestCartId') ?? guestCartId
    if (qUser) userCart(existingCartId)
    else guestCart(existingCartId)
  }, [isAuthLoading])

  useEffect(() => {
    if (isPcOfferInCart || isAmbOfferInCart || isAmbPremiumOfferInCart) {
      handleLoadLandingPage(
        qUser,
        isPcOfferInCart,
        isAmbOfferInCart,
        isAmbPremiumOfferInCart
      )
    }
  }, [isPcOfferInCart, isAmbOfferInCart, isAmbPremiumOfferInCart])

  function findAndReturnProductInfo(sku: string) {
    return cartData?.items?.find(({ product }) => product.sku === sku)
  }

  const handleSetCustomerGroup = async (
    customerGroup: 'RETAIL' | 'PREFERRED' | 'AMBASSADOR'
  ) => {
    if (!isAuthenticated) return
    return await QService.User.setCustomerGroup(customerGroup).catch(e => {
      console.log(e)
      // e.message.includes('not authorized') && refreshAuthState()
    })
  }

  // ADD PC FEE TO CART
  useEffect(() => {
    if (!cartData) return
    const pcFeeItem = findAndReturnProductInfo(enrollSkus.pcFee)
    const pcSmartshipItem = findAndReturnProductInfo(enrollSkus.pcSmartship)

    setCartState(prev => ({
      ...prev,
      isPcOfferInCart: !!pcFeeItem,
      isPcSmartshipInCart: !!pcSmartshipItem,
    }))

    if (!pcFeeItem) {
      if (shouldAddPcFee) {
        upgradeUserAddPcFee().then(() =>
          handleSetUserState({ shouldAddPcFee: false })
        )
        return
      }
      return
    } else {
      const { uid, quantity } = pcFeeItem
      // AUTO REMOVE PC FEE IF USER IS NOT RETAIL AND USER CART IS ACTIVE
      if (userType !== 'RETAIL' && cartData.email) {
        removeFee(uid, cartData.id)
        return
      }
      // CHECK TO ENSURE THAT ONLY (1) PCFEE EXISTS IN CART
      ensureOnlyOneQty(uid, quantity)
    }

    if (!pcSmartshipItem) {
      if (shouldAddPcSmartship) {
        upgradeUserAddPcSmartship().then(() =>
          handleSetUserState({ shouldAddPcSmartship: false })
        )
        return
      }
      return
    } else {
      const { uid, quantity } = pcSmartshipItem
      //REMOVES PC SMARTSHIP IF USER IS NOT REATAIL AN CART IS ACTIVE
      if (userType !== 'RETAIL' && cartData.email) {
        removeFee(uid, cartData.id)
        return
      }
      //REMOVE ANY SMARTSHIP DUPLICATES
      ensureOnlyOneQty(uid, quantity)
    }
  }, [cartData?.items, userType, shouldAddPcFee, authToggle])

  // ADD AMBASSADOR FEE TO CART
  const [ambEnrollProducts, setAmbEnrollProducts] = useState({
    premium: null,
    basic: null,
  })

  const [selectedAmbEnrollSku, setSelectedAmbEnrollSku] = useState(null)

  const addSelectedAmbFeeToCart = async () => {
    if (!isAuthenticated) return navigate('/login')
    if (!cartId) return
    setCartState(prev => ({
      ...prev,
      isAmbOfferInCart: true,
      isPcOfferInCart: false,
    }))
    // IF PC FEE EXISTS, REMOVE PC FEE
    const pcFeeItem = findAndReturnProductInfo(enrollSkus.pcFee)
    if (pcFeeItem) {
      removeFee(pcFeeItem.uid, cartId)
    }
    await upgradeToAmbassadorAddItems()
  }

  // ADD AMB RENEWAL FEE TO CART
  const [ambRenewalProducts, setAmbRenewalProducts] = useState({
    premium: null,
    basic: null,
  })

  const [selectedAmbRenewalSku, setSelectedAmbRenewalSku] = useState(null)

  const addSelectedAmbRenwalFeeToCart = async () => {
    if (!isAuthenticated) return navigate('/login')
    if (!cartId) return
    // CHECK TO ENSURE THAT ONLY (1) AMB RENEWAL FEE EXISTS IN CART
    const selectedAmbRenewalFeeItem = findAndReturnProductInfo(
      selectedAmbRenewalSku
    )
    if (selectedAmbRenewalFeeItem) return
    await manageCart.addItemToCart(
      { sku: selectedAmbRenewalSku },
      1,
      cartId,
      buildCart,
      prismicGeneral
    )
    // IF OTHER FEE EXISTS, REMOVE IT
    if (selectedAmbRenewalSku !== null) {
      const nonSelectedAmbRenewalSku =
        selectedAmbRenewalSku === enrollSkus.ambRenewalPremiumSku
          ? enrollSkus.ambRenewalBasicSku
          : enrollSkus.ambRenewalPremiumSku

      const nonSelectedAmbRenewalItem = findAndReturnProductInfo(
        nonSelectedAmbRenewalSku
      )
      if (nonSelectedAmbRenewalItem) {
        removeFee(nonSelectedAmbRenewalItem.uid, cartId)
      }
    }
  }

  useEffect(() => {
    if (!isAuthenticated || !cartId) return
    const ambPremiumSkuItem = findAndReturnProductInfo(enrollSkus.ambPremiumSku)
    const ambBasicSkuItem = findAndReturnProductInfo(enrollSkus.ambBasicSku)
    const ambPremiumRenSkuItem = findAndReturnProductInfo(
      enrollSkus.ambRenewalPremiumSku
    )
    const ambBasicRenSkuItem = findAndReturnProductInfo(
      enrollSkus.ambRenewalBasicSku
    )
    setCartState(prev => ({
      ...prev,
      isAmbPremiumOfferInCart: !!ambPremiumSkuItem,
      isAmbOfferInCart: !!ambPremiumSkuItem || !!ambBasicSkuItem,
      isRenewalOfferInCart: !!ambPremiumRenSkuItem || !!ambBasicRenSkuItem,
    }))
    // CHECK TO ENSURE THAT ONLY (1) AMB ENROLL FEE EXISTS IN CART
    const selectedAmbFeeItem = findAndReturnProductInfo(selectedAmbEnrollSku)
    if (selectedAmbFeeItem) {
      const { uid, quantity } = selectedAmbFeeItem
      ensureOnlyOneQty(uid, quantity)
    }
    // IF OTHER TIER AMB ENROLL FEE IS IN CART, REMOVE IT
    if (selectedAmbEnrollSku !== null) {
      const nonSelectedAmbSku =
        selectedAmbEnrollSku === enrollSkus.ambPremiumSku
          ? enrollSkus.ambBasicSku
          : enrollSkus.ambPremiumSku

      const nonSelectedAmbFeeItem = findAndReturnProductInfo(nonSelectedAmbSku)
      if (nonSelectedAmbFeeItem) {
        removeFee(nonSelectedAmbFeeItem.uid, cartId)
      }
    }
    // CHECK TO ENSURE THAT ONLY (1) ARQ FEE EXISTS IN CART
    const arqFeeItem = findAndReturnProductInfo(enrollSkus.arq)
    if (arqFeeItem) {
      const { uid: arqUid, quantity: arqQuantity } = arqFeeItem
      ensureOnlyOneQty(arqUid, arqQuantity)
    }
    // CHECK TO ENSURE THAT ONLY (1) MYQFIT FEE EXISTS IN CART
    const myQFitFeeItem = findAndReturnProductInfo(enrollSkus.myQFit)
    if (myQFitFeeItem) {
      const { uid: myQFitUid, quantity: myQFitQuantity } = myQFitFeeItem
      ensureOnlyOneQty(myQFitUid, myQFitQuantity)
    }
    // CHECK TO ENSURE THAT ONLY (1) Prem Renewal FEE EXISTS IN CART
    if (ambPremiumRenSkuItem) {
      const { uid: renewalPremUid, quantity: myQFitQuantity } =
        ambPremiumRenSkuItem
      ensureOnlyOneQty(renewalPremUid, myQFitQuantity)
    }
    // CHECK TO ENSURE THAT ONLY (1) Basic Renewal FEE EXISTS IN CART
    if (ambBasicRenSkuItem) {
      const { uid: renewalBasicUid, quantity: myQFitQuantity } =
        ambBasicRenSkuItem
      ensureOnlyOneQty(renewalBasicUid, myQFitQuantity)
    }
  }, [isAuthenticated, userType, cartData?.items])

  const ensureOnlyOneQty = async (uid: string, quantity: number) => {
    if (quantity === 1) return
    const itemObj = {
      cart_item_uid: uid,
      quantity: 1,
    }
    const cartObj = {
      cart_id: cartId || guestCartId,
      cart_items: [itemObj],
    }
    await Magento.Cart.updateItemsInCart(cartObj).then(buildCart)
  }

  const upgradeUserAddPcFee = async () => {
    await handleSetCustomerGroup('PREFERRED').then(async () => {
      const getCartId = magentoUser ? cartId : guestCartId
      return await manageCart.addItemToCart(
        { sku: 'PCFEE' },
        1,
        getCartId,
        buildCart,
        prismicGeneral
      )
    })
  }

  const upgradeUserAddPcSmartship = async () => {
    if (userType === 'PREFERRED') {
      await handleSetCustomerGroup('PREFERRED').then(async () => {
        const getCartId = magentoUser ? cartId : guestCartId
        return await manageCart.addItemToCart(
          { sku: 'SMARTSHIP' },
          1,
          getCartId,
          buildCart,
          prismicGeneral
        )
      })
      showCartPopup(true)
    }
    if (userType === 'AMBASSADOR') {
      await handleSetCustomerGroup('AMBASSADOR').then(async () => {
        const getCartId = magentoUser ? cartId : guestCartId
        return await manageCart.addItemToCart(
          { sku: 'SMARTSHIP' },
          1,
          getCartId,
          buildCart,
          prismicGeneral
        )
      })
      showCartPopup(true)
    }
  }

  const downgradeUserRemovePcFee = async (uid: string, packs: string[]) => {
    await handleSetCustomerGroup('RETAIL').then(async () => {
      const getCartId = magentoUser ? cartId : guestCartId
      return await manageCart.removeItemsFromCart(
        [uid, ...packs],
        getCartId,
        buildCart,
        prismicGeneral
      )
    })
  }

  const removeFee = async (uid: string, cartId: string) =>
    await manageCart.removeItemFromCart(uid, cartId, buildCart, prismicGeneral)

  // UPGRADE USER TO AMBASSADOR AND ADD AMBASSADOR ITEMS TO CART
  const upgradeToAmbassadorAddItems = async () => {
    await handleSetCustomerGroup('AMBASSADOR').then(async () => {
      await manageCart.addItemsToCart(
        [selectedAmbEnrollSku, enrollSkus.arq, enrollSkus.myQFit],
        1,
        cartId,
        buildCart,
        prismicGeneral
      )
    })
  }

  // DOWNGRADE TO CURRENT USER TYPE AND REMOVE ITEMS FROM CART
  const downgradeAmbassadorRemoveItems = async (
    ambSkuId,
    arqId,
    myQFitId,
    packs
  ) => {
    await handleSetCustomerGroup(userType).then(async () => {
      await manageCart.removeItemsFromCart(
        [ambSkuId, arqId, myQFitId, ...packs],
        cartId,
        buildCart,
        prismicGeneral
      )
    })
  }

  const guestCart = async (guestCartId: string) => {
    if (guestCartId) {
      setCartState(prev => ({ ...prev, guestCartId: guestCartId }))
      handleGetCartData(guestCartId)
    } else {
      await Magento.Cart.createEmptyCart()
        .then(id => {
          localStorage.setItem('guestCartId', id)
          setCartState(prev => ({ ...prev, guestCartId: id }))
          handleGetCartData(id)
        })
        .catch(e => console.log(e))
    }
  }

  // add guestCartData state to use in mergeCart
  const userCart = async (guestCartId: string) => {
    const shouldMergeCarts = guestCartId && cartData?.items?.length
    await Magento.Cart.getActiveCartOrCreateNew()
      .then(({ customerCart }) => {
        setCartState(prev => ({ ...prev, cartId: customerCart.id }))
        if (shouldMergeCarts) {
          mergeCarts(guestCartId, customerCart.id)
          return
        }
        if (customerCart.items.length) {
          const pcFeeItem = customerCart?.items?.find(
            ({ product }) => product.sku === enrollSkus.pcFee
          )
          if (pcFeeItem) {
            handleSetCustomerGroup(UserType.PREFERRED)
          }
          const ambPremiumSkuItem = customerCart?.items?.find(
            ({ product }) => product.sku === enrollSkus.ambPremiumSku
          )
          if (ambPremiumSkuItem) {
            handleSetCustomerGroup(UserType.AMBASSADOR)
          }
          const ambBasicSkuItem = customerCart?.items?.find(
            ({ product }) => product.sku === enrollSkus.ambBasicSku
          )
          if (ambBasicSkuItem) {
            handleSetCustomerGroup(UserType.AMBASSADOR)
          }
        }
        buildCart(customerCart)
      })
      .catch(e => {
        try {
          const { customerCart } = handleOutOfStock(e, prismicGeneral)
          setCartState(prev => ({ ...prev, cartId: customerCart.id }))
          buildCart(customerCart)
          displayOutOfStockError(customerCart, prismicGeneral)
        } catch (e) {
          Logger.log(e)
        }
      })
  }

  const mergeCarts = async (guestCartId: string, userCartId: string) => {
    await Magento.Cart.mergeCarts({
      source_cart_id: guestCartId,
      destination_cart_id: userCartId,
    })
      .then(cart => {
        if (!cart.items.length) return
        const pcFeeItem = cart?.items?.find(
          ({ product }) => product.sku === enrollSkus.pcFee
        )
        if (pcFeeItem) {
          handleSetCustomerGroup(UserType.PREFERRED)
        }
        const ambPremiumSkuItem = cart?.items?.find(
          ({ product }) => product.sku === enrollSkus.ambPremiumSku
        )
        if (ambPremiumSkuItem) {
          handleSetCustomerGroup(UserType.AMBASSADOR)
        }
        const ambBasicSkuItem = cart?.items?.find(
          ({ product }) => product.sku === enrollSkus.ambBasicSku
        )
        if (ambBasicSkuItem) {
          handleSetCustomerGroup(UserType.AMBASSADOR)
        }
        return cart
      })

      .then(buildCart)
      .then(() => {
        localStorage.removeItem('guestCartId')
        setCartState(prev => ({ ...prev, guestCartId: '', cartId: userCartId }))
      })
      .catch(e => console.log(e))
  }

  const buildCart = cart => {
    let [totalItemCount, totalSavings, totalWholesaleSavings, wholesaleTotal] =
      [0, 0, 0, 0]

    if (cart.items) {
      cart.items = cart.items.filter(item => !!item) // when there are errors, the product item array has nulls we want to remove
      cart.items.map(item => {
        const {
          product: { price_range, wholesale_price },
          quantity,
        } = item

        const regularPrice =
          price_range?.maximum_price?.regular_price?.value ?? ''
        const regularPriceTotal = regularPrice * quantity
        const finalPrice = price_range?.maximum_price?.final_price?.value ?? ''
        const finalPriceTotal = finalPrice * quantity
        const wholesalePrice = Number(wholesale_price)
        const wholesalePriceTotal = wholesalePrice * quantity
        const wholesaleSavings = regularPriceTotal - wholesalePriceTotal
        const savings = regularPriceTotal - finalPriceTotal

        totalItemCount += quantity
        totalSavings += savings
        totalWholesaleSavings += wholesaleSavings
        wholesaleTotal += wholesalePriceTotal
      })
    }

    setCartState(prev => ({
      ...prev,
      cartData: {
        ...cart,
        total_item_count: totalItemCount,
        total_savings: totalSavings,
        total_wholesale_savings: totalWholesaleSavings,
        wholesale_total: wholesaleTotal,
      },
    }))
  }

  const handleGetCartData = (cartId?: string): Promise<void> => {
    if (!isAuthenticated) {
      Magento.Cart.getCartData({ cart_id: guestCartId || cartId })
        .then(buildCart)
        .catch(parentError => {
          try {
            const { customerCart } = handleOutOfStock(
              parentError,
              prismicGeneral
            )
            buildCart(customerCart)
            displayOutOfStockError(customerCart, prismicGeneral)
          } catch (e) {
            if (parentError.message.includes('Could not find a cart')) {
              guestCart().then()
            }
            Logger.log(e)
          }
        })
      return
    }
    Magento.Cart.getActiveCartOrCreateNew()
      .then(({ customerCart }) => {
        setCartState(prev => ({ ...prev, cartId: customerCart.id }))
        buildCart(customerCart)
      })
      .catch(e => {
        try {
          const { customerCart } = handleOutOfStock(e, prismicGeneral)
          buildCart(customerCart)
          displayOutOfStockError(customerCart, prismicGeneral)
        } catch (e) {
          Logger.log(e)
        }
      })
  }

  useEffect(() => {
    if (autoShipId) {
      localStorage.removeItem('autoShipState')
      return
    }
    saveASDataToLocalStorage()
  }, [
    autoShipAddress,
    autoShipData,
    autoShipDate,
    autoShipId,
    autoShipItems,
    autoShipPaymentInformationId,
  ])

  const saveASDataToLocalStorage = () => {
    localStorage.setItem(
      'autoShipState',
      JSON.stringify({
        autoShipAddress,
        autoShipData,
        autoShipDate,
        autoShipId,
        autoShipItems,
        autoShipPaymentInformationId,
      })
    )
  }

  useEffect(() => {
    if (!isAuthenticated || userType === 'RETAIL' || isStoreLoading) return
    autoShipInit()
  }, [isAuthenticated, userType, isStoreLoading])

  const autoShipInit = () =>
    QService.AutoShip.getId()
      .then(({ autoShips }) => {
        return autoShips
      })
      .then(handleSetAutoShipInformation)
      .catch(e => {
        console.log(e.message)
      })

  const fixInternationalSkus = (sku: string) => {
    let newSku = sku
    if (EXCEPTIONSKUS.includes(newSku)) {
      return newSku
    }
    if (newSku.startsWith('AU')) {
      newSku = newSku.split('AU')[1]
    } else if (newSku.endsWith('AU')) {
      newSku = newSku.split('AU')[0]
    } else if (newSku.startsWith('CA')) {
      newSku = newSku.split('CA')[1]
    } else if (newSku.endsWith('CA')) {
      newSku = newSku.split('CA')[0]
    }
    return newSku
  }

  const updateAutoShipState = (data: AutoShipDataResponse) => {
    if (!data.isActive && !data.isVirtual) {
      setAutoShipState({ ...initialAutoShipState })
      return
    }
    if (!data.isActive && data.isVirtual) {
      setVirtualAutoShip({ ...initialVirtualAutoShipState })
      return
    }

    handleSetAutoShipInformation([data])
  }
  const findAutoShipProductsBySku = (autoShipItems: any[]) => {
    let items = []
    autoShipItems.forEach(item => {
      if (!item.isActive) {
        return
      }
      const productData = ASProducts.find(product => {
        const newSku = fixInternationalSkus(product.sku)
        return newSku === item.sku
      })
      const combined = !productData
        ? { ...item, dontShow: true }
        : { ...item, product: productData }
      items.push(combined)
    })
    return items
  }
  const handleSetAutoShipInformation = (autoShips: any[]) => {
    autoShips.forEach(autoShip => {
      if (!autoShip.isVirtual) {
        setAutoShipState({
          autoShipAddress: autoShip.address,
          autoShipData: { ...autoShip },
          autoShipDate: formatDate(autoShip.dateNextProcess),
          autoShipId: autoShip.autoShipId,
          autoShipItems: findAutoShipProductsBySku(autoShip.autoShipItems),
          autoShipPaymentInformationId: autoShip.associatePaymentInformationId,
        })
      } else {
        setVirtualAutoShip({
          virtualAutoShipData: { ...autoShip },
          virtualAutoShipDate: formatDate(autoShip.dateNextProcess),
          virtualAutoShipId: autoShip.autoShipId,
          virtualAutoShipItems: findAutoShipProductsBySku(
            autoShip.autoShipItems
          ),
          virtualAutoShipPaymentInformationId:
            autoShip.associatePaymentInformationId,
        })
      }
    })
  }
  const handleGetAutoShipById = (id: number = 0) => {
    if (!id) return
    // on initial app load the id is correct, an Int, but it somehow becomes a big object later and breaks QService.AutoShip.getData, but autoShipId is correct
    const dynamicId =
      id && typeof id === 'number' ? id : id.autoShipId ?? autoShipId
    QService.AutoShip.getData(dynamicId)
      .then(({ autoShip }) => {
        if (!autoShip.isActive) {
          return
        }
        handleSetAutoShipInformation([autoShip])
      })
      .catch(e => {
        console.log(e.message)
      })
  }

  const [isWrongAutoShipModalOpen, setIsWrongAutoShipModalOpen] =
    useState(false)

  const asCountryCode = autoShipAddress?.countryCode.toUpperCase()
  const currentCountryCode = langAndCountry.substring(langAndCountry.length - 2)
  const currentLang = langAndCountry.split('-')[0]

  // remove autoship address if it is in the wrong country
  const magentoStoreOfCurrentCountry =
    currentLang == 'en'
      ? findStoreByLocale(asCountryCode, prismicMarketAndLanguage.markets)
      : { store_code: currentCountryCode }

  const handleWrongCountryInAutoShip = (
    magentoStoreOfCurrentCountry,
    storeCountryCode
  ) => {
    const magentoStoreOfAddress = findStoreByLocale(
      asCountryCode,
      prismicMarketAndLanguage.markets
    )
    if (
      magentoStoreOfCurrentCountry?.store_code !==
      storeCountryCode.toUpperCase()
    ) {
      setAutoShipState(prev => ({ ...prev, autoShipAddress: null }))
    }
  }

  useEffect(() => {
    if (magentoStoreOfCurrentCountry && storeCountryCode) {
      handleWrongCountryInAutoShip(
        magentoStoreOfCurrentCountry,
        storeCountryCode
      )
    }
  }, [magentoStoreOfCurrentCountry, storeCountryCode])

  const addToAutoShip = async (
    product,
    quantity: number,
    doesExceedASLimit = false
  ) => {
    if (asCountryCode && asCountryCode !== currentCountryCode) {
      return setIsWrongAutoShipModalOpen(true)
    }

    const addedText = prismicCartPage.msg_added_to_subscription.replace(
      '{product}',
      `${product.name} (x${quantity})`
    )
    const removedText = prismicCartPage.msg_removed_from_subscription.replace(
      '{product}',
      `${quantity * -1} ${product.name}`
    )
    if (doesExceedASLimit) {
      return setIsMaxOrderModalOpen(true)
    }
    let isDuplicate = false
    const combinedItems = autoShipItems.map(item => {
      if (item.product?.sku === product.sku) {
        isDuplicate = true
        return { ...item, quantity: item.quantity + quantity }
      }
      return item
    })

    let newItemsArray = isDuplicate
      ? combinedItems
      : ([...autoShipItems, { product, quantity }] as AutoShipItemsProduct[])

    if (autoShipId) {
      await manageAutoShip
        .updateItems(
          {
            autoShipId,
            autoShipItems: buildAutoShipItems(newItemsArray),
            prismicGeneral,
          },
          updateAutoShipState
        )
        .then(() => toast.success(addedText))
        .catch(e => toast.error(e.message))
    } else {
      handleSetAutoShipState({
        autoShipItems: newItemsArray,
      })
      if (quantity < 0) {
        toast.success(removedText, {
          icon: '❌',
        })
        return
      }
      toast.success(addedText)
    }
  }

  const removeFromAutoShip = async (sku: string, name: string) => {
    const removedTextShort =
      prismicCartPage.msg_removed_from_subscription.replace(
        '{product}',
        `${name}`
      )
    let items = autoShipItems.filter(item => item.product?.sku !== sku)

    if (autoShipId) {
      await manageAutoShip
        .updateItems(
          {
            autoShipId,
            autoShipItems: buildAutoShipItems(items),
            prismicGeneral,
          },
          updateAutoShipState
        )
        .then(() =>
          toast.success(removedTextShort, {
            icon: '❌',
          })
        )
        .catch(e => toast.error(e.message))
    } else {
      handleSetAutoShipState({
        autoShipItems: items,
      })
      toast.success(removedTextShort, {
        icon: '❌',
      })
    }
  }

  const resetCartState = () => {
    setCartState({ ...initialCartState })
    setAutoShipState({ ...initialAutoShipState })
    localStorage.removeItem('autoShipState')
    localStorage.removeItem('guestCartId')
  }

  const handleSetAutoShipState = (autoShipState: Partial<InitAutoShipState>) =>
    setAutoShipState(prev => ({ ...prev, ...autoShipState }))

  const handleSetVirtualAutoShipState = (
    autoShipState: Partial<InitVirtualAutoShipState>
  ) => setVirtualAutoShip(prev => ({ ...prev, ...autoShipState }))

  const handleSetCartState = (cartState: Partial<InitCartState>) =>
    setCartState(prev => ({ ...prev, ...cartState }))

  const setIsPlacingOrder = (orderState: boolean) =>
    setCartState(prev => ({ ...prev, isPlacingOrder: orderState }))

  const showCartPopup = (visible: boolean) =>
    setCartState(prev => ({ ...prev, isShowCartPopup: visible }))

  const getTotalOfAutoShipItems = () =>
    autoShipItems
      ?.filter(x => x.product !== undefined)
      .reduce(({ total = 0, totalPv = 0 }, { product, quantity }) => {
        const { wholesale_price, pv } = product
        const price = +wholesale_price
        return {
          total: total + price * quantity,
          totalPv: totalPv + pv * quantity,
        }
      }, 0)

  const [isMaxOrderModalOpen, setIsMaxOrderModalOpen] = useState(false)

  const { total: autoShipTotal } = getTotalOfAutoShipItems()

  const doesExceedMaxOrder = (itemTotal = 0, type) => {
    if (!prismicCartPage?.cart_max) return false
    const cartOrASTotal =
      type === 'cart'
        ? cartData?.prices?.subtotal_excluding_tax.value
        : autoShipTotal ?? 0
    return itemTotal + cartOrASTotal >= prismicCartPage?.cart_max
  }

  // send user to subscription page if they have not yet completed setting it up
  useEffect(() => {
    if (autoShipItems.length > 0 && autoShipId === 0 && isVerified) {
      navigate('/subscriptions')
    }
  }, [autoShipItems, autoShipId, isVerified])

  // check whether user can sign into vip or event sites
  const [eventModalOpen, setEventModalOpen] = useState(false)

  const isEventOrVip = isEventSite || isVipSite

  useEffect(() => {
    if (isEventOrVip) {
      if (!qUser) {
        return
      }
      async function checkEventStatus() {
        const status = await getUserEventStatus(qUser?.legacyAssociateId)

        switch (status) {
          case 'vip':
            break
          case 'event':
            if (!isEventSite) {
              logoutShowModal()
            }
            break

          default:
            logoutShowModal()
            break
        }
      }
      checkEventStatus()
    }
  }, [isEventSite, isVipSite, qUser])

  useEffect(() => {
    if (isInMaintenance) {
      if (!qUser) {
        return
      }
      async function checkEventStatus() {
        const status = await getUserEventStatus(qUser?.legacyAssociateId)

        switch (status) {
          case 'event':
            handleSetAuthState({ maintenanceVerified: true })
            break
          default:
            logoutShowMaintenance()
            break
        }
      }
      checkEventStatus()
    }
  }, [isInMaintenance, qUser])

  const logoutShowModal = () => {
    handleUserLogout()
    resetCartState()
    setEventModalOpen(true)
  }

  const logoutShowMaintenance = () => {
    handleUserLogout()
    resetCartState()
    handleSetAuthState({ maintenanceVerified: false })
  }

  return (
    <Cart.Provider
      value={{
        addSelectedAmbFeeToCart,
        addSelectedAmbRenwalFeeToCart,
        ambEnrollProducts,
        ambRenewalProducts,
        autoShipAddress,
        autoShipData,
        autoShipDate,
        autoShipId,
        autoShipItems,
        autoShipPaymentInformationId,
        virtualAutoShipDate,
        virtualAutoShipData,
        virtualAutoShipId,
        virtualAutoShipItems,
        virtualAutoShipPaymentInformationId,
        cartData,
        cartId: cartId || guestCartId,
        getTotalOfAutoShipItems,
        isAmbOfferInCart,
        isAmbPremiumOfferInCart,
        isRenewalOfferInCart,
        isPcOfferInCart,
        isPcSmartshipInCart,
        isPlacingOrder,
        isShowCartPopup,
        isShippingSet,
        manageAutoShip,
        manageCart,
        addToAutoShip,
        buildCart,
        downgradeAmbassadorRemoveItems,
        downgradeUserRemovePcFee,
        handleGetAutoShipById,
        handleSetAutoShipInformation,
        handleGetCartData,
        handleSetAutoShipState,
        handleSetVirtualAutoShipState,
        handleSetCartState,
        doesExceedMaxOrder,
        removeFromAutoShip,
        resetCartState,
        selectedAmbEnrollSku,
        selectedAmbRenewalSku,
        setAmbEnrollProducts,
        setAmbRenewalProducts,
        setIsMaxOrderModalOpen,
        setIsPlacingOrder,
        setSelectedAmbEnrollSku,
        setSelectedAmbRenewalSku,
        showCartPopup,
        updateAutoShipState,
        upgradeUserAddPcFee,
        upgradeUserAddPcSmartship,
      }}
    >
      <>
        <MaxOrderModal
          open={isMaxOrderModalOpen}
          onClose={() => setIsMaxOrderModalOpen(false)}
        />
        <EventLoginModal
          open={eventModalOpen}
          handleCloseModal={() => setEventModalOpen(false)}
        />
        <WrongAutoshipModal
          open={isWrongAutoShipModalOpen}
          onClose={() => setIsWrongAutoShipModalOpen(false)}
          currentCountryCode={currentCountryCode}
          asCountryCode={asCountryCode}
        />
        {children}
      </>
    </Cart.Provider>
  )
}

export default CartProvider
