import React from 'react'
import { Modal } from 'semantic-ui-react'
import {
  Container,
  StyledButton,
  StyledModalContent,
  StyledText,
} from './PacksModal.styles'
import { usePrismic } from '../../context/PrismicContext'

const PacksModal = ({ open, onClose, yesOnClick, noOnClick }) => {
  const {
    prismicData: {
      prismicPacksModal: { modaltext, yes_text, no_text },
    },
  } = usePrismic()

  return (
    <Modal
      closeIcon
      style={{ width: '80%', maxWidth: 300 }}
      open={open}
      centered={true}
      closeOnDimmerClick={true}
      closeOnEscape
      onClose={onClose}
    >
      <StyledModalContent>
        <StyledText>
          <br></br>
          {modaltext[0].text}
          <br></br>
          <br></br>
          {modaltext[1].text}
        </StyledText>
        <Container>
          <StyledButton onClick={noOnClick}>{no_text[0].text}</StyledButton>
          <StyledButton onClick={yesOnClick}>{yes_text[0].text}</StyledButton>
        </Container>
      </StyledModalContent>
    </Modal>
  )
}

export default PacksModal
