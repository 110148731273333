// Services
import { QService } from '../services/q-services'
import { Magento, MagentoAutoship, MagentoHistory } from '../services/magento'
import { MagentoLoginProps } from '../types/contexts/AuthContextTypes'

const getUplineUser = async () => {
  let { treeNodeLite } = await QService.User.getUserTreeNodeLite()
  const displayName = treeNodeLite?.uplineAssociate.displayName
  const uplineAssociateId = treeNodeLite?.uplineAssociate.associateId

  const associateSlug = treeNodeLite?.associate?.associateSlugs[0]?.slug

  const uplineUserData = {
    uplineAssociateId,
    displayName,
  }

  return { uplineUserData, associateSlug }
}

async function loginWithMagento({
  firebaseToken,
  firstName,
  lastName,
  associateId,
  legacyAssociateId,
}: MagentoLoginProps) {
  if (!firebaseToken) return
  const magentoUserData = {
    jwt_token: firebaseToken,
    first_name: firstName || 'new',
    last_name: lastName || 'user',
    associate_id: associateId,
    legacy_associate_id: legacyAssociateId,
  }
  try {
    const { status, isNewCustomer, customer_token, message } =
      await Magento.User.getMagentoTokenFromFirebaseToken(magentoUserData)

    if (status === 'success') {
      Magento.User.setToken(customer_token)
      MagentoAutoship.User.setToken(customer_token)
      MagentoHistory.User.setToken(customer_token)

      if (isNewCustomer) {
        await QService.User.setCustomerGroup('RETAIL')
      }

      return await getMagentoUser().then(magentoUser => ({ magentoUser }))
    } else {
      console.log(message)
      return false
    }
  } catch (err) {
    console.log(err)
  }
}

const getMagentoUser = async () => await Magento.User.basicInformation()

export { getUplineUser, loginWithMagento, getMagentoUser }
