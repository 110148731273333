import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database'

import environment from '../utils/environment'

const isBrowser = typeof window !== 'undefined'
const app = initializeApp(environment.FIREBASE)
const auth = isBrowser && getAuth(app)
const database = isBrowser && getDatabase(app)

export { auth, database }
