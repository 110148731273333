import styled from 'styled-components'
import { Divider, Checkbox, Popup, Icon } from 'semantic-ui-react'

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 1.5rem;
`

interface ContainerProps {
  direction: 'column' | 'row'
  justify: string
  align: string
  margin: string
  width: string
}

export const Container = styled.div<Partial<ContainerProps>>`
  display: flex;
  flex-direction: ${props => props.direction || 'column'};
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.align || 'center'};
  margin: ${props => props.margin || '0'};
  width: ${props => props.width || '100%'};
  max-width: 500px;
  position: relative;
`

export const QLogo = styled.img`
  &&& {
    width: 80%;
    height: auto;

    @media ${({ theme }) => theme.device.sm} {
      width: 250px;
    }
  }
`

export const PageHeader = styled.header`
  &&& {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 15px;
    color: ${props => props.theme.colors.black};
  }
`

export const PageComment = styled.div`
  font-size: 12px;
  color: ${props => props.theme.colors.dimGray};
`

export const StyledCheckbox = styled(Checkbox)`
  align-self: start;
  padding-top: 2px;
`

export const SectionTitle = styled.div`
  width: 100%;
  margin-top: 15px;
  padding: 5px 0;
  background: ${props => props.theme.colors.metallicSeaweed};
  color: ${props => props.theme.colors.white};
  font-size: 16px;
  text-align: center;
`

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const ErrorLabel = styled.span`
  color: ${props => props.theme.colors.carnelian};
  align-self: flex-start;
  margin-bottom: 0;
`

export const SuccessLabel = styled.span`
  color: ${props => props.theme.colors.green};
  align-self: flex-start;
  margin-bottom: 0;
`

export const StyledDivider = styled(Divider)`
  &&& {
    border-top: 1px solid ${props => props.theme.colors.black} !important;
    border-bottom: none !important;
    width: 10px;
    margin: 0 5px 30px;
  }
`

export const StyledText = styled.div`
  font-size: 16px;
  padding-bottom: 10px;
`

export const InfoIcon = styled.img`
  width: 20px;
  height: 20px;
`

export const ThemedPopup = styled(Popup)`
  &&& {
    background-color: ${props => props.theme.colors.turquoiseGreen};
    ::before {
      background-color: ${props => props.theme.colors.turquoiseGreen};
    }
  }
`

export const SlugLoadingSpinner = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${props => props.theme.colors.turquoiseGreen};
`

export const Gap = styled.div`
  height: 20px;
`
