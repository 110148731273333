import React from 'react'
import { SentryWrapper } from './src/components/Sentry'
import StoreProvider from './src/context/StoreContext'
import AuthProvider from './src/context/AuthContext'
import PrismicProvider from './src/context/PrismicContext'
import CartProvider from './src/context/CartContext'
import { ThemeProvider } from 'styled-components'
import { theme } from './src/styles/GlobalStyle'

const AppWrapper = ({ element }) => {
  return (
    <SentryWrapper>
      <ThemeProvider theme={theme}>
        <PrismicProvider>
          <AuthProvider>
            <StoreProvider>
              <CartProvider>{element}</CartProvider>
            </StoreProvider>
          </AuthProvider>
        </PrismicProvider>
      </ThemeProvider>
    </SentryWrapper>
  )
}

export default AppWrapper
