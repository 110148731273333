import React from 'react'
import { navigate } from 'gatsby'
import { usePrismic } from '../../context/PrismicContext'
// Context
import { useAuthContext } from '../../context/AuthContext'
// Styles
import {
  StyledModal,
  StyledHeader,
  StyledModalContent,
  QLogo,
  PageHeader,
  PageComment,
  SectionTitle,
  StepItem,
  StepNumber,
  StepText,
  SubmitButton,
} from './NextStepModal.styles'

const NextStepModal = ({ open, setShowModal }) => {
  const {
    prismicData: { prismicGeneral },
  } = usePrismic()

  const {
    manageReferral: { referralData },
  } = useAuthContext()

  const gotoNextStep = async () => {
    navigate('/starter-packs')
  }

  return (
    <StyledModal
      closeIcon={false}
      open={open}
      centered={true}
      closeOnDimmerClick={true}
      closeOnEscape
      onClose={() => {
        setShowModal(false)
        navigate('/starter-packs')
      }}
    >
      <StyledHeader>
        <QLogo src="https://shopq.cdn.prismic.io/shopq/06ea17c4-0c65-4563-9507-bc314540e05f_black-q-sciences.svg" />
        <PageHeader>{prismicGeneral.label_ambassador_enrollment}</PageHeader>
        {referralData?.ambassadorName && (
          <PageComment>{`(${prismicGeneral.msg_referred_by?.toLowerCase()} ${
            referralData?.ambassadorName
          })`}</PageComment>
        )}
      </StyledHeader>
      <StyledModalContent>
        <SectionTitle>{prismicGeneral.label_next_steps}</SectionTitle>
        <StepItem>
          <StepNumber>1</StepNumber>
          <StepText>{prismicGeneral.msg_pick_your_products}</StepText>
        </StepItem>
        <StepItem>
          <StepNumber>2</StepNumber>
          <StepText>{prismicGeneral.msg_proceed_to_checkout}</StepText>
        </StepItem>
        <SubmitButton onClick={gotoNextStep}>
          {prismicGeneral.btn_continue}
        </SubmitButton>
      </StyledModalContent>
    </StyledModal>
  )
}

export default NextStepModal
