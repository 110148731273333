import React, { useState, useEffect } from 'react'
import { Magento } from '../../services/magento'
// Context
import { usePrismic } from '../../context/PrismicContext'
// Utils
import { formatCurrency } from '../../utils/i18n/stores'
// Styles
import {
  Container,
  ListContainer,
  ListItem,
  StartSavingButton,
  StyledCheck,
  StyledText,
  Title,
  Wrapper,
} from './PcOffer.styles'

// type PcOfferProps = {
//   addPcOfferToCart: () => void
//   isLoading: boolean
// }

const PcOffer = ({ addPcOfferToCart }) => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    langAndCountry,
    prismicData: {
      prismicCartPage: {
        annual_benefit_list,
        for_preferred_customer_enjoy,
        save_money_becoming_preferred_customer,
        start_saving_today,
      },
    },
  } = usePrismic()

  const [pcFeeItem, setPcFeeItem] = useState(null)
  const getPcItem = async () => {
    const result = await Magento.Store.getSingleProductBySku({ sku: 'PCFEE' })
    setPcFeeItem(result)
  }
  useEffect(() => {
    getPcItem()
  }, [])

  const price =
    pcFeeItem?.products?.items[0]?.price?.regularPrice?.amount?.value

  const formattedPrice = formatCurrency(langAndCountry, price)

  const for_pc_message = for_preferred_customer_enjoy[0].text.replace(
    '{price}',
    formattedPrice
  )

  const handleAddOffer = async () => {
    setIsLoading(true)
    await addPcOfferToCart()
    setIsLoading(false)
  }

  return (
    <Wrapper>
      <Title>{save_money_becoming_preferred_customer[0].text}</Title>

      <StyledText>{for_pc_message}</StyledText>
      <Container>
        <ListContainer>
          {annual_benefit_list.map((benefit: any, index: number) => (
            <ListItem key={index}>
              <StyledCheck />
              <StyledText>{benefit.text}</StyledText>
            </ListItem>
          ))}
        </ListContainer>
      </Container>

      <StartSavingButton onClick={handleAddOffer} loading={isLoading}>
        {start_saving_today[0].text}
      </StartSavingButton>
    </Wrapper>
  )
}

export default PcOffer
