import React, { useState } from 'react'
import { usePrismic } from '../context/PrismicContext'
import { ErrorMessage } from '../components'

function useShowError() {
  const {
    prismicData: { prismicGeneral },
  } = usePrismic()

  const [error, setError] = useState('')

  const clearError = () => setError('')

  const showError = () => (
    <ErrorMessage
      header={prismicGeneral.error_Error}
      message={error}
      cb={clearError}
    />
  )

  return [error, setError, showError]
}

export default useShowError
