import {
  ADD_BUNDLE_PRODUCTS_TO_CART,
  ADD_PRODUCTS_TO_CART,
  APPLY_COUPON_TO_CART,
  CLEAR_CUSTOMER_CART,
  CREATE_CUSTOMER_CART,
  CREATE_EMPTY_CART,
  GET_CART_DATA,
  MERGE_CARTS,
  PLACE_ORDER,
  REMOVE_COUPON,
  REMOVE_PRODUCT_FROM_CART,
  REORDER_ITEMS,
  SET_BILLING_ADDRESS_ON_CART,
  SET_GUEST_EMAIL_ON_CART,
  SET_PAYMENT_METHOD,
  SET_SHIPPING_ADDRESSES_ON_CART,
  SET_SHIPPING_METHOD,
  UPDATE_CART_ITEMS,
} from '../graphql/cart'

type CartData = {
  cart_id: string
  email?: string
}

export const Cart = client => ({
  addBundleProductsToCart: async data => {
    let { addBundleProductsToCart } = await client.request(
      ADD_BUNDLE_PRODUCTS_TO_CART,
      data
    )
    return addBundleProductsToCart.cart
  },
  addProductsToCart: async data => {
    let { addProductsToCart } = await client.request(ADD_PRODUCTS_TO_CART, data)
    return addProductsToCart.cart
  },
  applyCouponToCart: async data => {
    let { applyCouponToCart } = await client.request(APPLY_COUPON_TO_CART, data)
    return applyCouponToCart.cart
  },
  clearCustomerCart: async cartUid =>
    await client.request(CLEAR_CUSTOMER_CART, cartUid),
  createEmptyCart: async () => {
    let { createEmptyCart } = await client.request(CREATE_EMPTY_CART)
    return createEmptyCart
  },
  getActiveCartOrCreateNew: async () =>
    await client.request(CREATE_CUSTOMER_CART),
  getCartData: async (cartId: CartData) => {
    let { cart } = await client.request(GET_CART_DATA, cartId)
    return cart
  },
  // returns cart object
  mergeCarts: async data => {
    let { mergeCarts } = await client.request(MERGE_CARTS, data)
    return mergeCarts
  },
  placeOrder: async data => await client.request(PLACE_ORDER, data),
  removeCouponFromCart: async data => {
    let { removeCouponFromCart } = await client.request(REMOVE_COUPON, data)
    return removeCouponFromCart.cart
  },
  removeItemFromCart: async data => {
    let { removeItemFromCart } = await client.request(
      REMOVE_PRODUCT_FROM_CART,
      data
    )
    return removeItemFromCart.cart
  },
  reorderItems: async orderNumber => {
    let { reorderItems } = await client.request(REORDER_ITEMS, orderNumber)
    return reorderItems.cart
  },
  setBillingAddressOnCart: async data => {
    let { setBillingAddressOnCart } = await client.request(
      SET_BILLING_ADDRESS_ON_CART,
      data
    )
    return setBillingAddressOnCart.cart
  },
  setGuestEmailOnCart: async (data: CartData): Promise<string> => {
    let { cart } = await client.request(SET_GUEST_EMAIL_ON_CART, data)
    return cart?.email
  },
  setPaymentMethodOnCart: async data => {
    let { setPaymentMethodOnCart } = await client.request(
      SET_PAYMENT_METHOD,
      data
    )
    return setPaymentMethodOnCart.cart
  },
  setShippingAddressOnCart: async data => {
    let { setShippingAddressesOnCart } = await client.request(
      SET_SHIPPING_ADDRESSES_ON_CART,
      data
    )
    return setShippingAddressesOnCart.cart
  },
  setShippingMethodOnCart: async data => {
    let { setShippingMethodsOnCart } = await client.request(
      SET_SHIPPING_METHOD,
      data
    )
    return setShippingMethodsOnCart.cart
  },
  updateItemsInCart: async data => {
    let { updateCartItems } = await client.request(UPDATE_CART_ITEMS, data)
    return updateCartItems.cart
  },
})
