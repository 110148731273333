import { Modal, Header, Image } from 'semantic-ui-react'
import styled, { css } from 'styled-components'
import { Primary as PrimaryButton } from '..'

export const StyledModal = styled(Modal)`
  width: 90% !important;

  @media ${({ theme }) => theme.device.sm} {
    width: 600px !important;
  }

  &&& {
    &.ui.modal > .content {
      padding: 0 0 20px !important;
    }
    i.close {
      top: 0 !important;
      left: 0;
      color: ${({ theme }) => theme.colors.black};
    }
  }
`

export const StyledHeader = styled(Header)`
  text-align: center;
  font-size: 18px !important;
  font-weight: bold !important;
  border-bottom: 0 !important;

  @media ${({ theme }) => theme.device.sm} {
    font-size: 22px !important;
  }
`

export const StyledModalContent = styled(Modal.Content)``

export const QLogo = styled(Image)`
  &&& {
    width: 80% !important;
    height: auto;
    @media ${({ theme }) => theme.device.sm} {
      width: 250px !important;
    }
  }
`

export const PageHeader = styled.div`
  &&& {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 15px;
    color: ${props => props.theme.colors.black};
  }
`

export const PageComment = styled.div`
  font-size: 12px;
  color: ${props => props.theme.colors.dimGray};
`

export const SectionTitle = styled.div`
  width: 100%;
  margin-top: 15px;
  padding: 5px 0;
  background: ${props => props.theme.colors.metallicSeaweed};
  color: ${props => props.theme.colors.white};
  font-size: 16px;
  text-align: center;
`

export const StepItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 10px 0;

  @media ${({ theme }) => theme.device.sm} {
    padding: 15px 20px 0;
  }
`

export const StepNumber = styled.div`
  width: 36px;
  height: 36px;
  background: ${props => props.theme.colors.turquoiseGreen};
  color: ${props => props.theme.colors.white};
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
  text-align: center;
  line-height: 36px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
`

export const StepText = styled.div`
  margin-left: 15px;
  flex: 1;
`

export const SubmitButton = styled(PrimaryButton)`
  margin: 30px auto 0 !important;
  @media ${({ theme }) => theme.device.sm} {
    margin: 50px auto 0 !important;
  }
`
