import environment from './environment'

type AnalyticsData = {
  [key: string]: string
  send_to?: string
}

type EventType = 'click' | 'purchase'
const gtag = environment.isBrowser ? (window as any)?.gtag : null

export const setAnalyticsStream = () => {
  if (environment.isBrowser && gtag) {
    gtag('config', environment.GOOGLE_ANALYTICS_ID)
  }
}

/**
 * More info about usage here: https://developers.google.com/tag-platform/gtagjs
 */
export default (eventType: EventType = 'click', data: AnalyticsData = {}) => {
  if (environment.isBrowser && gtag) {
    gtag('event', eventType, { ...data })
  }
}
