import React, { useState, useRef } from 'react'
import { Button, Popup } from 'semantic-ui-react'
// Components
import OrderCost from './OrderCost'
import OrderItem from './OrderItem'
// Styles
import {
  HeaderLeftContent,
  HeaderRightContent,
  OrderHeader,
  OrderItemViewContainer,
  StatusIcon,
  StyledSpan,
} from './Orders.styles'
// Utils & Services
import { enrollSkus } from '../../utils/constants'
// Images
import isCanceled from '../../images/order/statusIcons/isCanceled.svg'
import isClosed from '../../images/order/statusIcons/isClosed.svg'
import isComplete from '../../images/order/statusIcons/isComplete.svg'
import isOnHold from '../../images/order/statusIcons/isOnHold.svg'
import isOpenOrder from '../../images/order/statusIcons/isOpenOrder.svg'
import isPending from '../../images/order/statusIcons/isPending.svg'
import isPendingPayment from '../../images/order/statusIcons/isPendingPayment.svg'
import isPendingReview from '../../images/order/statusIcons/isPendingReview.svg'
import isProcessing from '../../images/order/statusIcons/isProcessing.svg'
import isReadyForPickup from '../../images/order/statusIcons/isReadyForPickup.svg'

const OrderCard = ({ number, status, order_date, items, total, shipments }) => {
  const [pvTotal, setPvTotal] = useState(0)
  const [open, setOpen] = useState(false)
  const popupRef = useRef()

  const orderDate = new Date(order_date)
    .toUTCString()
    .split(' ')
    .slice(0, 4)
    .join(' ')

  const getStatusIcon = status => {
    switch (status) {
      case 'On Hold':
        return <StatusIcon src={isOnHold} />
      case 'Canceled':
        return <StatusIcon src={isCanceled} />
      case 'Closed':
        return <StatusIcon src={isClosed} />
      case 'Complete':
        return <StatusIcon src={isComplete} />
      case 'Open':
        return <StatusIcon src={isOpenOrder} />
      case 'Pending':
        return <StatusIcon src={isPending} />
      case 'Pending Payment':
        return <StatusIcon src={isPendingPayment} />
      case 'Pending Review':
        return <StatusIcon src={isPendingReview} />
      case 'Processing':
        return <StatusIcon src={isProcessing} />
      case 'Ready for Pickup':
        return <StatusIcon src={isReadyForPickup} />

      default:
        break
    }
  }

  const isOfferInOrder = !!items.find(({ product_sku }) =>
    Object.values(enrollSkus).includes(product_sku)
  )

  return (
    <OrderItemViewContainer>
      <OrderHeader>
        <HeaderLeftContent>
          <StyledSpan data-qa={`order-${number}`} padding="0 5px 0 0" big>
            {number}
          </StyledSpan>
          {/* <StyledSpan gray small>
            { view_invoice[0].text }
          </StyledSpan> */}
        </HeaderLeftContent>
        <div data-qa={`order-${number}-Status-${status}`}>
          <Popup
            content={status}
            on="click"
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            hideOnScroll
            onMount={() => setTimeout(() => setOpen(false), 2000)}
            open={open}
            position="top center"
            trigger={
              <Button
                className="copy-button"
                icon={getStatusIcon(status)}
                ref={popupRef}
              />
            }
          />
        </div>
        <HeaderRightContent>
          <StyledSpan data-qa={`orderDate-${order_date}`} big>
            {orderDate}
          </StyledSpan>
        </HeaderRightContent>
      </OrderHeader>
      {items?.map((product, i) => (
        <OrderItem
          key={`${product.product_sku}-${i}`}
          returnPv={setPvTotal}
          orderNumber={number}
          {...product}
        />
      ))}
      <OrderCost
        data-qa={`orderPVTotal-${pvTotal}`}
        pvTotal={pvTotal}
        {...total}
        orderNumber={number}
        isOfferInOrder={isOfferInOrder}
        shipments={shipments}
      />
    </OrderItemViewContainer>
  )
}

export default OrderCard
