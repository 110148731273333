import React from 'react'
import { navigate } from 'gatsby'
// Styles
import { AS } from './styled'

const SignInModal = ({ open, setShowModal }) => {
  const handleClick = () => {
    navigate('/login')
  }

  return (
    <AS.StyledModal
      centered={true}
      closeIcon
      closeOnDimmerClick={true}
      closeOnEscape
      onClose={() => setShowModal(false)}
      open={open}
    >
      <AS.StyledHeader />
      <AS.StyledModalContent>
        <AS.Container>
          <AS.StyledSpan>
            Before we can set up your Auto Ship, you'll need to create your Shop
            Q account. Press CREATE ACCOUNT below to get started.
          </AS.StyledSpan>

          <AS.ButtonsWrapper>
            <AS.StyledButton
              content="Create Account"
              onClick={handleClick}
              data-qa="create-account"
            />
          </AS.ButtonsWrapper>
        </AS.Container>
      </AS.StyledModalContent>
    </AS.StyledModal>
  )
}

export default SignInModal
