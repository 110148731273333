import React, { useState, useEffect, useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
// Components
import { Loading } from '../components'
// Types
import {
  PrismicContextType,
  PrismicData,
} from '../types/contexts/PrismicContextTypes'
// Utils
import { getStoreView } from '../utils/i18n/stores'
// Services
import { QService } from '../services/q-services'

const Prismic = React.createContext<Partial<PrismicContextType>>({})

export const usePrismic = () => useContext(Prismic)

const PrismicProvider = ({ children }) => {
  // storeLocale is used to hook up to the correct Magento and Prismic
  const [storeLocale, setUserLocale] = useState(null)
  // langAndCountry is to get the correct country for addresses and formatting currency
  const [langAndCountry, setLangAndCountry] = useState(null)
  const [magentoStoreName, setMagentoStoreName] = useState('')
  const [prismicLoading, setPrismicLoading] = useState(true)
  const [prismicData, setPrismicData] = useState({})
  const translations = {
    'en-us': require('../services/locales/en-us.json'),
    'de-de': require('../services/locales/de-de.json'),
    'en-au': require('../services/locales/en-au.json'),
    'en-ca': require('../services/locales/en-ca.json'),
    'en-eu': require('../services/locales/en-eu.json'),
    'en-gb': require('../services/locales/en-gb.json'),
    'en-mx': require('../services/locales/en-mx.json'),
    'en-nf': require('../services/locales/en-nf.json'),
    'en-no': require('../services/locales/en-no.json'),
    'en-nz': require('../services/locales/en-nz.json'),
    'es-es': require('../services/locales/es-es.json'),
    'es-mx': require('../services/locales/es-mx.json'),
    'es-us': require('../services/locales/es-us.json'),
    'fr-ca': require('../services/locales/fr-ca.json'),
    'fr-fr': require('../services/locales/fr-fr.json'),
    'it-it': require('../services/locales/it-it.json'),
    'ja-jp': require('../services/locales/ja-jp.json'),
    'no-eu': require('../services/locales/no-eu.json'),
    'no-no': require('../services/locales/no-no.json'),
    'sv-se': require('../services/locales/sv-se.json'),
  }

  useEffect(() => {
    const storeCode = getStoreView(localStorage, navigator.language).store_code
    const storeName = getStoreView(localStorage, navigator.language).store_name
    const langAndCountryFromStorage =
      localStorage.getItem('LANG_AND_COUNTRY') ?? storeCode.replace('_', '-')
    if (
      storeCode === 'Event' ||
      storeCode === 'vipStore' ||
      storeCode === 'ot_view' ||
      storeCode === 'testOpsView'
    ) {
      setUserLocale('en-us')
      setMagentoStoreName('US English')
      setLangAndCountry('en-US')
    } else {
      setUserLocale(storeCode.replace('_', '-').toLowerCase())
      setMagentoStoreName(storeName)
      setLangAndCountry(langAndCountryFromStorage)
    }
  }, [])

  useEffect(() => {
    if (!storeLocale) return
    setPrismicData(translations[storeLocale])
  }, [storeLocale])

  useEffect(() => {
    if (prismicData && Object.keys(prismicData).length > 0) {
      setPrismicLoading(false)
    }
  }, [prismicData])

  // countries
  const storeCountryCode = storeLocale?.slice(-2) || 'us'

  const [activeCountries, setActiveCountries] = useState([])
  const language = 'en'
  const variables = { language }
  const getCountryData = async () => {
    const response = await QService.Countries.getActiveCountries(variables)
    setActiveCountries(response)
  }

  useEffect(() => {
    getCountryData()
  }, [])

  const saveLangAndCountry = locale => {
    setLangAndCountry(locale)
    localStorage.setItem('LANG_AND_COUNTRY', locale)
  }
  const value = {
    activeCountries,
    storeLocale,
    storeCountryCode,
    langAndCountry,
    saveLangAndCountry,
    magentoStoreName,
    prismicData,
  }
  if (prismicLoading)
    return <Loading loading={prismicLoading} message={'Loading'} />
  return (
    <Prismic.Provider value={value}>
      {!prismicLoading && children}
    </Prismic.Provider>
  )
}
export default PrismicProvider
