import styled from 'styled-components'
import { Image } from 'semantic-ui-react'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100vw;
  background-color: ${props =>
    props.silver ? props.theme.colors.silver : props.theme.colors.white};
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 14px;
  left: 14px;
`

export const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xLarge};
  font-weight: bold;
  margin: 16px 2em 20px 2em;
  text-align: center;
  line-height: 30px;
`

export const RightIconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 14px;
  right: 14px;
`

export const DeleteIcon = styled(Image)`
  &&& {
    height: 'auto';
    object-fit: contain;
    width: 30px;
    cursor: pointer;
  }
`
