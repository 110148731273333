import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  /* justify-content: center; */
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
  min-height: calc(100vh - 240px);
`

interface ContainerProps {
  direction: string
  justify: string
  align: string
  margin: string
  padding: string
  width: string
  maxWidth: string
  height: string
}

export const Container = styled.div<Partial<ContainerProps>>`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.align || 'center'};
  width: ${props => props.width || '100%'};
  max-width: ${props => props.maxWidth || 'none'};
  padding: ${props => props.padding || '0.5rem'};
  margin: ${props => props.margin};
  height: ${props => props.height || 'auto'};
`

interface DividerProps {
  width: string
  margin: string
}

export const Divider = styled.div<Partial<DividerProps>>`
  width: ${props => props.width || '90%'};
  margin: ${props => props.margin || '12px 0'};
  border-top: 2px solid rgba(0, 0, 0, 0.2);
`
