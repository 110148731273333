import styled from 'styled-components'

export const StyledInput = styled.input`
  padding: 10px;
  width: ${props => props.width || '100%'};
  border: 1px solid #222;
  border-radius: 10px;
  margin-bottom: 1rem;
  font-size: 16px;
  opacity: ${props => (props.disabled ? 0.3 : 1)};

  ::placeholder {
    color: ${props => props.theme.colors.silver};
  }
  :focus {
    border: 1px solid ${props => props.theme.colors.metallicSeaweed};
  }
`
