import styled, { css } from 'styled-components'
import { Button, Checkbox } from 'semantic-ui-react'

export const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid black;
  padding-top: 0.5em;
  position: relative;
  min-height: 150px;
`

export const Container = styled.div`
  display: flex;
  flex-flow: ${props => (props.column ? 'column nowrap' : 'row nowrap')};
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.align || 'center'};
  padding: ${props => props.padding || '0'};
  margin: 0;
  width: ${props => props.width};

  ${props =>
    props.isCart &&
    css`
      flex-flow: column nowrap;
      padding-top: 2em;
      align-items: flex-end;
    `}
`

export const StyledSpan = styled.span`
  margin: 0;
  padding: 0;

  ${props =>
    props.bold &&
    css`
      font-weight: bold;
      font-size: 1.2em !important;
    `}

  ${props =>
    props.lineThru &&
    css`
      margin-right: 0.5em;
      font-size: 1.2em !important;
      text-decoration: line-through;
      color: ${({ theme }) => theme.colors.silver};
    `}
      
  ${props =>
    props.savings &&
    css`
      font-size: 0.9em !important;
      color: ${({ theme }) => theme.colors.green};
    `}
      
  ${props =>
    props.pv &&
    css`
      margin-left: 0.5em;
    `}
`

export const Title = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  top: 10px;
  left: 0;
  width: 80vw;
  z-index: 99;

  span {
    font-weight: bolder;
    font-size: 1.2em;
  }
`

export const CardInfoContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0;
  flex: 1 0 auto;
  height: inherit;
`

export const ButtonsWrapper = styled.div`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  flex-flow: row nowrap;
  justify-content: flex-end;
  margin-right: 0;
  width: 100%;
  max-width: 300px;
`

export const MinusButton = styled(Button)`
  &&& {
    background: white;
    border-radius: 0px;
    display: flex;
    flex: 1 0 40%;
    justify-content: flex-end;
    margin-left: 1em;
    margin: 0;
    padding: 0.7em;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    z-index: 99;
  }
`

export const PlusButton = styled(Button)`
  &&& {
    background: white;
    border-radius: 0px;
    display: flex;
    flex: 1 0 40%;
    justify-content: flex-start;
    margin: 0;
    padding: 0.7em;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    z-index: 99;
  }
`

export const Count = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
  font-weight: bolder;
`

export const RemoveButton = styled(Button)`
  &&& {
    background: inherit;
    border: none;
    align-self: flex-end;
    padding: 5px 0 15px 0;
    font-weight: bolder;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.black};
    z-index: 10;

    :hover {
      background: inherit;
      color: ${({ theme }) => theme.colors.carnelian};
    }
  }
`

export const OutOfStockOverlay = styled.div`
  position: absolute;
  background: #d8d8d8;
  opacity: 50%;
  top: 0;
  bottom: 40px;
  left: 0;
  z-index: 100;
  right: 0;
`

export const RotatedText = styled.div`
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #ddd;
  -moz-transform: translateX(-50%) translateY(-50%) rotate(23deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(23deg);
  transform: translateX(-50%) translateY(-50%) rotate(23deg);
`

export const StyledCheckbox = styled(Checkbox)`
  align-self: start;
  padding-top: 2px;
`
