import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: auto;
  padding: 2em 0;
  width: 100%;

  a:link,
  a:visited {
    color: ${({ theme }) => theme.colors.white};
  }
`
