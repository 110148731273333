import React from 'react'
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import { Link } from 'gatsby'
import { usePrismic } from '../context/PrismicContext'
import useWindowSize from '../hooks/useWindowSize'
import { Primary } from './Buttons'
import { Container } from './common'
import { setCookie } from '../utils/cookieHelpers'

const Wrapper = styled(Container)`
  position: fixed;
  bottom: 0;
  z-index: 200;
  background: white;
  border: 1px solid lightgrey;
  border-radius: 5px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  padding: 1rem;
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.metallicSeaweed};
  margin: 0 8px;
  font-weight: bold;
  cursor: pointer;
`

const StyledAnchor = styled.a`
  color: ${({ theme }) => theme.colors.metallicSeaweed};
  margin: 0 8px;
  font-weight: bold;
  cursor: pointer;
`

const CookieModal = ({ onClose }) => {
  const {
    prismicData: { prismicGeneral },
  } = usePrismic()

  const { width } = useWindowSize()
  const isLargeScreen = width > 750

  const cookiePath = isLargeScreen
    ? '/cookie-policy'
    : prismicGeneral.cookie_policy_url

  const ResponsiveLink = ({ link, children }) => (
    <>
      {isLargeScreen ? (
        <StyledLink to={link}>{children}</StyledLink>
      ) : (
        <StyledAnchor href={link} target="_blank">
          {children}
        </StyledAnchor>
      )}
    </>
  )

  return (
    <Wrapper direction="column">
      <Icon
        name="close"
        onClick={onClose}
        style={{ cursor: 'pointer', position: 'absolute', top: 8, right: 8 }}
      />
      <Container maxWidth="400px" direction="column">
        <Container padding="0" justify="space-between">
          <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
            {prismicGeneral.cookie_policy_title.toUpperCase()}
          </span>
        </Container>
        <span style={{ fontSize: '12px' }}>
          {prismicGeneral.cookie_policy_content}
        </span>
        <Container direction="column">
          <Primary
            onClick={() => {
              setCookie('user-cookie-consent')
              onClose()
            }}
          >
            {prismicGeneral.accept_and_continue}
          </Primary>
          <Container style={{ flexWrap: 'nowrap' }}>
            <ResponsiveLink link={cookiePath}>
              <span style={{ fontSize: '16px' }}>
                {prismicGeneral.cookie_policy.toUpperCase()}
              </span>
            </ResponsiveLink>
          </Container>
        </Container>
      </Container>
    </Wrapper>
  )
}
export default CookieModal
