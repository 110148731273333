import React from 'react'
import PropTypes from 'prop-types'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import { usePrismic } from '../../context/PrismicContext'
import environment from '../../utils/environment'

// src = https://www.npmjs.com/package/@paypal/react-paypal-js

const style = {
  layout: 'horizontal',
  height: 36,
  label: 'buynow',
  color: 'silver',
}

const PayPalButton = ({
  onClick,
  createOrder,
  onApprove,
  onError,
  disabled,
  currency,
  amount,
}) => {
  const { storeCountryCode } = usePrismic()

  const isAuOrNz = storeCountryCode === 'au' || storeCountryCode === 'nz'
  const initialOptions = {
    'client-id': !isAuOrNz
      ? process.env.GATSBY_PAYPAL_CLIENT_ID
      : process.env.GATSBY_PAYPAL_AUS_CLIENT_ID,
    currency: currency || 'USD',
    intent: 'authorize',
  }
  return (
    <div
      style={{
        width: '90%',
        maxWidth: 300,
        marginBottom: -6,
      }}
    >
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={style}
          forceReRender={[currency, amount]}
          fundingSource="paypal"
          onClick={onClick}
          createOrder={createOrder}
          onApprove={onApprove}
          onError={onError}
          disabled={disabled}
        />
      </PayPalScriptProvider>
    </div>
  )
}

PayPalButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  createOrder: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  currency: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
}

export default PayPalButton
