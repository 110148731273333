import {
  CONFIRM_ACCESS_CODE,
  DIRECT_SCALE_INFO,
  LOGIN_ASSOCIATE,
  LOGIN_VALIDATION_PROCESS,
} from '../graphql/authentication'

export const Auth = client => ({
  confirmAccessCode: async (loginName, accessCode) =>
    await client.request(CONFIRM_ACCESS_CODE, { loginName, accessCode }),
  getDirectScaleUser: async userName =>
    await client.request(DIRECT_SCALE_INFO, {
      ambassadorOnly: false,
      userName,
    }),
  loginAssociate: async () => {
    return await client
      .request(LOGIN_ASSOCIATE)
      .then(({ loginAssociate }) => loginAssociate)
      .catch(e => {
        console.log(e)
      })
  },
  loginValidationProcess: async (method, loginName, verificationInfo) =>
    await client.request(LOGIN_VALIDATION_PROCESS, {
      method,
      loginName,
      verificationInfo,
    }),
})
