import styled from 'styled-components'
export const CircleGradient = styled.div`
  color: #313149;
  position: relative;
  width: 40px;
  line-height: 40px;
  height: 40px;
  z-index: 0;
  text-align: center;
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    padding: 5px;
    border-radius: 50%;
    background: linear-gradient(to right, #007985, #02e5f1);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`
export const StyledCounter = styled.span`
  font-family: Arial;
  font-size: 16px;
  font-weight: 900;
  text-align: center;
  color: ${props => props.theme.colors.metallicSeaweed};
`
