// Types
import {
  InitAuthState,
  InitUserState,
} from '../types/contexts/AuthContextTypes'
import {
  InitCartState,
  InitAutoShipState,
  InitVirtualAutoShipState,
} from '../types/contexts/CartContextTypes'
import { InitStoreState } from '../types/contexts/StoreContextTypes'
// Utils
import environment from '../utils/environment'

const initialAuthState: InitAuthState = {
  isAuthenticated: false,
  isAuthLoading: true,
  isEventSite: environment.IS_EVENT_SITE,
  authToggle: false,
  isVerified: false,
  isVipSite: environment.IS_VIP_SITE,
  foundEmailButNotVerified: false,
  isInMaintenance: environment.IS_IN_MAINTENANCE ?? false,
  maintenanceVerified: false,
}

const initialUserState: InitUserState = {
  cardsOnFile: [],
  isEnrollmentComplete: false,
  isReferral: false,
  magentoUser: null,
  qUser: null,
  qUserUpline: null,
  referralData: null,
  shouldAddPcFee: false,
  userType: 'RETAIL',
  expiredSubscription: null,
  hasGenius: false,
}

const initialStoreState: InitStoreState = {
  isLandingPageLoading: true,
  isStoreLoading: true,
  landingPageData: {},
  storeData: {},
  autoshipStoreData: {},
}

const initialCartState: InitCartState = {
  cartData: null,
  cartId: '',
  guestCartId: '',
  isAmbOfferInCart: false,
  isPcOfferInCart: false,
  isPcSmartshipInCart: false,
  isRenewalOfferInCart: false,
  isPlacingOrder: false,
  isShippingSet: false,
  isShowCartPopup: false,
}

const initialAutoShipState: InitAutoShipState = {
  autoShipAddress: null,
  autoShipData: null,
  autoShipDate: '',
  autoShipId: 0,
  autoShipItems: [],
  autoShipPaymentInformationId: null,
}
const initialVirtualAutoShipState: InitVirtualAutoShipState = {
  virtualAutoShipData: null,
  virtualAutoShipDate: '',
  virtualAutoShipId: 0,
  virtualAutoShipItems: [],
  virtualAutoShipPaymentInformationId: null,
}

export {
  initialAuthState,
  initialAutoShipState,
  initialCartState,
  initialStoreState,
  initialUserState,
  initialVirtualAutoShipState,
}
