import React from 'react'
// Context
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { AS } from './styled'

const DontDeleteLastItemModal = ({ open, onClose }) => {
  const {
    prismicData: { prismicForgotPasswordPage, prismicAutoshipPage },
  } = usePrismic()

  return (
    <AS.StyledModal
      centered
      closeIcon
      closeOnDimmerClick
      closeOnEscape
      onClose={onClose}
      open={open}
    >
      <AS.StyledHeader />
      <AS.StyledModalContent>
        <AS.Container>
          <AS.StyledSpan>
            {prismicAutoshipPage.subscription_must_contain_one_item[0].text}
          </AS.StyledSpan>

          <AS.StyledButton
            content={prismicForgotPasswordPage.close[0].text}
            data-qa="don't-delete-last-item-close-button"
            onClick={onClose}
          />
        </AS.Container>
      </AS.StyledModalContent>
    </AS.StyledModal>
  )
}

export default DontDeleteLastItemModal
