import toast from 'react-hot-toast'
// Services
import { QService } from '../services/q-services'
// Types
import {
  AddToAutoShip,
  AutoShipDataResponse,
  ManageAutoShipTypes,
  UpdateAutoShip,
  UpdateAutoShipItems,
  UpdateProcessDate,
} from './../types/AutoShipTypes'
import { UserShippingAddress } from '../types/CartTypes'

const create = async (
  data: AddToAutoShip,
  updateState: (res: AutoShipDataResponse) => void,
  prismicGeneral
) => {
  if (!data.dateStart) return
  await QService.AutoShip.addTo(data)
    .then(({ addAutoShipV2 }) => addAutoShipV2)
    .then(async ({ autoShipId, dateNextProcess }) =>
      updateProcessDate(
        { autoShipId, dateNextProcess },
        updateState,
        prismicGeneral
      )
    )
    .then(() =>
      toast.success(prismicGeneral.msg_subscription_created, { duration: 5000 })
    )
    .catch(e => {
      console.log(e)
      toast.error(prismicGeneral.error_something_went_wrong, { duration: 5000 })
    })
}

const updateProcessDate = async (
  data: UpdateProcessDate,
  updateState: (res: AutoShipDataResponse) => void,
  prismicGeneral
) => {
  await QService.AutoShip.updateDateNextProcess(data)
    .then(({ updateAutoShipDateNextProcess }) => updateAutoShipDateNextProcess)
    .then(updateState)
    .catch(e => {
      console.log(e)
      toast.error(prismicGeneral.error_something_went_wrong)
    })
}

const updateItems = async (
  data: UpdateAutoShipItems,
  updateState: (res: AutoShipDataResponse) => void,
  prismicGeneral
) => {
  await QService.AutoShip.updateItems(data)
    .then(({ updateAutoShipItems }) => updateAutoShipItems)
    .then(updateState)
    .catch(e => {
      console.log(e)
      toast.error(prismicGeneral.error_something_went_wrong)
    })
}

const update = async (
  data: UpdateAutoShip,
  updateState: (res: AutoShipDataResponse) => void,
  prismicGeneral
) => {
  await QService.AutoShip.update(data)
    .then(({ updateAutoShip }) => updateAutoShip)
    .then(updateState)
    .catch(e => {
      console.log(e)
      toast.error(prismicGeneral.error_something_went_wrong)
    })
}

const skipMonth = async (
  autoShipId: number,
  updateState: (res: AutoShipDataResponse) => void,
  prismicGeneral
) => {
  await QService.AutoShip.skipMonth(autoShipId)
    .then(({ skipAutoShipMonth }) => skipAutoShipMonth)
    .then(updateState)
    .catch(e => {
      console.log(e)
      toast.error(prismicGeneral.error_something_went_wrong)
    })
}

const manageAutoShip: ManageAutoShipTypes = {
  create,
  updateProcessDate,
  updateItems,
  update,
  skipMonth,
}

export default manageAutoShip

export const buildAutoShipAddress = (
  address: Partial<UserShippingAddress>
) => ({
  address1: address.street[0],
  address2: address?.street[1] || '',
  city: address.city,
  state: address.region.region_code,
  zip: address.postcode,
  countryCode: address.country_code,
})

export const buildAutoShipItems = itemsArr => {
  return itemsArr.map(({ product, quantity, productName, sku, dS_ItemId }) => ({
    quantity,
    productName: product !== undefined ? product.name : productName,
    sku: product !== undefined ? product.sku : sku,
    dS_ItemId: product !== undefined ? Number(product.ds_itemid) : dS_ItemId,
    magentoEntityId: 6,
    isActive: true,
  }))
}
