import { GraphQLClient } from 'graphql-request'
import environment from '../../utils/environment'
import { User } from './api/user'
import { Payments } from './api/payments'
import { AutoShip } from './api/autoship'
import { Auth } from './api/auth'
import { Countries } from './api/countries'

const endpoint = environment.QSERVICES_API_URL

const client = new GraphQLClient(endpoint)

export const QService = {
  setToken: (token: string) =>
    client.setHeader('authorization', `Bearer ${token}`),
  unsetToken: () => client.setHeaders({}),
  Auth: Auth(client),
  AutoShip: AutoShip(client),
  Payments: Payments(client),
  User: User(client),
  Countries: Countries(client),
}
