import { gql } from 'graphql-request'

export const CREATE_EMPTY_CART = gql`
  mutation {
    createEmptyCart
  }
`

export const CREATE_CUSTOMER_CART = gql`
  query {
    customerCart {
      id
      email
      billing_address {
        city
        country {
          code
          label
        }
        firstname
        lastname
        postcode
        region {
          code
          label
        }
        street
        telephone
      }
      shipping_addresses {
        firstname
        lastname
        street
        city
        postcode
        region {
          code
          label
        }
        country {
          code
          label
        }
        telephone
        available_shipping_methods {
          amount {
            currency
            value
          }
          available
          carrier_code
          carrier_title
          method_code
          method_title
          price_excl_tax {
            value
            currency
          }
          price_incl_tax {
            value
            currency
          }
        }
        selected_shipping_method {
          amount {
            value
            currency
          }
          carrier_code
          carrier_title
          method_code
          method_title
        }
      }
      available_payment_methods {
        code
        title
      }
      selected_payment_method {
        code
        title
      }
      applied_coupons {
        code
      }
      prices {
        grand_total {
          value
          currency
        }
        discounts {
          label
          amount {
            value
          }
        }
        subtotal_excluding_tax {
          value
        }
        subtotal_including_tax {
          value
        }
        subtotal_with_discount_excluding_tax {
          value
        }
        applied_taxes {
          label
          amount {
            value
          }
        }
      }
      items {
        uid
        prices {
          total_item_discount {
            value
          }
          price {
            value
          }
          discounts {
            label
            amount {
              value
            }
          }
        }
        product {
          autoship
          name
          sku
          stock_status
          price_range {
            maximum_price {
              final_price {
                value
              }
              regular_price {
                value
              }
            }
            minimum_price {
              final_price {
                value
              }
              regular_price {
                value
              }
            }
          }
          special_price
          pv
          wholesale_price
          url_key
          iCBD
          image {
            url
          }
          categories {
            name
          }
        }
        quantity
        ... on BundleCartItem {
          bundle_options {
            uid
            label
            type
            values {
              id
              label
              price
              quantity
            }
          }
        }
        ... on ConfigurableCartItem {
          configurable_options {
            configurable_product_option_uid
            option_label
            configurable_product_option_value_uid
            value_label
          }
        }
      }
      total_quantity
    }
  }
`

export const GET_CART_DATA = gql`
  query getCartData($cart_id: String!) {
    cart(cart_id: $cart_id) {
      id
      email
      is_virtual
      billing_address {
        city
        country {
          code
          label
        }
        firstname
        lastname
        postcode
        region {
          code
          label
        }
        street
        telephone
      }
      shipping_addresses {
        firstname
        lastname
        street
        city
        postcode
        region {
          code
          label
        }
        country {
          code
          label
        }
        telephone
        available_shipping_methods {
          amount {
            currency
            value
          }
          available
          carrier_code
          carrier_title
          method_code
          method_title
          price_excl_tax {
            value
            currency
          }
          price_incl_tax {
            value
            currency
          }
        }
        selected_shipping_method {
          amount {
            value
            currency
          }
          carrier_code
          carrier_title
          method_code
          method_title
        }
      }
      available_payment_methods {
        code
        title
      }
      selected_payment_method {
        code
        title
      }
      applied_coupons {
        code
      }
      prices {
        grand_total {
          value
          currency
        }
        discounts {
          label
          amount {
            value
          }
        }
        subtotal_excluding_tax {
          value
        }
        subtotal_including_tax {
          value
        }
        subtotal_with_discount_excluding_tax {
          value
        }
        applied_taxes {
          label
          amount {
            value
          }
        }
      }
      items {
        uid
        prices {
          total_item_discount {
            value
          }
          price {
            value
          }
          discounts {
            label
            amount {
              value
            }
          }
        }
        product {
          autoship
          name
          sku
          stock_status
          price_range {
            maximum_price {
              final_price {
                value
              }
              regular_price {
                value
              }
            }
            minimum_price {
              final_price {
                value
              }
              regular_price {
                value
              }
            }
          }
          special_price
          pv
          wholesale_price
          url_key
          iCBD
          image {
            url
          }
          categories {
            name
          }
        }
        quantity
        ... on BundleCartItem {
          bundle_options {
            uid
            label
            type
            values {
              id
              label
              price
              quantity
            }
          }
        }
        ... on ConfigurableCartItem {
          configurable_options {
            configurable_product_option_uid
            option_label
            configurable_product_option_value_uid
            value_label
          }
        }
      }
      total_quantity
    }
  }
`

export const SET_GUEST_EMAIL_ON_CART = gql`
  mutation setGuestEmail($cart_id: String!, $email: String!) {
    setGuestEmailOnCart(input: { cart_id: $cart_id, email: $email }) {
      cart {
        email
      }
    }
  }
`

export const CLEAR_CUSTOMER_CART = gql`
  mutation clearCart($cartUid: String!) {
    clearCustomerCart(cartUid: $cartUid) {
      status
    }
  }
`

export const MERGE_CARTS = gql`
  mutation mergeCarts($source_cart_id: String!, $destination_cart_id: String!) {
    mergeCarts(
      source_cart_id: $source_cart_id
      destination_cart_id: $destination_cart_id
    ) {
      id
      email
      is_virtual
      billing_address {
        city
        country {
          code
          label
        }
        firstname
        lastname
        postcode
        region {
          code
          label
        }
        street
        telephone
      }
      shipping_addresses {
        firstname
        lastname
        street
        city
        postcode
        region {
          code
          label
        }
        country {
          code
          label
        }
        telephone
        available_shipping_methods {
          amount {
            currency
            value
          }
          available
          carrier_code
          carrier_title
          method_code
          method_title
          price_excl_tax {
            value
            currency
          }
          price_incl_tax {
            value
            currency
          }
        }
        selected_shipping_method {
          amount {
            value
            currency
          }
          carrier_code
          carrier_title
          method_code
          method_title
        }
      }
      available_payment_methods {
        code
        title
      }
      selected_payment_method {
        code
        title
      }
      applied_coupons {
        code
      }
      prices {
        grand_total {
          value
          currency
        }
        discounts {
          label
          amount {
            value
          }
        }
        subtotal_excluding_tax {
          value
        }
        subtotal_including_tax {
          value
        }
        subtotal_with_discount_excluding_tax {
          value
        }
        applied_taxes {
          label
          amount {
            value
          }
        }
      }
      items {
        uid
        prices {
          total_item_discount {
            value
          }
          price {
            value
          }
          discounts {
            label
            amount {
              value
            }
          }
        }
        product {
          autoship
          name
          sku
          stock_status
          price_range {
            maximum_price {
              final_price {
                value
              }
              regular_price {
                value
              }
            }
            minimum_price {
              final_price {
                value
              }
              regular_price {
                value
              }
            }
          }
          special_price
          pv
          wholesale_price
          url_key
          iCBD
          image {
            url
          }
          categories {
            name
          }
        }
        quantity
        ... on BundleCartItem {
          bundle_options {
            uid
            label
            type
            values {
              id
              label
              price
              quantity
            }
          }
        }
        ... on ConfigurableCartItem {
          configurable_options {
            configurable_product_option_uid
            option_label
            configurable_product_option_value_uid
            value_label
          }
        }
      }
      total_quantity
      # items {
      #   id
      #   product {
      #     name
      #     sku
      #   }
      #   quantity
      # }
    }
  }
`

export const REORDER_ITEMS = gql`
  mutation reorderItems($orderNumber: String!) {
    reorderItems(orderNumber: $orderNumber) {
      cart {
        id
        email
        is_virtual
        billing_address {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          region {
            code
            label
          }
          street
          telephone
        }
        shipping_addresses {
          firstname
          lastname
          street
          city
          postcode
          region {
            code
            label
          }
          country {
            code
            label
          }
          telephone
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            carrier_code
            carrier_title
            method_code
            method_title
            price_excl_tax {
              value
              currency
            }
            price_incl_tax {
              value
              currency
            }
          }
          selected_shipping_method {
            amount {
              value
              currency
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
        }
        available_payment_methods {
          code
          title
        }
        selected_payment_method {
          code
          title
        }
        applied_coupons {
          code
        }
        prices {
          grand_total {
            value
            currency
          }
          discounts {
            label
            amount {
              value
            }
          }
          subtotal_excluding_tax {
            value
          }
          subtotal_including_tax {
            value
          }
          subtotal_with_discount_excluding_tax {
            value
          }
          applied_taxes {
            label
            amount {
              value
            }
          }
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
            }
            discounts {
              label
              amount {
                value
              }
            }
          }
          product {
            autoship
            name
            sku
            stock_status
            price_range {
              maximum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
              minimum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
            }
            special_price
            pv
            wholesale_price
            url_key
            iCBD
            image {
              url
            }
            categories {
              name
            }
          }
          quantity
          ... on BundleCartItem {
            bundle_options {
              uid
              label
              type
              values {
                id
                label
                price
                quantity
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              option_label
              configurable_product_option_value_uid
              value_label
            }
          }
        }
        total_quantity
        # id
        # items {
        #   uid
        #   product {
        #     sku
        #   }
        #   quantity
        #   prices {
        #     price {
        #       value
        #     }
        #   }
        # }
      }
      userInputErrors {
        code
        message
        path
      }
    }
  }
`

export const ADD_PRODUCTS_TO_CART = gql`
  mutation addProductsToCart($cartId: String!, $cartItems: [CartItemInput!]!) {
    addProductsToCart(cartId: $cartId, cartItems: $cartItems) {
      cart {
        id
        email
        is_virtual
        billing_address {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          region {
            code
            label
          }
          street
          telephone
        }
        shipping_addresses {
          firstname
          lastname
          street
          city
          postcode
          region {
            code
            label
          }
          country {
            code
            label
          }
          telephone
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            carrier_code
            carrier_title
            method_code
            method_title
            price_excl_tax {
              value
              currency
            }
            price_incl_tax {
              value
              currency
            }
          }
          selected_shipping_method {
            amount {
              value
              currency
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
        }
        available_payment_methods {
          code
          title
        }
        selected_payment_method {
          code
          title
        }
        applied_coupons {
          code
        }
        prices {
          grand_total {
            value
            currency
          }
          discounts {
            label
            amount {
              value
            }
          }
          subtotal_excluding_tax {
            value
          }
          subtotal_including_tax {
            value
          }
          subtotal_with_discount_excluding_tax {
            value
          }
          applied_taxes {
            label
            amount {
              value
            }
          }
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
            }
            discounts {
              label
              amount {
                value
              }
            }
          }
          product {
            autoship
            name
            sku
            stock_status
            price_range {
              maximum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
              minimum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
            }
            special_price
            pv
            wholesale_price
            url_key
            iCBD
            image {
              url
            }
            categories {
              name
            }
          }
          quantity
          ... on BundleCartItem {
            bundle_options {
              uid
              label
              type
              values {
                id
                label
                price
                quantity
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              option_label
              configurable_product_option_value_uid
              value_label
            }
          }
        }
        total_quantity
      }
    }
  }
`

export const ADD_BUNDLE_PRODUCTS_TO_CART = gql`
  mutation addBundleProductsToCart(
    $cart_id: String!
    $cart_items: [BundleProductCartItemInput!]!
  ) {
    addBundleProductsToCart(
      input: { cart_id: $cart_id, cart_items: $cart_items }
    ) {
      cart {
        id
        email
        is_virtual
        billing_address {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          region {
            code
            label
          }
          street
          telephone
        }
        shipping_addresses {
          firstname
          lastname
          street
          city
          postcode
          region {
            code
            label
          }
          country {
            code
            label
          }
          telephone
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            carrier_code
            carrier_title
            method_code
            method_title
            price_excl_tax {
              value
              currency
            }
            price_incl_tax {
              value
              currency
            }
          }
          selected_shipping_method {
            amount {
              value
              currency
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
        }
        available_payment_methods {
          code
          title
        }
        selected_payment_method {
          code
          title
        }
        applied_coupons {
          code
        }
        prices {
          grand_total {
            value
            currency
          }
          discounts {
            label
            amount {
              value
            }
          }
          subtotal_excluding_tax {
            value
          }
          subtotal_including_tax {
            value
          }
          subtotal_with_discount_excluding_tax {
            value
          }
          applied_taxes {
            label
            amount {
              value
            }
          }
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
            }
            discounts {
              label
              amount {
                value
              }
            }
          }
          product {
            autoship
            name
            sku
            stock_status
            price_range {
              maximum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
              minimum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
            }
            special_price
            pv
            wholesale_price
            url_key
            iCBD
            image {
              url
            }
            categories {
              name
            }
          }
          quantity
          ... on BundleCartItem {
            bundle_options {
              uid
              label
              type
              values {
                id
                label
                price
                quantity
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              option_label
              configurable_product_option_value_uid
              value_label
            }
          }
        }
        total_quantity
        # items {
        #   uid
        # }
      }
    }
  }
`

export const UPDATE_CART_ITEMS = gql`
  mutation updateCartItems(
    $cart_id: String!
    $cart_items: [CartItemUpdateInput!]!
  ) {
    updateCartItems(input: { cart_id: $cart_id, cart_items: $cart_items }) {
      cart {
        id
        email
        is_virtual
        billing_address {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          region {
            code
            label
          }
          street
          telephone
        }
        shipping_addresses {
          firstname
          lastname
          street
          city
          postcode
          region {
            code
            label
          }
          country {
            code
            label
          }
          telephone
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            carrier_code
            carrier_title
            method_code
            method_title
            price_excl_tax {
              value
              currency
            }
            price_incl_tax {
              value
              currency
            }
          }
          selected_shipping_method {
            amount {
              value
              currency
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
        }
        available_payment_methods {
          code
          title
        }
        selected_payment_method {
          code
          title
        }
        applied_coupons {
          code
        }
        prices {
          grand_total {
            value
            currency
          }
          discounts {
            label
            amount {
              value
            }
          }
          subtotal_excluding_tax {
            value
          }
          subtotal_including_tax {
            value
          }
          subtotal_with_discount_excluding_tax {
            value
          }
          applied_taxes {
            label
            amount {
              value
            }
          }
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
            }
            discounts {
              label
              amount {
                value
              }
            }
          }
          product {
            autoship
            name
            sku
            stock_status
            price_range {
              maximum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
              minimum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
            }
            special_price
            pv
            wholesale_price
            url_key
            iCBD
            image {
              url
            }
            categories {
              name
            }
          }
          quantity
          ... on BundleCartItem {
            bundle_options {
              uid
              label
              type
              values {
                id
                label
                price
                quantity
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              option_label
              configurable_product_option_value_uid
              value_label
            }
          }
        }
        total_quantity
        # items {
        #   uid
        # }
      }
    }
  }
`

export const REMOVE_PRODUCT_FROM_CART = gql`
  mutation removeProductFromCart($cart_id: String!, $cart_item_uid: ID!) {
    removeItemFromCart(
      input: { cart_id: $cart_id, cart_item_uid: $cart_item_uid }
    ) {
      cart {
        id
        email
        is_virtual
        billing_address {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          region {
            code
            label
          }
          street
          telephone
        }
        shipping_addresses {
          firstname
          lastname
          street
          city
          postcode
          region {
            code
            label
          }
          country {
            code
            label
          }
          telephone
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            carrier_code
            carrier_title
            method_code
            method_title
            price_excl_tax {
              value
              currency
            }
            price_incl_tax {
              value
              currency
            }
          }
          selected_shipping_method {
            amount {
              value
              currency
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
        }
        available_payment_methods {
          code
          title
        }
        selected_payment_method {
          code
          title
        }
        applied_coupons {
          code
        }
        prices {
          grand_total {
            value
            currency
          }
          discounts {
            label
            amount {
              value
            }
          }
          subtotal_excluding_tax {
            value
          }
          subtotal_including_tax {
            value
          }
          subtotal_with_discount_excluding_tax {
            value
          }
          applied_taxes {
            label
            amount {
              value
            }
          }
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
            }
            discounts {
              label
              amount {
                value
              }
            }
          }
          product {
            autoship
            name
            sku
            stock_status
            price_range {
              maximum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
              minimum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
            }
            special_price
            pv
            wholesale_price
            url_key
            iCBD
            image {
              url
            }
            categories {
              name
            }
          }
          quantity
          ... on BundleCartItem {
            bundle_options {
              uid
              label
              type
              values {
                id
                label
                price
                quantity
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              option_label
              configurable_product_option_value_uid
              value_label
            }
          }
        }
        total_quantity
        # items {
        #   uid
        # }
      }
    }
  }
`

export const APPLY_COUPON_TO_CART = gql`
  mutation applyCouponToCart($cart_id: String!, $coupon_code: String!) {
    applyCouponToCart(input: { cart_id: $cart_id, coupon_code: $coupon_code }) {
      cart {
        id
        email
        is_virtual
        billing_address {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          region {
            code
            label
          }
          street
          telephone
        }
        shipping_addresses {
          firstname
          lastname
          street
          city
          postcode
          region {
            code
            label
          }
          country {
            code
            label
          }
          telephone
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            carrier_code
            carrier_title
            method_code
            method_title
            price_excl_tax {
              value
              currency
            }
            price_incl_tax {
              value
              currency
            }
          }
          selected_shipping_method {
            amount {
              value
              currency
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
        }
        available_payment_methods {
          code
          title
        }
        selected_payment_method {
          code
          title
        }
        applied_coupons {
          code
        }
        prices {
          grand_total {
            value
            currency
          }
          discounts {
            label
            amount {
              value
            }
          }
          subtotal_excluding_tax {
            value
          }
          subtotal_including_tax {
            value
          }
          subtotal_with_discount_excluding_tax {
            value
          }
          applied_taxes {
            label
            amount {
              value
            }
          }
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
            }
            discounts {
              label
              amount {
                value
              }
            }
          }
          product {
            autoship
            name
            sku
            stock_status
            price_range {
              maximum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
              minimum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
            }
            special_price
            pv
            wholesale_price
            url_key
            iCBD
            image {
              url
            }
            categories {
              name
            }
          }
          quantity
          ... on BundleCartItem {
            bundle_options {
              uid
              label
              type
              values {
                id
                label
                price
                quantity
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              option_label
              configurable_product_option_value_uid
              value_label
            }
          }
        }
        total_quantity
        # items {
        #   product {
        #     name
        #   }
        #   quantity
        # }
        # applied_coupons {
        #   code
        # }
        # prices {
        #   grand_total {
        #     value
        #     currency
        #   }
        # }
      }
    }
  }
`

export const REMOVE_COUPON = gql`
  mutation removeCoupon($cart_id: String!) {
    removeCouponFromCart(input: { cart_id: $cart_id }) {
      cart {
        id
        email
        is_virtual
        billing_address {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          region {
            code
            label
          }
          street
          telephone
        }
        shipping_addresses {
          firstname
          lastname
          street
          city
          postcode
          region {
            code
            label
          }
          country {
            code
            label
          }
          telephone
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            carrier_code
            carrier_title
            method_code
            method_title
            price_excl_tax {
              value
              currency
            }
            price_incl_tax {
              value
              currency
            }
          }
          selected_shipping_method {
            amount {
              value
              currency
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
        }
        available_payment_methods {
          code
          title
        }
        selected_payment_method {
          code
          title
        }
        applied_coupons {
          code
        }
        prices {
          grand_total {
            value
            currency
          }
          discounts {
            label
            amount {
              value
            }
          }
          subtotal_excluding_tax {
            value
          }
          subtotal_including_tax {
            value
          }
          subtotal_with_discount_excluding_tax {
            value
          }
          applied_taxes {
            label
            amount {
              value
            }
          }
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
            }
            discounts {
              label
              amount {
                value
              }
            }
          }
          product {
            autoship
            name
            sku
            stock_status
            price_range {
              maximum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
              minimum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
            }
            special_price
            pv
            wholesale_price
            url_key
            iCBD
            image {
              url
            }
            categories {
              name
            }
          }
          quantity
          ... on BundleCartItem {
            bundle_options {
              uid
              label
              type
              values {
                id
                label
                price
                quantity
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              option_label
              configurable_product_option_value_uid
              value_label
            }
          }
        }
        total_quantity
        # items {
        #   product {
        #     name
        #   }
        # }
      }
    }
  }
`

export const SET_SHIPPING_ADDRESSES_ON_CART = gql`
  mutation addShippingAddressesOnCart(
    $cart_id: String!
    $shipping_addresses: [ShippingAddressInput]!
  ) {
    setShippingAddressesOnCart(
      input: { cart_id: $cart_id, shipping_addresses: $shipping_addresses }
    ) {
      cart {
        id
        email
        is_virtual
        billing_address {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          region {
            code
            label
          }
          street
          telephone
        }
        shipping_addresses {
          firstname
          lastname
          street
          city
          postcode
          region {
            code
            label
          }
          country {
            code
            label
          }
          telephone
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            carrier_code
            carrier_title
            method_code
            method_title
            price_excl_tax {
              value
              currency
            }
            price_incl_tax {
              value
              currency
            }
          }
          selected_shipping_method {
            amount {
              value
              currency
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
          pickup_location_code
        }
        available_payment_methods {
          code
          title
        }
        selected_payment_method {
          code
          title
        }
        applied_coupons {
          code
        }
        prices {
          grand_total {
            value
            currency
          }
          discounts {
            label
            amount {
              value
            }
          }
          subtotal_excluding_tax {
            value
          }
          subtotal_including_tax {
            value
          }
          subtotal_with_discount_excluding_tax {
            value
          }
          applied_taxes {
            label
            amount {
              value
            }
          }
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
            }
            discounts {
              label
              amount {
                value
              }
            }
          }
          product {
            autoship
            name
            sku
            stock_status
            price_range {
              maximum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
              minimum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
            }
            special_price
            pv
            wholesale_price
            url_key
            iCBD
            image {
              url
            }
            categories {
              name
            }
          }
          quantity
          ... on BundleCartItem {
            bundle_options {
              uid
              label
              type
              values {
                id
                label
                price
                quantity
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              option_label
              configurable_product_option_value_uid
              value_label
            }
          }
        }
        total_quantity
        # shipping_addresses {
        #   firstname
        #   lastname
        #   company
        #   street
        #   city
        #   region {
        #     region_id
        #   }
        #   postcode
        #   telephone
        #   country {
        #     code
        #     label
        #   }
        #   pickup_location_code
        #   available_shipping_methods {
        #     carrier_code
        #     carrier_title
        #     method_code
        #     method_title
        #   }
        # }
      }
    }
  }
`

export const SET_BILLING_ADDRESS_ON_CART = gql`
  mutation addBillingAddress(
    $cart_id: String!
    $billing_address: BillingAddressInput!
  ) {
    setBillingAddressOnCart(
      input: { cart_id: $cart_id, billing_address: $billing_address }
    ) {
      cart {
        id
        email
        is_virtual
        billing_address {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          region {
            code
            label
          }
          street
          telephone
        }
        shipping_addresses {
          firstname
          lastname
          street
          city
          postcode
          region {
            code
            label
          }
          country {
            code
            label
          }
          telephone
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            carrier_code
            carrier_title
            method_code
            method_title
            price_excl_tax {
              value
              currency
            }
            price_incl_tax {
              value
              currency
            }
          }
          selected_shipping_method {
            amount {
              value
              currency
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
        }
        available_payment_methods {
          code
          title
        }
        selected_payment_method {
          code
          title
        }
        applied_coupons {
          code
        }
        prices {
          grand_total {
            value
            currency
          }
          discounts {
            label
            amount {
              value
            }
          }
          subtotal_excluding_tax {
            value
          }
          subtotal_including_tax {
            value
          }
          subtotal_with_discount_excluding_tax {
            value
          }
          applied_taxes {
            label
            amount {
              value
            }
          }
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
            }
            discounts {
              label
              amount {
                value
              }
            }
          }
          product {
            autoship
            name
            sku
            stock_status
            price_range {
              maximum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
              minimum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
            }
            special_price
            pv
            wholesale_price
            url_key
            iCBD
            image {
              url
            }
            categories {
              name
            }
          }
          quantity
          ... on BundleCartItem {
            bundle_options {
              uid
              label
              type
              values {
                id
                label
                price
                quantity
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              option_label
              configurable_product_option_value_uid
              value_label
            }
          }
        }
        total_quantity
        # billing_address {
        #   firstname
        #   lastname
        #   company
        #   street
        #   city
        #   region {
        #     code
        #     label
        #   }
        #   postcode
        #   telephone
        #   country {
        #     code
        #     label
        #   }
        # }
      }
    }
  }
`

export const SET_SHIPPING_METHOD = gql`
  mutation setShippingMethod(
    $cart_id: String!
    $shipping_methods: [ShippingMethodInput!]!
  ) {
    setShippingMethodsOnCart(
      input: { cart_id: $cart_id, shipping_methods: $shipping_methods }
    ) {
      cart {
        id
        email
        is_virtual
        billing_address {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          region {
            code
            label
          }
          street
          telephone
        }
        shipping_addresses {
          firstname
          lastname
          street
          city
          postcode
          region {
            code
            label
          }
          country {
            code
            label
          }
          telephone
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            carrier_code
            carrier_title
            method_code
            method_title
            price_excl_tax {
              value
              currency
            }
            price_incl_tax {
              value
              currency
            }
          }
          selected_shipping_method {
            amount {
              value
              currency
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
        }
        available_payment_methods {
          code
          title
        }
        selected_payment_method {
          code
          title
        }
        applied_coupons {
          code
        }
        prices {
          grand_total {
            value
            currency
          }
          discounts {
            label
            amount {
              value
            }
          }
          subtotal_excluding_tax {
            value
          }
          subtotal_including_tax {
            value
          }
          subtotal_with_discount_excluding_tax {
            value
          }
          applied_taxes {
            label
            amount {
              value
            }
          }
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
            }
            discounts {
              label
              amount {
                value
              }
            }
          }
          product {
            autoship
            name
            sku
            stock_status
            price_range {
              maximum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
              minimum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
            }
            special_price
            pv
            wholesale_price
            url_key
            iCBD
            image {
              url
            }
            categories {
              name
            }
          }
          quantity
          ... on BundleCartItem {
            bundle_options {
              uid
              label
              type
              values {
                id
                label
                price
                quantity
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              option_label
              configurable_product_option_value_uid
              value_label
            }
          }
        }
        total_quantity
        # shipping_addresses {
        #   selected_shipping_method {
        #     carrier_code
        #     carrier_title
        #     method_code
        #     method_title
        #     amount {
        #       value
        #       currency
        #     }
        #   }
        # }
      }
    }
  }
`

export const SET_PAYMENT_METHOD = gql`
  mutation setPaymentMethod(
    $cart_id: String!
    $payment_method: PaymentMethodInput!
  ) {
    setPaymentMethodOnCart(
      input: { cart_id: $cart_id, payment_method: $payment_method }
    ) {
      cart {
        id
        email
        is_virtual
        billing_address {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          region {
            code
            label
          }
          street
          telephone
        }
        shipping_addresses {
          firstname
          lastname
          street
          city
          postcode
          region {
            code
            label
          }
          country {
            code
            label
          }
          telephone
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            carrier_code
            carrier_title
            method_code
            method_title
            price_excl_tax {
              value
              currency
            }
            price_incl_tax {
              value
              currency
            }
          }
          selected_shipping_method {
            amount {
              value
              currency
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
        }
        available_payment_methods {
          code
          title
        }
        selected_payment_method {
          code
          title
        }
        applied_coupons {
          code
        }
        prices {
          grand_total {
            value
            currency
          }
          discounts {
            label
            amount {
              value
            }
          }
          subtotal_excluding_tax {
            value
          }
          subtotal_including_tax {
            value
          }
          subtotal_with_discount_excluding_tax {
            value
          }
          applied_taxes {
            label
            amount {
              value
            }
          }
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
            }
            discounts {
              label
              amount {
                value
              }
            }
          }
          product {
            autoship
            name
            sku
            stock_status
            price_range {
              maximum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
              minimum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
            }
            special_price
            pv
            wholesale_price
            url_key
            iCBD
            image {
              url
            }
            categories {
              name
            }
          }
          quantity
          ... on BundleCartItem {
            bundle_options {
              uid
              label
              type
              values {
                id
                label
                price
                quantity
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              option_label
              configurable_product_option_value_uid
              value_label
            }
          }
        }
        total_quantity
        # selected_payment_method {
        #   code
        #   title
        # }
      }
    }
  }
`

export const PLACE_ORDER = gql`
  mutation placeOrder($cart_id: String!) {
    placeOrder(input: { cart_id: $cart_id }) {
      order {
        order_number
      }
    }
  }
`
