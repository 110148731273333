import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'semantic-ui-react'
// Contexts
import { usePrismic } from '../../context/PrismicContext'
import { Container } from '../common'
import { StyledText } from '../Cart/MaxOrderModal.styles'
import { colors } from '../../styles/GlobalStyle'

const MaxOrderModal = ({
  open,
  onClose,
  currentCountryCode,
  asCountryCode,
}) => {
  const {
    prismicData: { prismicAutoshipPage, prismicMarketAndLanguage },
  } = usePrismic()

  const asCountry = prismicMarketAndLanguage.markets.find(
    market => market.country_code === asCountryCode
  )
  const currentCountry = prismicMarketAndLanguage.markets.find(
    market => market.country_code === currentCountryCode
  )

  const body = prismicAutoshipPage.wrong_as_store
    .replace('{asCountryCode}', asCountry?.label ?? 'another')
    .replace('{currentCountryCode}', currentCountry?.label ?? 'different')

  const body2 = prismicAutoshipPage.change_to_new_store.replace(
    '{asCountryCode}',
    asCountry?.label ?? 'original'
  )

  return (
    <Modal
      closeIcon
      style={{ width: '80%', maxWidth: 300 }}
      open={open}
      centered={true}
      closeOnDimmerClick={true}
      closeOnEscape
      onClose={onClose}
    >
      <Container
        direction="column"
        padding="18px"
        style={{ marginTop: '1.5rem' }}
      >
        <StyledText style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
          {prismicAutoshipPage.unable_to_add}
        </StyledText>
      </Container>
      <Container direction="column" padding="18px">
        <StyledText>{body}</StyledText>
      </Container>
      <Container direction="column" padding="18px">
        <StyledText>{body2}</StyledText>
      </Container>
      <Container justify="flex-end">
        <StyledText
          style={{
            fontWeight: 'bold',
            color: colors.metallicSeaweed,
            marginRight: '4px',
            marginBottom: '4px',
            cursor: 'pointer',
          }}
          onClick={onClose}
        >
          {prismicAutoshipPage.close.toUpperCase()}
        </StyledText>
      </Container>
    </Modal>
  )
}

MaxOrderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default MaxOrderModal
