import {Modal, Header, Button} from 'semantic-ui-react'
import styled, { css } from 'styled-components'

export const StyledModalContent = styled(Modal.Content)`
  padding: 10px !important;
`
interface StyledTextProps {
  align: string
}

export const StyledText = styled.span<Partial<StyledTextProps>>`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.black};
  text-align: ${props => props.align || 'center'};
  @media ${({ theme }) => theme.device.sm} {
    font-size: 16px;
  }
`

export const StyledHeader = styled(Header)`
  text-align: center;
  font-size: 18px !important;
  font-weight: bold !important;
  border-bottom: 0 !important;

  @media ${({ theme }) => theme.device.sm} {
    font-size: 22px !important;
  }
`
export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 10px;
`
export const StyledButton = styled(Button)`
  &&& {
    background: inherit;
    border: none;
    align-self: flex-end;
    padding: 5px 0 15px 0;
    font-weight: bolder;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.metallicSeaweed};
    z-index: 10;

    :hover {
      background: inherit;
      color: ${({ theme }) => theme.colors.carnelian};
    }
  }
`