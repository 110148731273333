import { GraphqlResponse } from '../types/GraphqlResponse'

/**
 * graphqlrequest library stringifies errors (yargh). Function to parse error back into data
 * @param error error from graphql request
 */
export const parseGraphqlError = (error: string): GraphqlResponse => {
  // read through characters front to back until after the second colon (:)
  const afterFirstColon = error.substring(error.indexOf(':') + 1)
  const afterSecondColon = afterFirstColon.substring(
    afterFirstColon.indexOf(':') + 1
  )
  const stripAfterLastBracket = afterSecondColon.substring(
    0,
    afterSecondColon.lastIndexOf('}') + 1
  )
  const noEndlineCharacters = stripAfterLastBracket.replaceAll('\n', '')
  const parsedObject = JSON.parse(noEndlineCharacters)
  const graphqlResponse: GraphqlResponse = parsedObject.response
  return graphqlResponse
}
