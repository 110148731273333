import React from 'react'
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { Container, StyledCheckbox } from './AddToAutoship.styles'

const AddToAutoship = ({
  cartOption,
  setCartOption,
  nextAutoshipDate,
  autoshipItem,
}) => {
  const {
    prismicData: { prismicGeneral },
  } = usePrismic()

  let addToCartMsg
  if (nextAutoshipDate) {
    addToCartMsg =
      prismicGeneral.msg_add_to_cart_and_subscription_with_date.replace(
        '{date}',
        nextAutoshipDate
      )
  } else {
    addToCartMsg = prismicGeneral.msg_add_to_cart_and_subscription
  }

  const disclaimer = prismicGeneral.promotion_price_disclaimer
    .replace('{product_name}', autoshipItem?.name)
    .replace('{price}', Number(autoshipItem?.wholesale_price).toFixed(2))

  return (
    <Container>
      <StyledCheckbox
        checked={cartOption === 'both'}
        fitted
        label={{
          children: (
            <div>
              <div>{`${prismicGeneral.msg_add_to_cart_and_subscription_short}***`}</div>
              <p>
                <span>{addToCartMsg}</span>
              </p>
            </div>
          ),
        }}
        name="cartOptionGroup"
        onChange={() => setCartOption('both')}
        radio
        value="both"
      />

      <StyledCheckbox
        checked={cartOption === 'autoship'}
        fitted
        label={`${prismicGeneral.msg_add_to_subscription}***`}
        name="cartOptionGroup"
        onChange={() => setCartOption('autoship')}
        radio
        value="autoship"
      />

      <StyledCheckbox
        checked={cartOption === 'cart'}
        fitted
        label={prismicGeneral.msg_add_to_cart}
        name="cartOptionGroup"
        onChange={() => setCartOption('cart')}
        radio
        value="cart"
      />
      <span
        style={{ fontStyle: 'italic', fontSize: '12px', paddingRight: '12px' }}
      >
        {disclaimer}
      </span>
    </Container>
  )
}

export default AddToAutoship
