import styled from 'styled-components'
import { Modal, Button } from 'semantic-ui-react'

export const StyledModalContent = styled(Modal.Content)`
  padding: 10px;
`
const metallicSeaweed = '#007985'
export const PrimaryButton = styled(Button)`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    width: 90%;
    max-width: 300px;
    border-radius: 5px;
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.7);
    text-transform: uppercase;
    color: white;
    background-color: ${metallicSeaweed};
    :active {
      box-shadow: none;
      border: none;
    }
    :hover {
      box-shadow: none;
      border: none;
      background-color: ${metallicSeaweed};
      color: white;
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.align || 'center'};
  width: 100%;
  max-width: ${props => props.maxWidth || 'none'};
  padding: ${props => props.padding || '0.5rem'};
  align-self: center;
`

export const StyledText = styled.span`
  font-size: 1rem;
`
