import { addMonths, addDays, format } from 'date-fns'

export function oneYearFromToday(): string {
  const date = addMonths(new Date(), 12)
  return format(date, 'M/dd/yyyy')
}

export function addOneDay(date: Date): string {
  const datePlusOne = addDays(new Date(date), 1)
  return format(datePlusOne, 'MM/dd/yyyy')
}

export function getMMyyyyFormat(date: Date): string {
  return format(new Date(date), 'MM/yyyy')
}

export function formatDate(date: Date): string {
  let tempDate = new Date(date)
  return `${
    tempDate.getUTCMonth() + 1
  }/${tempDate.getUTCDate()}/${tempDate.getUTCFullYear()}`
}
