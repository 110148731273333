import { useEffect, useState } from 'react'
// Context
import { useAuthContext } from '../context/AuthContext'
import { useCartContext } from '../context/CartContext'
import { usePrismic } from '../context/PrismicContext'
// Types
import { ProductType } from '../types/ProductTypes'

const useAddToCart = (productData: ProductType) => {
  const [product, setProduct] = useState({ ...productData })
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedOptionProduct, setSelectedOptionProduct] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [count, setCount] = useState(1)

  const { isAuthenticated, userType } = useAuthContext()

  const {
    prismicData: { prismicGeneral },
  } = usePrismic()

  const {
    cartId,
    buildCart,
    manageCart,
    showCartPopup,
    setIsMaxOrderModalOpen,
  } = useCartContext()

  useEffect(() => {
    setProduct(productData)
  }, [productData])

  const increment = () => setCount(count + 1)
  const decrement = () => (count > 1 ? setCount(count - 1) : setCount(1))

  const isBundleProduct = product?.items?.length > 0

  const handleLoadingAndPopup = () => {
    setIsLoading(false)
    showCartPopup(true)
  }

  const handleSetOption = (option: string) => setSelectedOption(option)

  // Used to get the option sku for configurable products on autoships
  const handleSetOptionProduct = (option: string) =>
    setSelectedOptionProduct(option)

  const addToCart = async (count: number = 1, doesExceedCartLimit = false) => {
    if (doesExceedCartLimit) {
      return setIsMaxOrderModalOpen(true)
    }
    if (isAuthenticated && userType !== 'RETAIL') {
      setShowModal(true)
    }

    setIsLoading(true)

    if (isBundleProduct) {
      await manageCart
        .addBundleProductToCart(
          product,
          count,
          cartId,
          buildCart,
          prismicGeneral
        )
        .then(handleLoadingAndPopup)
    } else if (!!selectedOption) {
      await manageCart
        .addConfigurableProductToCart(
          { sku: product.sku, optionUid: selectedOption, name: product.name },
          count,
          cartId,
          buildCart,
          prismicGeneral
        )
        .then(handleLoadingAndPopup)
    } else {
      await manageCart
        .addItemToCart(product, count, cartId, buildCart, prismicGeneral)
        .then(handleLoadingAndPopup)
    }
  }

  return {
    count,
    isLoading,
    selectedOption,
    selectedOptionProduct,
    showModal,
    addToCart,
    decrement,
    increment,
    handleSetOption,
    handleSetOptionProduct,
    setShowModal,
  }
}

export default useAddToCart
