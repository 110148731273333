import toast from 'react-hot-toast'
import { Logger } from './logger'
// Services & Utils
import { parseGraphqlError } from './graphqlErrorParser'

export const OUT_OF_STOCK = 'OUT_OF_STOCK'

export const handleOutOfStock = (e, prismicGeneral) => {
  const graphqlResponse = parseGraphqlError(e.toString())
  const { errors, data } = graphqlResponse
  if (!errors) {
    Logger.log(e)
    return
  }

  const outOfStock = errors.some(
    ({ message }) =>
      message === prismicGeneral.msg_some_of_the_products_are_out_of_stock
  )
  if (outOfStock) return data
}

export const displayOutOfStockError = ({ items }, prismicGeneral) => {
  const outOfStockItems = items
    .filter(({ product }) => product.stock_status === OUT_OF_STOCK)
    .map(({ product }) => product.name)
  const outOfStockItemTitles = outOfStockItems.join(',')
  const message = `${prismicGeneral.msg_out_of_stock} ${outOfStockItemTitles}`
  toast.error(message)
}

/**
 * If a product is part of the Exclude PC category, PC users should not be able to see it
 * If a product is part of the Retail category, Retail users should not be able to see it
 * If a product is part of the Packs category, Retail users should not be able to see it
 */
export const filterProducts = (
  products,
  qUser,
  isPcInCart = false,
  isAmbInCart = false,
  isAmbPremInCart = false
) => {
  if (!products) return []
  const associateType = qUser?.associateType ?? 'NOT LOGGED IN'
  products = products.map(product => {
    product.categoryNames = product.categories?.map(({ name }) => name) ?? []
    return product
  })
  // const filteredByFeaturedAndPromo = products.filter(product => {
  //   const featuredOrPromo = product?.categoryNames.some(
  //     category => category === 'Featured Products' || category === 'Promotions'
  //   )
  //   const shouldHide = featuredOrPromo && product.show_in_category !== 1
  //   return !shouldHide
  // })
  if (associateType === 'AMBASSADOR' || isAmbInCart || isAmbPremInCart) {
    return products.filter(
      ({ categoryNames }) => !categoryNames.includes('Only PC')
    )
  } else if (associateType === 'PREFERRED' || isPcInCart) {
    return products.filter(
      ({ categoryNames }) => !categoryNames.includes('Exclude PC')
    )
  } else {
    return products.filter(
      ({ categoryNames }) =>
        !categoryNames.some(
          category =>
            category === 'Exclude Retail' ||
            category === 'Packs' ||
            category === 'Only PC'
        )
    ) // if you're not logged in, or not retail, you can't see packs
  }
}

export const buildConfigOptions = (
  configurable_options,
  configurable_product_options_selection,
  variants,
  defaultImage
) => {
  const optionsDataArr = configurable_options?.[0].values
  let optionsDataWithImages = {}

  variants.forEach((variant, i) => {
    const imageData =
      configurable_product_options_selection.media_gallery[i] ?? defaultImage
    optionsDataWithImages[variant.attributes[0].uid] = {
      ...variant.attributes[0],
      image: imageData,
      ...variant.product,
    }
  })

  return optionsDataWithImages
}

export const configureCategories = categories => {
  const result = categories.reduce(
    (acc, category) => ({
      ...acc,
      [category.name]: {
        displayOrder: category.display_order,
        description: category.description,
      },
    }),
    {}
  )
  return result
}

const changeObjToArr = obj => Object.keys(obj).map(key => obj[key])

const injectDescription = (map, categories) => {
  return categories.map(category => ({
    ...category,
    description: map[category.url_key]?.description,
  }))
}

export const sortCategories = (
  categoryMap,
  orderedCategories,
  qUser,
  isPcOfferInCart: boolean,
  isAmbOfferInCart: boolean,
  isAmbPremOfferInCart: boolean
) => {
  // turn type and benefits object to array
  const categories = changeObjToArr(categoryMap)
  // turn prismic category array into object
  const filteredCategories = categories.map(cat => {
    const products = filterProducts(
      cat.products,
      qUser,
      isPcOfferInCart,
      isAmbOfferInCart,
      isAmbPremOfferInCart
    )
    return { ...cat, products: products }
  })
  const categoryDisplayOrderMap = configureCategories(orderedCategories)
  // add prismic description to categories
  const categoriesWithDescription = injectDescription(
    categoryDisplayOrderMap,
    filteredCategories
  )
  const sortedList = categoriesWithDescription
    ?.sort(
      (a, b) =>
        categoryDisplayOrderMap[a.url_key]?.displayOrder -
        categoryDisplayOrderMap[b.url_key]?.displayOrder
    )
    .filter(item => item.products.length > 0)
  return sortedList
}

export const canSeeProduct = product => {
  // If a product is part of the Exclude PC category, PC users should not be able to see it
  const categoryNames = product?.categories?.map(({ url_key }) => url_key) ?? []
  if (categoryNames.includes('only-pc')) {
    return ['PREFERRED']
  }
  if (categoryNames.includes('exclude-retail')) {
    return ['AMBASSADOR', 'PREFERRED']
  }
  if (categoryNames.includes('packs')) {
    if (categoryNames.includes('exclude-pc')) {
      return ['AMBASSADOR']
    } else {
      return ['AMBASSADOR', 'PREFERRED']
    }
  }
  return ['AMBASSADOR', 'PREFERRED', 'RETAIL']
}
