import React, { useState, useEffect, useContext } from 'react'
// Contexts
import { useAuthContext } from './AuthContext'
// Services
import { Magento, MagentoAutoship, MagentoHistory } from '../services/magento'
// Types
import { StoreContextType } from '../types/contexts/StoreContextTypes'
// Utils
import { filterProducts } from '../utils/productHelpers'
import { buildStoreData, buildAutoshipStoreData } from '../utils/storeHelpers'
import { getStoreView, saveStoreView } from '../utils/i18n/stores'
import { initialStoreState } from './initialStates'
import { usePrismic } from './PrismicContext'

const Store = React.createContext<Partial<StoreContextType>>({})

export const useStoreContext = () => useContext(Store)

const StoreProvider = ({ children }) => {
  const [
    {
      isStoreLoading,
      isLandingPageLoading,
      landingPageData,
      storeData,
      autoshipStoreData,
    },
    setStoreState,
  ] = useState(initialStoreState)

  const { isAuthLoading, isEventSite, qUser, userType } = useAuthContext()

  const { langAndCountry, saveLangAndCountry } = usePrismic()

  const allowedMarkets = ['en-US', 'en-AU', 'en-CA', 'en-NZ', 'fr-CA']
  const localeArray = langAndCountry.split('-')
  const parsedLanguage = localeArray[0]
  const parsedCountry = localeArray[1]
  const currentStoreCode = `${parsedLanguage}_${parsedCountry}`

  Magento.User.setStoreView(
    getStoreView(localStorage, navigator.language).store_code
  )

  MagentoHistory.User.setStoreView(
    getStoreView(localStorage, navigator.language).store_code
  )

  MagentoAutoship.User.setStoreView(
    getStoreView(localStorage, navigator.language)?.autoship_store_code
  )

  useEffect(() => {
    if (isAuthLoading) return
    if (!allowedMarkets.includes(langAndCountry)) {
      saveLangAndCountry('en-US')
      handleChangeStore(currentStoreCode, 'en_US')
    }
    if (qUser) {
      handleLoadLandingPage(qUser)
      handleLoadStore(qUser)
      handleLoadAutoshipStore(qUser)
    } else {
      handleLoadLandingPage()
      handleLoadStore()
      handleLoadAutoshipStore()
    }
  }, [isAuthLoading])

  const handleChangeStore = (
    currentStoreViewCode: string,
    storeViewCode: string
  ) => {
    if (currentStoreViewCode === storeViewCode) return
    saveStoreView(localStorage, storeViewCode)
    window.location.reload()
  }

  const handleLoadLandingPage = async (
    qUser = null,
    isPcInCart = false,
    isAmbInCart = false,
    isAmbPremInCart = false
  ) => {
    setStoreState(prev => ({
      ...prev,
      isLandingPageLoading: true,
    }))

    await Magento.Store.getLandingPageData()
      .then(({ benefit, type, featuredProducts }) => {
        const filteredFeaturedProducts = filterProducts(
          featuredProducts.items[0].products.items,
          qUser,
          isPcInCart,
          isAmbInCart,
          isAmbPremInCart
        )
        return {
          benefit: benefit.items[0],
          type: type.items[0],
          'featured-products': filteredFeaturedProducts,
        }
      })
      .then(data => {
        setStoreState(prev => ({
          ...prev,
          isLandingPageLoading: false,
          landingPageData: { ...data },
        }))
      })
  }

  const handleLoadStore = async (qUser = null) => {
    setStoreState(prev => ({
      ...prev,
      isStoreLoading: true,
    }))

    const productsArr = await Magento.Store.getAllProducts()
      .then(({ products }) => {
        return products.items.map(product => {
          product.categoryNames = product.categories.map(
            category => category.name
          )
          return product
        })
      })
      .catch(e => console.log(e))
    // const filteredProducts = filterProducts(productsArr, qUser)
    setStoreState(prev => ({
      ...prev,
      isStoreLoading: false,
      storeData: buildStoreData(
        productsArr,
        isEventSite,
        userType === 'RETAIL'
      ),
    }))
  }

  const handleLoadAutoshipStore = async (qUser = null) => {
    MagentoAutoship.Store.getAllProductsLite()
      .then(({ products }) => {
        setStoreState(prev => ({
          ...prev,
          autoshipStoreData: buildAutoshipStoreData(products.items) || {},
        }))
      })
      .catch(e => console.log(e))
  }

  return (
    <Store.Provider
      value={{
        isLandingPageLoading,
        isStoreLoading,
        landingPageData,
        storeData,
        autoshipStoreData,
        handleChangeStore,
        handleLoadLandingPage,
      }}
    >
      {children}
    </Store.Provider>
  )
}

export default StoreProvider
