import React, { useState, useEffect } from 'react'
import { Magento } from '../../services/magento'
// Context
import { usePrismic } from '../../context/PrismicContext'
// Utils
import { formatCurrency } from '../../utils/i18n/stores'
// Styles
import {
  Container,
  ListContainer,
  ListItem,
  StartSavingButton,
  StyledCheck,
  StyledText,
  Title,
  Wrapper,
} from './PcSmartship.styles'

const PcSmartship = ({ addPcSmartshipToCart }) => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    langAndCountry,
    prismicData: {
      prismicPickYourPerksModal: {
        smartship_title,
        smartship_text,
        smartship_benefits,
        smartship_start_saving_today,
      }
    },
  } = usePrismic()

  const [smartshipItem, setSmartshipItem] = useState(null)
  const getSmartshipItem = async () => {
    const result = await Magento.Store.getSingleProductBySku({ sku: 'SMARTSHIP' })
    setSmartshipItem(result)
  }
  useEffect(() => {
    getSmartshipItem()
  }, [])

  const price =
    smartshipItem?.products?.items[0]?.price?.regularPrice?.amount?.value

  const formattedPrice = formatCurrency(langAndCountry, price)

  const for_smartship_message = smartship_text[0].text.replace(
    '{amount}',
    formattedPrice
  )

  const handleAddOffer = async () => {
    setIsLoading(true)
    await addPcSmartshipToCart()
    setIsLoading(false)
  }

  return (
    <Wrapper>
      <Title>{smartship_title[0].text}</Title>

      <StyledText>{for_smartship_message}</StyledText>

      <Container>
        <ListContainer>
          {smartship_benefits.map((benefit: any, index: number) => (
            <ListItem key={index}>
              <StyledCheck />
              <StyledText>{benefit.text}</StyledText>
            </ListItem>
          ))}
        </ListContainer>
      </Container>

      <StartSavingButton onClick={handleAddOffer} loading={isLoading}>
        {smartship_start_saving_today[0].text}
      </StartSavingButton>
    </Wrapper>
  )
}

export default PcSmartship
