import { Modal, Header, Dropdown, Checkbox } from 'semantic-ui-react'
import styled from 'styled-components'

export const StyledModal = styled(Modal)`
  width: 90%;
  max-width: 600px;
  &&& {
    i.close {
      top: 0;
      right: 0;
      color: ${({ theme }) => theme.colors.black};
    }
    .content {
      padding: 2rem 3rem;
    }
  }
`

export const StyledHeader = styled(Header)`
  background: #d8d8d8 !important;
  text-align: center;
  font-size: 18px !important;
  font-weight: bold !important;

  @media ${({ theme }) => theme.device.sm} {
    font-size: 22px !important;
  }
`

export const MarketLabel = styled.div`
  font-size: 16px;
  font-weight: bold;

  @media ${({ theme }) => theme.device.sm} {
    font-size: 19px;
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  color: ${props => props.theme.colors.metallicSeaweed};
  font-weight: bold;
  font-size: 16px;

  :hover {
    cursor: pointer;
  }
`
