import {
  AddToAutoShip,
  AutoShipsType,
  AutoShipType,
  UpdateAutoShip,
  UpdateAutoShipItems,
  UpdateProcessDate,
} from '../../../types/AutoShipTypes'
import {
  ADD_AUTO_SHIP,
  GET_AUTO_SHIP,
  GET_AUTO_SHIPS,
  SKIP_AUTO_SHIP_MONTH,
  UPDATE_AUTO_SHIP_DATE_NEXT_PROCESS,
  UPDATE_AUTO_SHIP_ITEMS,
  UPDATE_AUTO_SHIP,
} from '../graphql/autoship'

export const AutoShip = client => ({
  addTo: async (data: AddToAutoShip): Promise<AutoShipType> =>
    await client.request(ADD_AUTO_SHIP, data),
  getData: async (autoShipId: number): Promise<AutoShipType> =>
    await client.request(GET_AUTO_SHIP, { autoShipId }),
  getId: async (): Promise<AutoShipsType> =>
    await client.request(GET_AUTO_SHIPS),
  skipMonth: async (autoShipId: number): Promise<AutoShipType> =>
    await client.request(SKIP_AUTO_SHIP_MONTH, { autoShipId }),
  update: async (data: UpdateAutoShip): Promise<AutoShipType> =>
    await client.request(UPDATE_AUTO_SHIP, data),
  updateDateNextProcess: async (
    data: UpdateProcessDate
  ): Promise<AutoShipType> =>
    await client.request(UPDATE_AUTO_SHIP_DATE_NEXT_PROCESS, data),
  updateItems: async (data: UpdateAutoShipItems): Promise<AutoShipType> =>
    await client.request(UPDATE_AUTO_SHIP_ITEMS, data),
})
