import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { Image, Search } from 'semantic-ui-react'

export const StyledSearch = styled(Search)`
  &&& {
    .input {
      width: 80vw;
      max-width: 600px;
    }
    .result {
      padding: 0 0 0 10px !important;
    }
  }
`

export const StyledSearchAdd = styled(Search)`
  &&& {
    .input {
      width: 80vw;
      max-width: 400px;
    }
    .prompt {
      border-radius: 5px !important;
    }
    .result {
      padding: 0 0 0 10px !important;
    }
    margin: 15px 0;
  }
`

export const StyledLink = styled(Link)`
  &&& {
    color: ${({ theme }) => theme.colors.black};
    :active {
      color: ${({ theme }) => theme.colors.black};
    }

    ::before {
      display: none;
    }
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 5px;
`

export const StyledImage = styled(Image)`
  &&& {
    height: 70px !important;
    width: 70px !important;
  }
`

export const SearchItemWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const SearchItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  padding: 10px 0;
  width: calc(100% - 80px);
`

export const TitleContainer = styled.div`
  max-width: 100%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
`

export const StyledSpan = styled.span`
  font-size: 1.1em;

  ${props =>
    props.isTitle &&
    css`
      font-weight: bold;
      color: ${props.theme.colors.black};
    `}
  ${props =>
    props.price &&
    css`
      color: ${props.theme.colors.green};
    `}
    ${props =>
    props.pv &&
    css`
      font-size: 1em;
      color: ${props.theme.colors.silver};
      margin-left: 10px;
    `}
`
