import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'semantic-ui-react'
import { usePrismic } from '../../context/PrismicContext'
import {
  Container,
  StyledButton,
  StyledModalContent,
  StyledText,
} from './Verification.styles'

const VerifyChangeLocaleModal = ({ open, onClose, yesOnClick }) => {
  const {
    prismicData: {
      prismicGeneral,
      prismicExistingQModal: { no: noText, yes: yesText },
    },
  } = usePrismic()
  return (
    <Modal
      closeIcon
      style={{ width: '80%', maxWidth: 300 }}
      open={open}
      centered={true}
      closeOnDimmerClick={true}
      closeOnEscape
      onClose={onClose}
    >
      <StyledModalContent style={{ padding: '3em' }}>
        <StyledText>
          {prismicGeneral.msg_selected_different_country_or_language}
        </StyledText>
        <Container justify="flex-end">
          <StyledButton onClick={onClose}>{noText[0].text}</StyledButton>
          <StyledButton onClick={yesOnClick}>{yesText[0].text}</StyledButton>
        </Container>
      </StyledModalContent>
    </Modal>
  )
}

VerifyChangeLocaleModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  yesOnClick: PropTypes.func.isRequired,
}

export default VerifyChangeLocaleModal
