export const LOCALE_KEY = 'USER_LOCALE'

/**
 * Gets the locale of the user
 * Uses settings from localstorage first, then browser settings, then 'US'
 */
export const getUserLocale = (storage: Storage, locale: string) => {
  return storage.getItem(LOCALE_KEY) ?? locale
}

/**
 * Saves locale to localstorage
 * @param locale locale code to save to local storage (i.e. "en-US")
 */
export const saveUserLocale = (storage: Storage, locale: string) => {
  storage.setItem(LOCALE_KEY, locale)
}
