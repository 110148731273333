import styled from 'styled-components'
import { Checkbox } from 'semantic-ui-react'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledCheckbox = styled(Checkbox)`
  &&& {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;

    label {
      font-size: 18px;
      font-weight: bold;
      p {
        width: 90%;
        line-height: 15px;
      }
      span {
        font-weight: 500;
        font-size: 13px;
      }
    }
  }
`
