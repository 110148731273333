import toast from 'react-hot-toast'
import axios from 'axios'
import StackTrace from 'stacktrace-js'
import { Logger } from './logger'
// Services & Utils
import { Magento } from '../services/magento'
import { QService } from '../services/q-services'
import environment from '../utils/environment'
import { enrollSkus } from './constants'
import * as Sentry from '@sentry/react'
// Types
import { OrderDetailsType, CartItem } from '../types/CartTypes'

export const getOrderDetailValues = ({
  subtotal_excluding_tax,
  subtotal_including_tax,
  grand_total,
  shipping,
}: OrderDetailsType) => {
  const subTotalValue = subtotal_excluding_tax?.value || 0
  const taxValue =
    subtotal_including_tax?.value - subtotal_excluding_tax?.value || 0
  const shippingValue = shipping?.amount?.value || 0
  const grandTotalValue = grand_total?.value || 0

  const getAmounts = () => ({
    subTotalText: subTotalValue ?? 0,
    taxesText: taxValue ?? 0,
    shippingText: shippingValue ?? 0,
  })

  const getTotalWithShipping = () => {
    return grandTotalValue + shippingValue
  }

  return {
    getAmounts,
    getTotalWithShipping,
  }
}

const SLACK = environment.SLACK_WEBHOOK_URL

const addItemToCart = async (
  item,
  quantity = 1,
  cartId: string,
  updateCart,
  prismicGeneral
) => {
  const itemObj = {
    sku: item.sku,
    quantity,
  }

  const cartObj = {
    cartId: cartId,
    cartItems: [itemObj],
  }

  await Magento.Cart.addProductsToCart(cartObj)
    .then(updateCart)
    .catch(err => {
      console.log(err)
      toast.error(
        err?.response?.errors[0]?.message ?? prismicGeneral.error_adding_item
      )
    })
}

const addItemsToCart = async (
  items: string[],
  quantity = 1,
  cartId: string,
  updateCart,
  prismicGeneral
) => {
  const cartItems = items.map(sku => ({
    sku,
    quantity,
  }))

  const cartObj = {
    cartId: cartId,
    cartItems,
  }

  await Magento.Cart.addProductsToCart(cartObj)
    .then(updateCart)
    .catch(err => {
      console.log(err)
      toast.error(
        err?.response?.errors[0]?.message ?? prismicGeneral.error_adding_items
      )
    })
}

const addBundleProductToCart = async (
  product,
  quantity = 1,
  cartId,
  updateCart,
  prismicGeneral
) => {
  const bundledOptions = product.items.map(item => ({
    id: item.option_id,
    quantity: item.options[0].quantity,
    value: [`${item.options[0].id}`],
  }))

  const productObj = {
    data: {
      sku: product.sku,
      quantity,
    },
    bundle_options: bundledOptions,
  }

  const cartObj = {
    cart_id: cartId,
    cart_items: [productObj],
  }

  await Magento.Cart.addBundleProductsToCart(cartObj)
    .then(updateCart)
    .catch(err => {
      console.log(err)
      toast.error(
        err?.response?.errors[0]?.message ??
          prismicGeneral.error_adding_bundle_item
      )
    })
}

const addConfigurableProductToCart = async (
  item,
  quantity = 1,
  cartId,
  updateCart,
  prismicGeneral
) => {
  const itemObj = {
    sku: item.sku,
    selected_options: [item.optionUid],
    quantity,
  }

  const cartObj = {
    cartId: cartId,
    cartItems: [itemObj],
  }

  await Magento.Cart.addProductsToCart(cartObj)
    .then(updateCart)
    .catch(err => {
      console.log(err)
      toast.error(
        err?.response?.errors[0]?.message ??
          prismicGeneral.error_adding_configurable_item
      )
    })
}

const removeItemFromCart = async (
  itemUid: string,
  cartId: string,
  updateCart: any,
  prismicGeneral
) => {
  const cartObj = {
    cart_id: cartId,
    cart_item_uid: itemUid,
  }

  await Magento.Cart.removeItemFromCart(cartObj)
    .then(updateCart)
    .catch(err => {
      console.log(err)
      toast.error(
        err?.response?.errors[0]?.message ?? prismicGeneral.error_removing_item
      )
    })
}

const removeItemsFromCart = async (
  items: string[],
  cartId: string,
  updateCart: any,
  prismicGeneral
) => {
  const cartItems = items.map(uid => ({
    cart_item_uid: uid,
    quantity: 0,
  }))

  const cartObj = {
    cart_id: cartId,
    cart_items: cartItems,
  }

  await Magento.Cart.updateItemsInCart(cartObj)
    .then(updateCart)
    .catch(err => {
      console.log(err)
      toast.error(
        err?.response?.errors[0]?.message ?? prismicGeneral.error_removing_items
      )
    })
}

const updateCartItems = async (
  item,
  add: boolean = true,
  cartId: string,
  updateCart: any,
  prismicGeneral
) => {
  const { uid, quantity } = item
  const itemObj = {
    cart_item_uid: uid,
    quantity: add ? quantity + 1 : quantity - 1,
  }
  const cartObj = {
    cart_id: cartId,
    cart_items: [itemObj],
  }

  await Magento.Cart.updateItemsInCart(cartObj)
    .then(updateCart)
    .catch(err => {
      console.log(err)
      toast.error(
        err?.response?.errors[0]?.message ?? prismicGeneral.error_updating_items
      )
    })
}

const handleReorderItems = async (orderNumber: string, updateCart: any) =>
  await Magento.Cart.reorderItems({ orderNumber })
    .then(updateCart)
    .catch(err => Logger.log(err))

const addCouponToCart = async (
  code: string,
  cartId: string,
  updateCart: any,
  prismicGeneral
) => {
  const cartObj = {
    cart_id: cartId,
    coupon_code: code,
  }
  return await Magento.Cart.applyCouponToCart(cartObj)
    .then(updateCart)
    .catch(err => {
      console.log(err)
      toast.error(
        err?.response?.errors[0]?.message ?? prismicGeneral.error_adding_coupon
      )
    })
}

const removeCouponFromCart = async (
  cartId: string,
  updateCart: any,
  prismicGeneral
) =>
  await Magento.Cart.removeCouponFromCart({
    cart_id: cartId,
  })
    .then(updateCart)
    .catch(err => {
      console.log(err)
      toast.error(
        err?.response?.errors[0]?.message ??
          prismicGeneral.error_removing_coupon
      )
    })

const placeMagentoOrder = async (cartData, updateCart) => {
  try {
    // setShippingMethodOnCart
    const method = cartData?.shipping_addresses[0].available_shipping_methods
    if (method.length) {
      const shippingMethodObj = {
        cart_id: cartData.id,
        shipping_methods: [
          {
            carrier_code: method[0].carrier_code,
            method_code: method[0].method_code,
          },
        ],
      }
      await Magento.Cart.setShippingMethodOnCart(shippingMethodObj)
        .then(updateCart)
        .then(() => Logger.log('shipping method set'))
        .catch(err => {
          Logger.log(err)
          Sentry.captureException(err)
        })
    }

    // setPaymentMethodOnCart
    let paymentMethodObj = {}
    if (cartData?.prices.grand_total.value == 0) {
      paymentMethodObj = {
        cart_id: cartData.id,
        payment_method: {
          code: 'free',
        },
      }
    } else {
      const cartPaymentMethods = cartData?.available_payment_methods[0]
      if (cartPaymentMethods) {
        const { code } = cartPaymentMethods
        paymentMethodObj = {
          cart_id: cartData.id,
          payment_method: {
            code,
          },
        }
      }
    }
    await Magento.Cart.setPaymentMethodOnCart(paymentMethodObj)
      .then(updateCart)
      .then(() => Logger.log('payment method set'))
      .catch(err => {
        Logger.log(err)
        Sentry.captureException(err)
      })
  } catch (error) {
    Sentry.captureException(error)
    Logger.log(error)
  }

  const { placeOrder } = await Magento.Cart.placeOrder({
    cart_id: cartData.id,
  })

  return placeOrder.order.order_number
}

const placePayPalOrder = async (
  isLoading,
  cartData,
  updateCart,
  payPalOrderId,
  refreshCart
) => {
  isLoading(true)
  const orderNumber = await placeMagentoOrder(cartData, updateCart)
  let orderError = false
  let status = null

  const payPalObj = {
    magentoOrderIncrementId: orderNumber,
    payPalOrderId,
  }
  try {
    let value = await QService.Payments.processPayPalOrderV3(payPalObj)
    if (value !== true) {
      orderError = true
      status = 'Cancelled'
    } else {
      status = 'Processing'
    }
  } catch (err) {
    status = 'Cancelled'
    orderError = true
    Sentry.captureException(err)
    StackTrace.get(err)
      .then(data => {
        const stackErr = JSON.stringify(data)
        const qErr = JSON.stringify(err)
        return axios.post(
          SLACK,
          {
            text: `=======PAYPAL ORDER ERROR=======\n\nStack Error:\n${stackErr}\n\nQ Services Error:\n${qErr}`,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
      })
      .catch(err => {
        console.log(err)
        Sentry.captureException(err)
      })
  }
  refreshCart()
  isLoading(false)
  return { orderNumber, orderError, status }
}

const placeOrder = async (
  isLoading,
  cartData,
  getSelectedCard,
  updateCart,
  refreshCart
) => {
  isLoading(true)
  let orderNumber: string, status: string, orderError: any, redirectData: any

  const magentoOrderId = await placeMagentoOrder(cartData, updateCart)
  orderNumber = magentoOrderId

  const { creditCardGuid } = getSelectedCard()
  const nexioObj = {
    magentoOrderIncrementId: orderNumber,
    creditCardGuid: creditCardGuid,
    customerRedirectUrl: `${environment.NEXIO_3DS_REDIRECT_URL}?mOrderNumber=${magentoOrderId}`,
  }

  // handle payment response from Nexio & Q Services
  try {
    const { success, error, redirect, redirectResult, errorResult } =
      await QService.Payments.nexioProcessTransactionV2(nexioObj)
    if (error) {
      status = 'Cancelled'
      orderError = errorResult.message
    }
    if (redirect) {
      redirectData = redirectResult
    }
  } catch (err) {
    Sentry.captureException(err)
    StackTrace.get(err)
      .then(data => {
        const stackErr = JSON.stringify(data)
        const qErr = JSON.stringify(err)
        return axios.post(
          SLACK,
          {
            text: `=======ORDER FAILED, STATUS PENDING=======\n\nStack Error:\n${stackErr}\n\nQ Services Error:\n${qErr}`,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
      })
      .catch(err => {
        Sentry.captureException(err)
        console.log(err)
      })

    orderError = true
  } finally {
    localStorage.removeItem('mdefaultcard')
  }

  if (!redirectData) {
    refreshCart()
  }
  isLoading(false)
  return { orderNumber, orderError, status, redirectData }
}

export const completeNexioTransaction = async (
  pendingTransactionUid: string,
  cancel: boolean,
  token?: string
) => {
  const { message } = await QService.Payments.nexioConfirmTransaction(
    { pendingTransactionUid, cancel },
    token
  )
  console.log(message)
}

export const findIdsOfAmbEnrollmentProducts = async (items: CartItem[]) => {
  const { arq, myQFit } = enrollSkus
  const arqArray = items.filter(({ product }) => product.sku === arq)
  const myQFitArray = items.filter(({ product }) => product.sku === myQFit)
  return { arqId: arqArray?.[0]?.uid, myQFitId: myQFitArray?.[0]?.uid }
}

const doesCartHaveCBD = cartData =>
  cartData?.items?.some(item => item.product.iCBD)

const manageCart = {
  addItemToCart,
  addItemsToCart,
  addBundleProductToCart,
  addConfigurableProductToCart,
  addCouponToCart,
  handleReorderItems,
  removeCouponFromCart,
  removeItemFromCart,
  removeItemsFromCart,
  updateCartItems,
  placeOrder,
  placeMagentoOrder,
  placePayPalOrder,
  findIdsOfAmbEnrollmentProducts,
  doesCartHaveCBD,
}

export default manageCart
