// Create cookie
export const setCookie = (cname = 'user-cookie-consent') => {
  const d = new Date()
  d.setTime(d.getTime() * 24 * 60 * 60 * 1000)
  let timeStamp = 'timeStamp=' + d.toUTCString()
  document.cookie = `${cname}=true;${timeStamp};path=/`
}

// Delete cookie
export const deleteCookie = (cname = 'user-cookie-consent') => {
  const d = new Date()
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000)
  let timeStamp = 'timeStamp=' + d.toUTCString()
  document.cookie = `${cname}=false;${timeStamp};path=/`
}

// Read cookie
export const getCookie = (cname = 'user-cookie-consent') => {
  let name = cname + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
