import styled from 'styled-components'
import { Message } from 'semantic-ui-react'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 1em 0;
`

export const StyledMessage = styled(Message)`
  &&& {
    width: 100%;
    text-align: center;
    font-weight: bold;
  }
`

export const StyledHeader = styled.div`
  font-weight: bolder;
  font-size: 1.5em;
`
