import { Modal, Header } from 'semantic-ui-react'
import styled, { css } from 'styled-components'
import { Button, Checkbox, Image } from 'semantic-ui-react'
import { Primary as PrimaryButton } from '..'

export const StyledModal = styled(Modal)`
  width: 90% !important;

  @media ${({ theme }) => theme.device.sm} {
    width: 600px !important;
  }

  &&& {
    i.close {
      top: 0 !important;
      left: 0;
      color: ${({ theme }) => theme.colors.black};
    }
  }
`

export const StyledModalContent = styled(Modal.Content)`
  &&& {
    padding: 10px;
    text-align: ${props => props.textAlign};
  }
`

export const StyledHeader = styled(Header)`
  text-align: center;
  font-size: 18px !important;
  font-weight: bold !important;
  border-bottom: 0 !important;

  @media ${({ theme }) => theme.device.sm} {
    font-size: 22px !important;
  }
`

export const StyledImage = styled(Image)`
  &&& {
    margin-top: 1em;
  }
`

interface ContainerProps {
  column: boolean
  justify: string
  align: string
  padding: string
  maxWidth: string
  textAlign: string
  margin?: string
  width?: string
}

export const Container = styled.div<Partial<ContainerProps>>`
  display: flex;
  flex-flow: ${props => (props.column ? 'column nowrap' : 'row nowrap')};
  justify-content: ${props => props.justify};
  align-items: ${props => props.align};
  width: ${props => props.width || '100%'};
  padding: ${props => props.padding};
  margin: ${props => props.margin || '0'};
  max-width: ${props => props.maxWidth};

  ul > li {
    margin-bottom: 0.5em;
  }
`

export const StyledButton = styled(Button)`
  &&& {
    display: flex;
    flex: 1 0 auto;
    border: 1px solid black;
    background: white;
    padding: 1em;

    :hover {
      color: rgba(0, 0, 0, 0.6);
    }

    :active {
      color: rgba(0, 0, 0, 0.8);
    }

    @media ${({ theme }) => theme.device.sm} {
      :hover {
        color: rgba(0, 0, 0, 0.8) !important;
      }

      :active,
      :focus {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
`

export const MinusButton = styled(StyledButton)`
  &&& {
    border-left: none;
    border-radius: 0;
    margin: 0;
    margin-left: 1em;
    justify-content: flex-end;
  }
`

export const PlusButton = styled(StyledButton)`
  &&& {
    border-left: none;
    border-right: none;
    border-radius: 0;
    justify-content: flex-start;
    margin: 0 1em 0 0;
  }
`

interface StyledSpanProps {
  isTitle: boolean
  lineThru: boolean
  count: number
  bold: boolean
  pv: boolean
}

export const StyledSpan = styled.span<Partial<StyledSpanProps>>`
  font-size: 1.5em;

  ${props =>
    props.isTitle &&
    css`
      font-weight: bold;
      margin-bottom: 1em;
    `}

  ${props =>
    props.lineThru &&
    css`
      font-size: 1.2em;
      margin-right: 5px;
      opacity: 0.6;
      text-decoration: line-through;
      color: ${({ theme }) => theme.colors.dimGray};
    `}

  ${props =>
    props.count &&
    css`
      font-size: 1.5em;
      font-weight: bold;
      margin: 10px;
    `}

  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `}

  ${props =>
    props.pv &&
    css`
      font-size: 1.2em;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.dimGray};
    `}
`

export const AddButton = styled(PrimaryButton)`
  &&& {
    text-transform: uppercase;
    margin: 0 0 1rem;
  }
`

export const StyledCheckbox = styled(Checkbox)`
  &&& {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;

    label {
      font-size: 18px;
      font-weight: bold;
      p {
        width: 90%;
        line-height: 15px;
      }
      span {
        font-weight: 500;
        font-size: 13px;
      }
    }
  }
`
