import styled, { css } from 'styled-components'
import { Card } from 'semantic-ui-react'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex: 1 0 calc(25% - 10px);
  background-color: ${({ theme }) => theme.colors.white};
`

interface CardItemProps {
  backgroundimg: string
}

export const CardItem = styled(Card)<Partial<CardItemProps>>`
  &&& {
    display: flex;
    width: 90%;
    height: 100px;
    border-radius: 5px;
    cursor: pointer;
    ${props =>
      props.backgroundimg &&
      css`
        background: url(${props.backgroundimg});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      `}

    @media ${({ theme }) => theme.device.sm} {
      height: 150px;
    }

    @media ${({ theme }) => theme.device.lg} {
      height: 200px;
    }
  }
`

export const ImageOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.white};
  :hover {
    background-color: rgba(0, 0, 0, 0);
  }
`

export const CategoryTitle = styled.span`
  font-size: 1.5em;
  font-weight: bolder;
  text-align: center;
  line-height: 25px;
  @media ${({ theme }) => theme.device.sm} {
    font-size: 2.5em;
    line-height: 40px;
  }
`
