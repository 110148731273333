import React from 'react'
// Context
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { Container, StyledCheckbox } from '../../styles/common'
// Utils
import { formatParagraph } from '../../utils/prismicUtils'

type PcAgreementProps = {
  handleChecked: () => void
  receiveSmsEmail: boolean
  areTermsAccepted: boolean
}

const PcAgreement = ({
  handleChecked,
  receiveSmsEmail,
  areTermsAccepted,
}: PcAgreementProps) => {
  const {
    prismicData: {
      prismicCartPage: {
        agree_receive_sms_and_email,
        preferred_customer_agreement,
        understand_and_agree,
      },
    },
  } = usePrismic()

  return (
    <>
      <Container gray padded align="center">
        <h3>{preferred_customer_agreement[0].text}</h3>
      </Container>
      <Container padded>
        <StyledCheckbox
          checked={receiveSmsEmail}
          label={agree_receive_sms_and_email[0].text}
          name="smsEmail"
          onChange={handleChecked}
        />
      </Container>
      <Container padded>
        <StyledCheckbox
          name="terms"
          label={
            <label
              dangerouslySetInnerHTML={{
                __html: formatParagraph(understand_and_agree[0]),
              }}
            />
          }
          checked={areTermsAccepted}
          onChange={handleChecked}
        />
      </Container>
    </>
  )
}

export default PcAgreement
