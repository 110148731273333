import React, { useEffect, useState } from 'react'
// Context
import { useAuthContext } from '../context/AuthContext'
import { useCartContext } from '../context/CartContext'
import { usePrismic } from '../context/PrismicContext'
// Styles
import {
  Container,
  StyledSpan,
} from '../components/Cart/CartProductCard.styles'
// Types
import { ProductType } from '../types/ProductTypes'
import { oneYearFromToday } from '../utils/dateHelpers'
import { enrollSkus } from '../utils/constants'
// Utils
import { formatCurrency } from '../utils/i18n/stores'

const useShowProductPrice = (productData: ProductType, useWholesale) => {
  const [product, setProduct] = useState({ ...productData })

  const { userType } = useAuthContext()

  const { isPcOfferInCart, isAmbOfferInCart } = useCartContext()

  const {
    langAndCountry,
    prismicData: {
      prismicCartPage: { disallow_retail },
    },
  } = usePrismic()

  useEffect(() => {
    setProduct(productData)
  }, [productData])

  const isOfferProduct = Object.values(enrollSkus).includes(product?.sku)

  const wholesalePrice =
    product?.wholesale_price && Number(product?.wholesale_price)
  const finalPrice = useWholesale
    ? wholesalePrice
    : product?.price_range?.maximum_price?.final_price?.value
  const regularPrice = useWholesale
    ? wholesalePrice
    : product?.price_range?.maximum_price?.regular_price?.value
  const hasDiscount = finalPrice < regularPrice
  const showPv =
    (isAmbOfferInCart || userType === 'AMBASSADOR') && product?.pv !== '0'
  const showDiscount =
    ((isPcOfferInCart || isAmbOfferInCart) && regularPrice > wholesalePrice) ||
    (userType !== 'RETAIL' && hasDiscount)

  const ProductPricing = ({ isCart = false, quantity = 1 }) => {
    const pricing = {
      regularPrice: formatCurrency(langAndCountry, regularPrice * quantity),
      finalPrice:
        isPcOfferInCart || isAmbOfferInCart
          ? formatCurrency(langAndCountry, wholesalePrice * quantity)
          : formatCurrency(langAndCountry, finalPrice * quantity),
    }

    const savings =
      isPcOfferInCart || isAmbOfferInCart
        ? (regularPrice - wholesalePrice) * quantity
        : (regularPrice - finalPrice) * quantity

    const pvTotal = showPv ? product?.pv * quantity : 0

    if (!product) return null
    return (
      <Container isCart={isCart} column={!isCart}>
        {showDiscount ? (
          <>
            <Container>
              {!disallow_retail && (
                <StyledSpan
                  lineThru
                  data-qa={`priceLineThru-${product.sku}`}
                >{`${pricing.regularPrice}`}</StyledSpan>
              )}
              <StyledSpan
                bold
                data-qa={`priceDiscount-${product.sku}`}
              >{`${pricing.finalPrice}`}</StyledSpan>
            </Container>
          </>
        ) : (
          <>
            <StyledSpan
              bold
              data-qa={`priceRegular-${product.sku}`}
            >{`${pricing.regularPrice}`}</StyledSpan>
            {isOfferProduct && (
              <StyledSpan
                style={{ marginTop: '1em' }}
              >{`Expires: ${oneYearFromToday()}`}</StyledSpan>
            )}
          </>
        )}
        <Container column={!isCart}>
          {!disallow_retail && isCart && savings ? (
            <StyledSpan savings>{`(You save ${formatCurrency(
              langAndCountry,
              savings
            )})`}</StyledSpan>
          ) : null}
          {showPv ? (
            <StyledSpan
              pv
              data-qa={`pv-${product.sku}`}
            >{`PV ${pvTotal}`}</StyledSpan>
          ) : null}
        </Container>
      </Container>
    )
  }

  return { ProductPricing }
}

export default useShowProductPrice
