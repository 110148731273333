export const stagingFirebase = {
  apiKey: 'AIzaSyDLvs6kf7lAlma9v714M_yF4EqP_UDIc5g',
  authDomain: 'q-connect-pro-staging.firebaseapp.com',
  databaseURL: 'https://q-connect-pro-staging-default-rtdb.firebaseio.com',
  projectId: 'q-connect-pro-staging',
  storageBucket: 'q-connect-pro-staging.appspot.com',
  messagingSenderId: '348281014348',
  appId: '1:348281014348:web:33717b1f58b819a9594ce9',
  measurementId: 'G-WV8PXGZQM1',
}

export const prodFirebase = {
  apiKey: 'AIzaSyCJ9fsDkv4R-P3Ok8ZYswXB5OfxZNiGXxg',
  authDomain: 'q-innovation-prod.firebaseapp.com',
  databaseURL: 'https://q-innovation-prod-default-rtdb.firebaseio.com',
  projectId: 'q-innovation-prod',
  storageBucket: 'q-innovation-prod.appspot.com',
  messagingSenderId: '558665444400',
  appId: '1:558665444400:web:153532ac641aa4403551f5',
  measurementId: 'G-6T88HBBN0M',
}
