import React from 'react'
import { navigate } from 'gatsby'
import useWindowSize from '../../hooks/useWindowSize'
// Context
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { Container, StyledLink, StyledAnchor } from './Footer.styles'

const Disclaimer = () => {
  const {
    prismicData: { prismicDisclaimer, prismicGeneral },
  } = usePrismic()

  const { width } = useWindowSize()
  const isLargeScreen = width > 750
  const privacyPath = isLargeScreen
    ? '/privacy-policy'
    : prismicGeneral.privacy_policy_url

  const termsPath = isLargeScreen ? '/terms' : prismicGeneral.term_of_use_url

  const ResponsiveLink = ({ link, children }) => (
    <>
      {isLargeScreen ? (
        <StyledLink to={link}>{children}</StyledLink>
      ) : (
        <StyledAnchor href={link} target="_blank">
          {children}
        </StyledAnchor>
      )}
    </>
  )

  const getReturnUrl = () => {
    const urlParams = window.location.pathname
    return urlParams
  }

  return (
    <>
      <Container row padding="2em 0 0 0" justify="space-evenly">
        <StyledLink to="/contact-us">{prismicDisclaimer.contact}</StyledLink>
        <span>|</span>
        <ResponsiveLink link={privacyPath}>
          {prismicDisclaimer.privacy_policy}
        </ResponsiveLink>
        <span>|</span>
        <ResponsiveLink link={termsPath}>
          {prismicDisclaimer.terms_of_use}
        </ResponsiveLink>
      </Container>
      <Container align="flex-start">
        <p>{prismicDisclaimer.cruelty_free}</p>
        {/*<p>{prismicDisclaimer.hemp_not_under_18}</p>*/}
        <p>{prismicDisclaimer.fda}</p>
        <p>{prismicDisclaimer.consult_health_professional}</p>
      </Container>
      {/*<Container align="flex-start" padding="1em 0 0 0">*/}
      {/*  <StyledAnchor onClick={() => navigate('/drug-testing')}>*/}
      {/*    {prismicDisclaimer.drug_testing_statement}*/}
      {/*  </StyledAnchor>*/}
      {/*  <StyledAnchor onClick={() => {*/}
      {/*      const returnUrl = getReturnUrl()*/}
      {/*      navigate("/hemp-info", {*/}
      {/*        state: {*/}
      {/*          returnUrl,*/}
      {/*        }*/}
      {/*      })*/}
      {/*    }}>*/}
      {/*    {prismicDisclaimer.hemp_product_tests}*/}
      {/*  </StyledAnchor>*/}
      {/*</Container>*/}
    </>
  )
}

export default Disclaimer
