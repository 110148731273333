import React, { useEffect } from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
// Images
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { LoadingWrapper, dimmerStyle } from './Loading.styles'
import environment from '../../utils/environment'

const Loading = ({ loading, message = '' }) => {
  const { prismicData } = usePrismic()
  const backgroundUrl = prismicData?.prismicHomepage?.loading_background?.url
  const eventBackgroundUrl =
    prismicData?.prismicHomepage?.event_loading_background?.url
  const vipBackgroundUrl =
    prismicData?.prismicHomepage?.vip_loading_background?.url

  //
  const url = environment.IS_VIP_SITE
    ? vipBackgroundUrl ?? eventBackgroundUrl ?? backgroundUrl
    : environment.IS_EVENT_SITE
    ? eventBackgroundUrl ?? backgroundUrl
    : backgroundUrl

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const isEventOrVip = environment.IS_EVENT_SITE || environment.IS_VIP_SITE

  return (
    <LoadingWrapper data-qa="loading" url={url} isEvent={isEventOrVip}>
      <Dimmer style={dimmerStyle} active={loading}>
        <Loader style={{ marginTop: '100px' }} size="huge">
          {message}
        </Loader>
      </Dimmer>
    </LoadingWrapper>
  )
}

export default Loading
