import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
// Context
import { usePrismic } from '../../context/PrismicContext'
import { useAuthContext } from '../../context/AuthContext'
import { useCartContext } from '../../context/CartContext'
import { useStoreContext } from '../../context/StoreContext'
// Utils
import { formatCurrency } from '../../utils/i18n/stores'
// Styles
import {
  ImageWrapper,
  SearchItemInfo,
  SearchItemWrapper,
  StyledImage,
  StyledSearch,
  StyledSearchAdd,
  StyledSpan,
  TitleContainer,
} from './SearchMenu.styles'
// Types
import { ProductType } from '../../types/ProductTypes'
import { filterProducts } from '../../utils/productHelpers'

type InitialState = {
  isLoading: boolean
  results: ProductType[]
  value: string
}

const initialState = {
  isLoading: false,
  results: [],
  value: '',
}

const resultRenderer = ({
  image: { url },
  show_pv,
  price,
  pv,
  sku,
  title,
  uid,
}) => {
  return (
    <SearchItemWrapper key={uid}>
      <ImageWrapper data-qa={`searchImage-${sku}`}>
        <StyledImage src={url} />
      </ImageWrapper>
      <SearchItemInfo>
        <TitleContainer data-qa={`searchTitle-${sku}`}>
          <StyledSpan isTitle>{title}</StyledSpan>
        </TitleContainer>
        <div>
          <StyledSpan data-qa={`searchPrice-${sku}`} price>
            {price}
          </StyledSpan>
          {show_pv === 'true' ? (
            <StyledSpan data-qa={`searchPV-${sku}`} pv>
              PV: {`${pv}`}
            </StyledSpan>
          ) : null}
        </div>
      </SearchItemInfo>
    </SearchItemWrapper>
  )
}

export default function SearchMenu({ type, callback }) {
  const {
    langAndCountry,
    prismicData: { prismicGeneral },
  } = usePrismic()
  const [{ isLoading, results, value }, setState] =
    useState<InitialState>(initialState)
  const [data, setData] = useState(null)

  const { userType, qUser } = useAuthContext()
  const {
    isAmbOfferInCart,
    isPcOfferInCart,
    isAmbPremiumOfferInCart,
    doesExceedMaxOrder,
    setIsMaxOrderModalOpen,
  } = useCartContext()
  const { isStoreLoading, storeData, autoshipStoreData } = useStoreContext()

  const handleMakeAutoshipData = products => {
    const filteredProducts = filterProducts(
      products,
      qUser,
      isPcOfferInCart,
      isAmbOfferInCart,
      isAmbPremiumOfferInCart
    )
    return filteredProducts?.map(
      ({
        autoship,
        ds_itemid,
        image,
        name,
        pv,
        sku,
        uid,
        url_key,
        wholesale_price,
      }) => {
        return {
          autoship,
          ds_itemid,
          image,
          show_pv: 'true',
          name,
          price: wholesale_price
            ? formatCurrency(langAndCountry, wholesale_price)
            : '-',
          pv,
          sku,
          title: name,
          uid,
          url_key,
          wholesale_price,
        }
      }
    )
  }

  const handleMakeData = products => {
    const filteredProducts = filterProducts(
      products,
      qUser,
      isPcOfferInCart,
      isAmbOfferInCart,
      isAmbPremiumOfferInCart
    )
    return filteredProducts?.map(
      ({
        autoship,
        ds_itemid,
        image,
        name,
        price_range,
        pv,
        sku,
        uid,
        url_key,
        wholesale_price,
      }) => {
        const finalPrice = price_range?.maximum_price?.final_price?.value
        const shouldShowPv = userType === 'AMBASSADOR' || isAmbOfferInCart
        return {
          autoship,
          ds_itemid,
          image,
          show_pv: shouldShowPv ? 'true' : 'false',
          name,
          price_range,
          price: finalPrice ? formatCurrency(langAndCountry, finalPrice) : '-',
          pv,
          sku,
          title: name,
          uid,
          url_key,
          wholesale_price,
        }
      }
    )
  }

  useEffect(() => {
    if (isStoreLoading) return
    const allProducts = storeData['all-products']?.products
    const autoShipItems = Object?.keys(autoshipStoreData)?.map(item => ({
      ...autoshipStoreData[item],
    }))

    setData(
      type === 'add'
        ? handleMakeAutoshipData(autoShipItems)
        : handleMakeData(allProducts)
    )
  }, [isStoreLoading])

  const handleResultSelect = (e, { result }) => {
    const count = 1
    const finalPrice = result?.price_range?.maximum_price?.final_price?.value
    const regularPrice =
      result?.price_range?.maximum_price?.regular_price?.value
    const wholesalePrice =
      result?.wholesale_price && Number(result?.wholesale_price)
    const hasDiscount = finalPrice < regularPrice
    const showDiscount =
      ((isPcOfferInCart || isAmbOfferInCart) &&
        regularPrice > wholesalePrice) ||
      (userType !== 'RETAIL' && hasDiscount)
    const pricing = {
      regularPrice: regularPrice * count,
      finalPrice:
        isPcOfferInCart || isAmbOfferInCart
          ? wholesalePrice * count
          : finalPrice * count,
    }
    const totalPriceToCheckMax = showDiscount
      ? pricing.finalPrice
      : pricing.regularPrice

    const isAutoShipMaxedOut = doesExceedMaxOrder(
      totalPriceToCheckMax,
      'autoship'
    )
    if (isAutoShipMaxedOut) {
      return setIsMaxOrderModalOpen(true)
    }
    if (type === 'link') {
      callback()
      navigate(`/products/${result.url_key}`)
    } else {
      callback(result, 1)
      setState(initialState)
    }
  }

  const handleAutoshipResultSelect = (e, { result }) => {
    const count = 1
    const wholesalePrice = Number(result?.wholesale_price)

    const totalPriceToCheckMax = wholesalePrice * count

    const isAutoShipMaxedOut = doesExceedMaxOrder(
      totalPriceToCheckMax,
      'autoship'
    )
    if (isAutoShipMaxedOut) {
      return setIsMaxOrderModalOpen(true)
    }
    if (type === 'link') {
      callback()
      navigate(`/products/${result.url_key}`)
    } else {
      callback(result, 1)
      setState(initialState)
    }
  }

  const handleSearchChange = (e, { value }) => {
    setState(prev => ({ ...prev, isLoading: true, value }))

    if (value.length < 1) {
      setState(prev => ({ ...prev, isLoading: false }))
      return
    }

    // TODO - Add debounce here
    setTimeout(() => {
      const results = data?.filter(product =>
        product?.title?.toLowerCase()?.includes(value?.toLowerCase())
      )
      setState(prev => ({
        ...prev,
        isLoading: false,
        results: results?.map(x => ({ ...x, key: x.uid })) ?? [],
      }))
    }, 500)
  }

  return type === 'link' ? (
    <StyledSearch
      autoFocus
      data-qa="searchInputLink"
      fluid
      loading={isLoading}
      minCharacters={2}
      onResultSelect={handleResultSelect}
      onSearchChange={handleSearchChange}
      resultRenderer={resultRenderer}
      results={results}
      value={value}
    />
  ) : (
    <StyledSearchAdd
      className="search-add"
      data-qa="searchInputAdd"
      fluid
      loading={isLoading}
      minCharacters={2}
      onResultSelect={handleAutoshipResultSelect}
      onSearchChange={handleSearchChange}
      placeholder={prismicGeneral.msg_click_here_to_quickly_add_products}
      resultRenderer={resultRenderer}
      results={results}
      value={value}
    />
  )
}
