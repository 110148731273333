import React from 'react'
import { Link } from 'gatsby'
// Context
import { usePrismic } from '../../context/PrismicContext'
import { useAuthContext } from '../../context/AuthContext'
// Styles
import {
  Container,
  MissingText,
  StyledChevron,
  StyledSpan,
} from '../../styles/common'

type ShippingDetailsProps = {
  address: any
  showAddress: boolean
  returnUrl: string
}

const ShippingDetails = ({
  address,
  showAddress = false,
  returnUrl = '/cart',
}: ShippingDetailsProps) => {
  // const { firstname, lastname, street, city, region, postcode } = address
  const street0 = address?.street?.[0] ?? address?.address1
  const street1 = address?.street?.[1] ?? address?.address2
  const {
    prismicData: {
      prismicCartPage: { shipping_address, add_shipping_address },
    },
  } = usePrismic()

  const { magentoUser } = useAuthContext()

  return (
    <>
      <Container gray padded align="center">
        <h3>{shipping_address[0].text}</h3>
      </Container>
      <Link
        data-qa="addShipping"
        style={{ width: '100%' }}
        to="/shipping-info"
        state={{
          returnUrl,
        }}
      >
        <Container padded row justify="space-between" align="center">
          <Container>
            {showAddress && address !== null ? (
              <>
                <StyledSpan data-qa="shipToName" bold>{`${
                  address?.firstname ?? magentoUser.firstname
                } ${address?.lastname ?? magentoUser.lastname}`}</StyledSpan>
                <span data-qa="shipToAddress">
                  {`${street0}`}
                  {street1 && `, ${street1}`}
                </span>
                <span data-qa="shipToAddress2">
                  {' '}
                  {`${address?.city}, ${
                    address?.region?.code ??
                    address?.region?.region_code ??
                    address?.state
                  }, ${address?.postcode ?? address?.zip}`}
                </span>
                <span>{address?.country?.code ?? address?.countryCode}</span>
              </>
            ) : (
              <MissingText>{add_shipping_address[0].text}</MissingText>
            )}
          </Container>
          <StyledChevron name="chevron right" size="big" />
        </Container>
      </Link>
    </>
  )
}

export default ShippingDetails
