import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { usePrismic } from '../../context/PrismicContext'
// Components
import { Modal } from 'semantic-ui-react'
import { StyledInput } from '..'
// Styles
import {
  StyledModal,
  Container,
  StyledText,
  StyledTextBlue,
  ErrorMsg,
} from './Verification.styles'

const EnterEnrollerId = ({ open, onClose, value, onSave }) => {
  const {
    prismicData: {
      prismicGeneral: {
        btn_save,
        error_the_id_must_be_a_number,
        label_referrers_id_number,
        msg_unknown_ambassador_id,
      },
    },
  } = usePrismic()

  const [localValue, setLocalValue] = useState('')

  useEffect(() => {
    setLocalValue(value)

    return () => {
      setLocalValue(value)
    }
  }, [value, open])

  return (
    <StyledModal
      style={{ width: '80%', maxWidth: 300, padding: 8 }}
      closeIcon
      centered={true}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      dimmer="blurring"
      onClose={() => onClose(value, localValue)}
      open={open}
    >
      <Modal.Content>
        <Container direction="column">
          <StyledText>{msg_unknown_ambassador_id}</StyledText>
          <Container style={{ height: 24, maxWidth: 300 }}>
            {isNaN(localValue) && (
              <ErrorMsg>{error_the_id_must_be_a_number}</ErrorMsg>
            )}
          </Container>
          <StyledInput
            value={localValue}
            onChange={e => setLocalValue(e.target.value)}
            placeholder={label_referrers_id_number}
          />
          <StyledTextBlue
            onClick={() => onSave(localValue)}
            style={{ alignSelf: 'flex-end' }}
          >
            {btn_save.toUpperCase()}
          </StyledTextBlue>
        </Container>
      </Modal.Content>
    </StyledModal>
  )
}

EnterEnrollerId.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  value: PropTypes.string,
  onSave: PropTypes.func.isRequired,
}

export default EnterEnrollerId
