import * as yup from 'yup'
import { formatTaxDataFromPrismic } from './i18n/enrollmentForm'

// ------------------------ User Authorization ------------------------

export const buildSignUpSchema = prismicGeneral => {
  const signUpSchema = yup.object().shape({
    email: yup
      .string()
      .email(prismicGeneral.msg_please_enter_a_valid_email)
      .required(prismicGeneral.required_email),
    password: yup
      .string()
      .required(prismicGeneral.required_password)
      .matches(/[a-z]/, prismicGeneral.required_one_lowercase_letter)
      .matches(/[A-Z]/, prismicGeneral.required_one_uppercase_letter)
      .matches(/\d/, prismicGeneral.required_one_digit)
      .min(8, prismicGeneral.required_password_at_least_eight_characters),
    passwordConfirm: yup
      .string()
      .required(prismicGeneral.required_confirm_password)
      .oneOf(
        [yup.ref('password'), null],
        prismicGeneral.required_passwords_must_match
      ),
  })
  return signUpSchema
}

export const buildSignInSchema = prismicGeneral => {
  const signInSchema = yup.object().shape({
    email: yup
      .string()
      .email(prismicGeneral.msg_please_enter_a_valid_email)
      .required(prismicGeneral.required_email),
    password: yup.string().required(prismicGeneral.required_password),
  })
  return signInSchema
}

export const buildForgotPasswordSchema = prismicGeneral => {
  const forgotPasswordSchema = yup.object().shape({
    resetEmail: yup
      .string()
      .email(prismicGeneral.msg_please_enter_a_valid_email)
      .required(prismicGeneral.required_email),
  })
  return forgotPasswordSchema
}

// ------------------------ Q Services Account Validation ------------------------

export const buildExistingCustomer = prismicGeneral => {
  const existingCustomer = yup.object().shape({
    customer: yup
      .string()
      .required(prismicGeneral.required_customer_id_or_username),
  })
  return existingCustomer
}

export const buildNewCustomer = prismicGeneral => {
  const newCustomer = yup.object().shape({
    firstName: yup.string().required(prismicGeneral.required_first_name),
    lastName: yup.string().required(prismicGeneral.required_last_name),
    countryCode: yup.string().required(prismicGeneral.required_country_code),
  })
  return newCustomer
}

export const buildEnterCode = prismicGeneral => {
  const enterCode = yup.object().shape({
    code: yup
      .string()
      .required(prismicGeneral.required_code)
      .matches(/^[0-9]{6}/, prismicGeneral.error_invalid_code),
  })
  return enterCode
}

export const buildValidationSchema = prismicGeneral => {
  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .test(
        'oneOfRequired',
        prismicGeneral.required_one_field,
        function (item) {
          return this.parent.password || this.parent.email || this.parent.phone
        }
      ),
    email: yup
      .string()
      .test(
        'oneOfRequired',
        prismicGeneral.required_one_field,
        function (item) {
          return this.parent.password || this.parent.email || this.parent.phone
        }
      ),
    phone: yup
      .string()
      .test(
        'oneOfRequired',
        prismicGeneral.required_one_field,
        function (item) {
          return this.parent.password || this.parent.email || this.parent.phone
        }
      ),
  })
  return validationSchema
}

// ------------------------ Checkout ------------------------
export const buildShippingAddressSchema = prismic => {
  const shippingAddressSchema = yup.object().shape({
    firstname: yup
      .string()
      .required(prismic.first_name_required[0].text)
      .max(25, prismic.must_not_exceed_25[0].text),
    lastname: yup
      .string()
      .required(prismic.last_name_required[0].text)
      .max(25, prismic.must_not_exceed_25[0].text),
    address1: yup
      .string()
      .required(prismic.address1_required[0].text)
      .max(prismic.address1_max_length, prismic.must_not_exceed_30[0].text),
    address2: yup
      .string()
      .max(prismic.address1_max_length, prismic.must_not_exceed_30[0].text),
    city: yup
      .string()
      .required(prismic.city_required[0].text)
      .max(prismic.address1_max_length, prismic.must_not_exceed_30[0].text),
    state: yup.string().required(prismic.state_required[0].text),
    postcode: yup
      .string()
      .required(prismic.zip_required[0].text)
      .matches(new RegExp(prismic.zip_regex[0].text), prismic.digit_zip[0].text)
      .min(prismic.zip_min, prismic.digit_zip[0].text)
      .max(prismic.zip_max, prismic.digit_zip[0].text),
    country_code: yup.string().required(prismic.country_required[0].text),
    telephone: yup
      .string()
      .required(prismic.phone_required[0].text)
      .min(prismic.phone_min, prismic.digits_required[0].text)
      .max(prismic.phone_max, prismic.digits_required[0].text)
      .matches(
        new RegExp(prismic.phone_regex[0].text),
        prismic.digits_required[0].text
      ),
  })
  return shippingAddressSchema
}

export const buildAddCouponCode = prismicGeneral => {
  const addCouponCode = yup.object().shape({
    code: yup.string().max(20, prismicGeneral.error_too_long),
  })
  return addCouponCode
}

export const buildEnrollmentSchema = prismic => {
  // dynamically build schema for personal tax fields
  const ssnFromPrismic = formatTaxDataFromPrismic(prismic.ssn_list)
  const ssnSchema = ssnFromPrismic.map(({ name, message, regex }) => ({
    [name]: yup.string().test('oneOfRequired', message, function () {
      if (prismic.hide_tax_fields === true || prismic.hide_tax_fields === null)
        return true
      if (this.parent.identification !== 'personal') return true
      const re = new RegExp(regex)
      if (!re.test(this.parent[name])) return false
      return this.parent[name]
    }),
  }))
  // dynamically build schema for business tax fields
  const einFromPrismic = formatTaxDataFromPrismic(prismic.ein_list)
  const einSchema = einFromPrismic.map(({ name, message, regex }) => ({
    [name]: yup.string().test('oneOfRequired', message, function () {
      if (this.parent.identification === 'personal') return true
      const re = new RegExp(regex)
      if (!re.test(this.parent[name])) return false
      return this.parent[name]
    }),
  }))
  const enrollmentSchema = yup.object().shape({
    identification: yup
      .string()
      .required(prismic.identification_required[0].text),
    ...Object.assign({}, ...ssnSchema),
    ...Object.assign({}, ...einSchema),
    companyName: yup
      .string()
      .test('oneOfRequired', prismic.company_required[0].text, function () {
        if (this.parent.identification !== 'business') {
          return true
        }
        return this.parent.companyName
      }),
    legalFirstName: yup.string().required(prismic.first_name_required[0].text),
    legalLastName: yup.string().required(prismic.last_name_required[0].text),
    primaryPhoneNumber: yup
      .string()
      .required(prismic.phone_required[0].text)
      .min(prismic.phone_min, prismic.digits_required[0].text)
      .max(prismic.phone_max, prismic.digits_required[0].text)
      .matches(
        new RegExp(prismic.phone_regex[0].text),
        prismic.digits_required[0].text
      ),
    emailAddress: yup
      .string()
      .email(prismic.enter_valid_email[0].text)
      .required(prismic.email_required[0].text),
    dateBirth: yup
      .string()
      .required(prismic.birthday_required[0].text)
      .matches(
        new RegExp(prismic.birthday_regex[0].text),
        prismic.birthday_format[0].text
      ),
    address1: yup
      .string()
      .required(prismic.address1_required[0].text)
      .max(prismic.address1_max_length, prismic.must_not_exceed_30[0].text),
    address2: yup
      .string()
      .max(prismic.address1_max_length, prismic.must_not_exceed_30[0].text),
    city: yup
      .string()
      .required(prismic.city_required[0].text)
      .max(prismic.address1_max_length, prismic.must_not_exceed_30[0].text),
    state: yup.string().required(prismic.state_required[0].text),
    zip: yup
      .string()
      .required(prismic.zip_required[0].text)
      .matches(new RegExp(prismic.zip_regex[0].text), prismic.digit_zip[0].text)
      .min(prismic.zip_min, prismic.digit_zip[0].text)
      .max(prismic.zip_max, prismic.digit_zip[0].text),
    country: yup.string().required(prismic.country_required[0].text),
    slug: yup
      .string()
      .required(prismic.field_required[0].text)
      .matches(
        new RegExp(prismic.slug_regex[0].text),
        prismic.letters_and_numbers[0].text
      )
      .min(3, prismic.slug_min_char[0].text)
      .max(60, prismic.slug_max_char[0].text),
    send1099FormElectronically: yup.boolean(),
    areTermsAccepted: yup
      .boolean()
      .oneOf([true], prismic.tncs_required[0].text),
  })
  return enrollmentSchema
}
