export const LANGUAGE_KEY = 'USER_LANG'

/**
 * Gets the language of the user
 * Uses settings from localstorage first, then browser settings, then 'en'
 */
export const getUserLanguage = (storage: Storage, locale: string) => {
  const language =
    storage.getItem(LANGUAGE_KEY) ?? _getLanguageFromLocale(locale)
  return language !== '' ? language : 'en'
}

/**
 * Saves language to localstorage
 * @param locale to get  (i.e. "en-US")
 */
export const saveUserLanguage = (storage: Storage, locale: string) => {
  storage.setItem(LANGUAGE_KEY, _getLanguageFromLocale(locale))
}

/**
 * Returns language of the user from locale string formatted as 'en-US' / 'language-COUNTRY'
 * @param locale locale string provided from 'navigator.language' (i.e. 'en-US')
 * @returns language (i.e. 'en')
 */
export const _getLanguageFromLocale = (locale: string) => {
  return locale.split('-')[0]
}
