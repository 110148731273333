import styled from 'styled-components'
import { Modal } from 'semantic-ui-react'
import { QLogo } from '../../styles/LoginPage.styles'

export const StyledModal = styled(Modal)`
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  max-width: 800px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.align || 'center'};
  width: 100%;
  max-width: ${props => props.maxWidth || 'none'};
  padding-top: 1em;
  align-self: center;
`

export const StyledParagraph = styled.span`
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-size: 30px;
  font-family: Tahoma;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  max-width: 760px;
`

export const StyledModalContent = styled(Modal.Content)`
  display: flex;
  padding: 10px;
  justify-content: center;
`
export const CenteredQLogo = styled(QLogo)`
  display: inline-block;
  margin: auto;
  text-align: center;
`
