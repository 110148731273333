import React from 'react'
import {
  StyledButton,
  StyledModalContent,
  Container,
  StyledParagraph,
  StyledButtonText,
  StyledModal,
  StyledShareText,
  VerifyButton,
  VerifyButtonText,
} from './Nexio3dsModal.styles'

import { usePrismic } from '../../context/PrismicContext'
import { Countdown } from '../Countdown'

export const Nexio3dsModal = ({
  open,
  onClose,
  onYes,
  onCard = false,
  time = 10,
}) => {
  const {
    prismicData: {
      prismicNexioRedirect: {
        card_verify_text,
        verify_payment_card,
        payment_verify_text,
        verify_payment_button,
      },
    },
  } = usePrismic()

  const textToDisplay = onCard ? card_verify_text : payment_verify_text
  const buttonTextToDisplay = onCard
    ? verify_payment_card
    : verify_payment_button
  return (
    <StyledModal
      closeIcon
      open={open}
      centered={true}
      closeOnDimmerClick={true}
      closeOnEscape
      onClose={onClose}
    >
      <StyledModalContent>
        <Container direction="column">
          <StyledParagraph>
            {textToDisplay.map((text, index) => (
              <React.Fragment key={index}>
                <StyledShareText>{text.text}</StyledShareText>
                <br />
                <br />
              </React.Fragment>
            ))}
          </StyledParagraph>
          <Countdown time={time} timeOutFunc={onYes}></Countdown>
        </Container>

        <Container justify="center" direction="column">
          <VerifyButton onClick={onYes}>
            <VerifyButtonText>{buttonTextToDisplay[0].text}</VerifyButtonText>
          </VerifyButton>
        </Container>
      </StyledModalContent>
    </StyledModal>
  )
}
