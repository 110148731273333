import React from 'react'
import { Link } from 'gatsby'
// Context
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { Wrapper, StyledMessage, StyledHeader } from './ErrorMessage.styles'

const ErrorMessage = ({ message, cb }) => {
  const {
    prismicData: {
      prismicErrorData: { error, need_help },
    },
  } = usePrismic()

  return (
    <Wrapper>
      <StyledMessage
        error
        onDismiss={cb}
        header={<StyledHeader>{error[0].text}</StyledHeader>}
        content={
          <>
            <p>{message}</p>
            <Link to="/contact-us">{need_help[0].text}</Link>
          </>
        }
      />
    </Wrapper>
  )
}

export default ErrorMessage
